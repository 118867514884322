import { Group, Modal, TextInput } from '@mantine/core';
import { useState } from 'react';
import { inkLight, redBase, skyDark } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Icon } from '../../../ui-library/icon/icon';
import { H3, Text } from '../../../ui-library/typography/typography';

type DeleteConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => Promise<void> | void;
  title: string;
  itemName: string;
  isDeleting: boolean;
  error?: Error | null;
};

export function DeleteConfirmationModal({
  isOpen,
  onClose,
  onDelete,
  title,
  itemName,
  isDeleting,
  error,
}: DeleteConfirmationModalProps) {
  const [deleteConfirmation, setDeleteConfirmation] = useState('');

  const handleClose = () => {
    setDeleteConfirmation('');
    onClose();
  };

  const handleDelete = async () => {
    if (deleteConfirmation === itemName) {
      const result = onDelete();
      if (result instanceof Promise) {
        await result;
      }
      handleClose();
    }
  };

  return (
    <Modal opened={isOpen} onClose={handleClose} centered radius="lg" size="lg" padding="40px" withCloseButton={false}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Icon
          name="close"
          variant="outlined"
          size={30}
          color={skyDark}
          style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
          onClick={handleClose}
        />
        <H3>{title}</H3>
        <Text style={{ marginTop: 10 }}>To confirm the deletion, please type its name:</Text>
        <Text color={redBase} weight="medium">
          {itemName}
        </Text>
        <TextInput
          label="Name"
          required
          placeholder="Type name here"
          value={deleteConfirmation}
          onChange={(e) => setDeleteConfirmation(e.currentTarget.value)}
          style={{ marginTop: 20 }}
          error={error ? 'Failed to delete' : undefined}
          disabled={isDeleting}
        />
        <Group justify="flex-end" style={{ marginTop: 40 }}>
          <Button
            variant="outline"
            onClick={handleClose}
            disabled={isDeleting}
            radius="xl"
            leftSection={
              <Icon name="cancel" variant="outlined" size={16} color={inkLight} style={{ cursor: 'pointer' }} />
            }
          >
            <Text weight="medium" style={{ color: inkLight }}>
              Cancel
            </Text>
          </Button>
          <Button
            style={{ backgroundColor: deleteConfirmation === itemName ? redBase : inkLight }}
            onClick={handleDelete}
            loading={isDeleting}
            disabled={deleteConfirmation !== itemName}
            radius="xl"
            leftSection={
              <Icon name="delete" variant="outlined" size={16} color="white" style={{ cursor: 'pointer' }} />
            }
          >
            <Text weight="medium" style={{ color: 'white' }}>
              Confirm and Delete
            </Text>
          </Button>
        </Group>
      </div>
    </Modal>
  );
}
