import { QueryObserverResult, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import {
  createComponent,
  createPage,
  deleteComponent,
  deletePage,
  fetchPages,
  updateComponent,
  updatePage,
} from './pages-client';
import { Component, Page } from './pages-client.type';

/**
 * Query manager for fetching a list of pages for the current user.
 *
 * @param options UseQueryOptions
 * @returns a object of pages along with the query manager
 */
const usePages = (
  organizationId: string,
  portfolioId: string,
  type: string,
  moduleType: string,
  options?: Partial<UseQueryOptions<Page[]>>,
): {
  pages: Page[];
  query: QueryObserverResult<Page[]>;
} => {
  const query = useQuery({
    queryKey: ['pages', organizationId, portfolioId, type, moduleType],
    queryFn: () => fetchPages(organizationId, portfolioId, type, moduleType),
    ...options,
  });

  let pages: Page[] = [];
  if (Array.isArray(query.data) && query.data.length > 0) {
    pages = query.data.sort((a, b) => a.name.localeCompare(b.name));
  }

  return { pages: pages || [], query };
};
/**
 * Query manager for creating a component.
 */
const useCreateComponent = () => {
  return useMutation({
    mutationFn: ({ component }: { component: Component }) => createComponent(component),
  });
};

/**
 * Query manager for updating a component.
 */
const useUpdateComponent = () => {
  return useMutation({
    mutationFn: ({ componentId, component }: { componentId: string; component: Component }) =>
      updateComponent(componentId, component),
  });
};

/**
 * Query manager for deleting a component.
 */
const useDeleteComponent = () => {
  return useMutation({
    mutationFn: ({ componentId }: { componentId: string }) => deleteComponent(componentId),
  });
};

/**
 * Query manager for creating a page.
 *
 * @returns Page
 */
const useCreatePage = () => {
  return useMutation({
    mutationFn: createPage,
  });
};

/**
 * Query manager for updating a page.
 */
const useUpdatePage = () => {
  return useMutation({
    mutationFn: ({ id, payload }: { id: string | undefined; payload: Page }) =>
      id ? updatePage(id, payload) : Promise.reject(new Error('Page ID is required')),
  });
};

/**
 * Query manager for deleting a page.
 */
const useDeletePage = () => {
  return useMutation({
    mutationFn: deletePage,
  });
};

export {
  useCreateComponent,
  useCreatePage,
  useDeleteComponent,
  useDeletePage,
  usePages,
  useUpdateComponent,
  useUpdatePage,
};
