import { MultiSelect, MultiSelectProps } from '@mantine/core';
import { useCallback } from 'react';
import { deduplicateComboboxGroups } from '../../helpers/general-helpers';
import { useGlobalStore } from '../../store/global-store/global-store';
import { commonMultiSelectProps } from './multiselect.helpers';

export function BoardMultiselect(props: Omit<MultiSelectProps, 'data'>) {
  const { value: boardIds = [], onChange: handleBoardSelected, ...rest } = props;
  const teams = useGlobalStore((state) => state.teams);

  const getOptions = useCallback(() => {
    return teams.map((team, index) => ({
      group: `${index + 1}-${team.name}`,
      items: team.subprojects
        .filter((subproject) => subproject.id && subproject.name) // Check that both id and name exist
        .map((subproject) => ({
          value: subproject.id,
          label: subproject.name,
        })),
    }));
  }, [teams]);

  return (
    <MultiSelect
      {...rest}
      {...commonMultiSelectProps}
      value={getOptions().length ? boardIds : []}
      data={deduplicateComboboxGroups(getOptions())}
      onChange={handleBoardSelected}
    />
  );
}
