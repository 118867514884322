import { useDocumentTitle } from '../../helpers/general-helpers';
import { newCOLORS } from '../../styles/colors';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { Legal } from '../setup/terms-of-service';

export function TermsAndConditions() {
  useDocumentTitle('Terms and Conditions - Bloomfilter');

  return (
    <MenuDrivenContainer header="Terms and Conditions">
      <div style={{ backgroundColor: newCOLORS.white, padding: '3em' }}>
        <Legal />
      </div>
    </MenuDrivenContainer>
  );
}
