import { useQueryClient } from '@tanstack/react-query';
import {
  useCreatePortfolio,
  useDeletePortfolio,
  useUpdatePortfolio,
} from '../../../../api/portfolio-client/portfolio-client.hooks';
import { Portfolio } from '../../../../api/portfolio-client/portfolio-client.type';
import { useGlobalStore } from '../../../../store/global-store/global-store';
import { setPortfolio, setPortfolios } from '../../../../store/global-store/global-store.actions';

/**
 * Hook for creating new portfolios
 * @returns Object containing:
 * - createNewPortfolio: Function to create a new portfolio and return its ID
 * - isCreating: Boolean indicating if creation is in progress
 */
export const usePortfolioCreate = (): {
  createNewPortfolio: () => Promise<string>;
  isCreating: boolean;
} => {
  const queryClient = useQueryClient();
  const createPortfolio = useCreatePortfolio();
  const organization = useGlobalStore((state) => state.organization);
  const portfolios = useGlobalStore((state) => state.portfolios);

  const createNewPortfolio = async (): Promise<string> => {
    const result = await createPortfolio.mutateAsync(
      {
        name: 'New Portfolio',
        description: '',
        projects: [],
        organization: organization?.id ?? '',
        is_default: false,
        use_end_date: true,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['portfolios'] });
        },
      },
    );
    // Update global store with new portfolio
    const updatedPortfolios = [...portfolios, result];
    setPortfolios(updatedPortfolios);
    return result.id;
  };

  return {
    createNewPortfolio,
    isCreating: createPortfolio.isPending,
  };
};

/**
 * Hook for managing portfolio name editing functionality
 * @returns Object containing:
 * - editPortfolioName: Function to update a portfolio's name
 * - isEditing: Boolean indicating if an edit is in progress
 * - error: Any error that occurred during editing
 */
export const usePortfolioNameEdit = (): {
  editPortfolioName: (portfolio: Portfolio, newName: string) => Promise<void>;
  isEditing: boolean;
  error: Error | null;
} => {
  const queryClient = useQueryClient();
  const updatePortfolio = useUpdatePortfolio();
  const portfolios = useGlobalStore((state) => state.portfolios);
  const currentPortfolio = useGlobalStore((state) => state.portfolio);

  const editPortfolioName = async (portfolio: Portfolio, newName: string) => {
    const updatedPortfolio = await updatePortfolio.mutateAsync(
      {
        id: portfolio.id,
        name: newName.trim(),
        projects: portfolio.projects.map((project) => project.id),
        is_default: portfolio.is_default,
        description: portfolio.description,
        use_end_date: portfolio.use_end_date,
        organization: portfolio.organization.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['portfolios'] });
        },
      },
    );
    // Update global store
    const updatedPortfolios = portfolios.map((p) => (p.id === updatedPortfolio.id ? updatedPortfolio : p));
    setPortfolios(updatedPortfolios);
    if (currentPortfolio?.id === updatedPortfolio.id) {
      setPortfolio(updatedPortfolio);
    }
  };

  return {
    editPortfolioName,
    isEditing: updatePortfolio.isPending,
    error: updatePortfolio.error,
  };
};

/**
 * Hook for managing portfolio projects
 * @returns Object containing:
 * - addProjectToPortfolio: Function to add a project to a portfolio
 * - removeProjectFromPortfolio: Function to remove a project from a portfolio
 * - isUpdating: Boolean indicating if an update is in progress
 * - error: Any error that occurred during update
 */
export const usePortfolioProjects = (): {
  addProjectToPortfolio: (portfolio: Portfolio, projectId: string) => Promise<void>;
  removeProjectFromPortfolio: (portfolio: Portfolio, projectId: string) => Promise<void>;
  isUpdating: boolean;
  error: Error | null;
} => {
  const queryClient = useQueryClient();
  const updatePortfolio = useUpdatePortfolio();
  const portfolios = useGlobalStore((state) => state.portfolios);
  const currentPortfolio = useGlobalStore((state) => state.portfolio);

  const resetGlobalProcessAnalysisState = (updatedPortfolio: Portfolio) => {
    const updatedPortfolios = portfolios.map((p) => (p.id === updatedPortfolio.id ? updatedPortfolio : p));
    setPortfolios(updatedPortfolios);
    if (currentPortfolio?.id === updatedPortfolio.id) {
      setPortfolio(updatedPortfolio);
    }
  };

  const addProjectToPortfolio = async (portfolio: Portfolio, projectId: string) => {
    if (!portfolio.projects.some((p) => p.id === projectId)) {
      const updatedPortfolio = await updatePortfolio.mutateAsync(
        {
          id: portfolio.id,
          name: portfolio.name,
          projects: [...portfolio.projects.map((p) => p.id), projectId],
          is_default: portfolio.is_default,
          description: portfolio.description,
          use_end_date: portfolio.use_end_date,
          organization: portfolio.organization.id,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['portfolios'] });
            queryClient.invalidateQueries({ queryKey: ['portfolioProjects', portfolio.id] });
          },
        },
      );
      // Update global store
      resetGlobalProcessAnalysisState(updatedPortfolio);
    }
  };

  const removeProjectFromPortfolio = async (portfolio: Portfolio, projectId: string) => {
    const updatedPortfolio = await updatePortfolio.mutateAsync(
      {
        id: portfolio.id,
        name: portfolio.name,
        projects: portfolio.projects.filter((p) => p.id !== projectId).map((p) => p.id),
        is_default: portfolio.is_default,
        description: portfolio.description,
        use_end_date: portfolio.use_end_date,
        organization: portfolio.organization.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['portfolios'] });
          queryClient.invalidateQueries({ queryKey: ['portfolioProjects', portfolio.id] });
        },
      },
    );
    resetGlobalProcessAnalysisState(updatedPortfolio);
  };

  return {
    addProjectToPortfolio,
    removeProjectFromPortfolio,
    isUpdating: updatePortfolio.isPending,
    error: updatePortfolio.error,
  };
};

/**
 * Hook for deleting portfolios
 * @returns Object containing:
 * - deletePortfolio: Function to delete a portfolio
 * - isDeleting: Boolean indicating if deletion is in progress
 * - error: Any error that occurred during deletion
 */
export const usePortfolioDelete = (): {
  deletePortfolio: (portfolio: Portfolio) => Promise<void>;
  isDeleting: boolean;
  error: Error | null;
} => {
  const queryClient = useQueryClient();
  const deletePortfolioMutation = useDeletePortfolio();
  const portfolios = useGlobalStore((state) => state.portfolios);
  const currentPortfolio = useGlobalStore((state) => state.portfolio);

  const deletePortfolio = async (portfolio: Portfolio) => {
    await deletePortfolioMutation.mutateAsync(portfolio, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['portfolios'] });
        // Update global store after successful deletion
        const remainingPortfolios = portfolios.filter((p) => p.id !== portfolio.id);
        setPortfolios(remainingPortfolios);
        if (currentPortfolio?.id === portfolio.id) {
          if (remainingPortfolios.length > 0) {
            setPortfolio(remainingPortfolios[0]);
          } else {
            setPortfolio(null);
          }
        }
      },
    });
  };

  return {
    deletePortfolio,
    isDeleting: deletePortfolioMutation.isPending,
    error: deletePortfolioMutation.error,
  };
};

/**
 * Hook for managing portfolio settings
 * @returns Object containing:
 * - updatePortfolioSettings: Function to update portfolio settings
 * - isUpdating: Boolean indicating if an update is in progress
 * - error: Any error that occurred during update
 */
export const usePortfolioSettings = (): {
  updatePortfolioSettings: (portfolio: Portfolio, settings: { use_end_date?: boolean }) => Promise<void>;
  isUpdating: boolean;
  error: Error | null;
} => {
  const queryClient = useQueryClient();
  const updatePortfolioMutation = useUpdatePortfolio();
  const portfolios = useGlobalStore((state) => state.portfolios);
  const currentPortfolio = useGlobalStore((state) => state.portfolio);

  const updatePortfolioSettings = async (portfolio: Portfolio, settings: { use_end_date?: boolean }) => {
    const updatedPortfolio = await updatePortfolioMutation.mutateAsync(
      {
        id: portfolio.id,
        name: portfolio.name,
        projects: portfolio.projects.map((p) => p.id),
        is_default: portfolio.is_default,
        description: portfolio.description,
        use_end_date: settings.use_end_date ?? portfolio.use_end_date,
        organization: portfolio.organization.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['portfolios'] });
        },
      },
    );
    // Update global store
    const updatedPortfolios = portfolios.map((p) => (p.id === updatedPortfolio.id ? updatedPortfolio : p));
    setPortfolios(updatedPortfolios);
    if (currentPortfolio?.id === updatedPortfolio.id) {
      setPortfolio(updatedPortfolio);
    }
  };

  return {
    updatePortfolioSettings,
    isUpdating: updatePortfolioMutation.isPending,
    error: updatePortfolioMutation.error,
  };
};
