/**
 * Gets the last non-null value from a time series data object.
 *
 * @param timeSeriesData - Object mapping dates to numeric values, or null/undefined
 * @returns The last non-null value as a number, or null if no valid value found
 *
 * @example
 * const data = {'2023-01-01': 42.5, '2023-01-02': null, '2023-01-03': 45.7};
 * getLastValue(data, 'Percentage'); // Returns 45.7
 * getLastValue(null, 'Percentage'); // Returns null
 */

const getLastNotNullValue = (timeSeriesData: Record<string, number> | undefined | null): number | null => {
  if (!timeSeriesData) return null;
  const dates = Object.keys(timeSeriesData).sort();
  for (let i = dates.length - 1; i >= 0; i--) {
    const value = timeSeriesData[dates[i]];
    if (value !== null && value !== undefined) {
      return value;
    }
  }

  return null;
};

export { getLastNotNullValue };
