import { Flex } from '@mantine/core';
import { QueryObserverResult } from '@tanstack/react-query';
import { PortfolioStatusesByProjectsAndBoards } from '../../../../../../api/portfolio-client/portfolio-client.type';
import { Workflow } from '../../../../../../api/workflows-client/workflows-client.type';
import { Loader } from '../../../../../../ui-library/loader/loader';
import { DataManagementDropShadowContainer } from '../../../data-management-header';
import { WorkflowAction } from '../../shared-components/reducer';
import { ViewWorkflowContent } from './view-workflow-content';
import { ViewWorkflowHeader } from './view-workflows-header';

export function ViewWorkflow({
  state,
  dispatch,
  setPageState,
  statusesByProjectsAndBoards,
  workflows,
  workflowsQuery,
}: {
  state: Workflow;
  dispatch: React.Dispatch<WorkflowAction>;
  setPageState: (pageState: 'update' | 'create' | 'idle') => void;
  statusesByProjectsAndBoards: PortfolioStatusesByProjectsAndBoards | undefined;
  workflows: Workflow[];
  workflowsQuery: QueryObserverResult<Workflow[]>;
}) {
  return (
    <DataManagementDropShadowContainer>
      <ViewWorkflowHeader state={state} setPageState={setPageState} />
      {workflowsQuery.isLoading ? (
        <Flex justify="center" align="center" h="100%" w="100%">
          <Loader />
        </Flex>
      ) : (
        <ViewWorkflowContent
          state={state}
          dispatch={dispatch}
          statusesByProjectsAndBoards={statusesByProjectsAndBoards}
          workflows={workflows}
        />
      )}
    </DataManagementDropShadowContainer>
  );
}
