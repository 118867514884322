import { useMutation, useQuery } from '@tanstack/react-query';
import { createWorkflow, deleteWorkflow, getWorkflows, updateWorkflow } from './workflows-client';
import { WorkflowPayload } from './workflows-client.type';

/**
 * Hook to create a workflow
 * @returns {MutationResult} The mutation result
 */
function useCreateWorkflow() {
  const mutation = useMutation({
    mutationFn: createWorkflow,
  });

  return mutation;
}

/**
 * Hook to update a workflow
 * @returns {MutationResult} The mutation result
 */
function useUpdateWorkflow() {
  const mutation = useMutation({
    mutationFn: ({ workflowId, payload }: { workflowId: string | null; payload: WorkflowPayload }) =>
      workflowId ? updateWorkflow({ workflowId, payload }) : Promise.reject({ error: 'Workflow ID is required' }),
  });

  return mutation;
}

/**
 * Hook to get workflows
 * @param {string | null} portfolioId The portfolio ID
 * @returns {QueryResult} The query result
 */
function useGetWorkflows(portfolioId: string | null) {
  const query = useQuery({
    queryKey: ['workflows', portfolioId],
    queryFn: portfolioId
      ? () => getWorkflows(portfolioId)
      : () => Promise.reject({ error: 'Portfolio ID is required' }),
  });

  return { data: query.data, query };
}

/**
 * Hook to delete a workflow
 * @returns {MutationResult} The mutation result
 */
function useDeleteWorkflow() {
  const mutation = useMutation({
    mutationFn: deleteWorkflow,
  });

  return mutation;
}

export { useCreateWorkflow, useDeleteWorkflow, useGetWorkflows, useUpdateWorkflow };
