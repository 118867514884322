import { styled } from '@linaria/react';
import { AppShell } from '@mantine/core';
import { ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../components/user/user';
import { UserContext } from '../../contexts/user';
import { isString } from '../../helpers/string-helpers/string-helpers';
import { secondaryBase, skyLighter } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { H2 } from '../../ui-library/typography/typography';
import { FloraButton } from '../flora/flora-button';

type Props = {
  header?: string | ReactNode;
};

export function HeaderBar({ header }: Props) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const headerComponent = isString(header) ? <H2>{header}</H2> : header;

  return (
    <AppShell.Header style={{ backgroundColor: skyLighter, maxWidth: '100%', zIndex: 400 }}>
      <Container style={{ justifyContent: header ? 'space-between' : 'flex-end', gap: 8 }}>
        {headerComponent}
        <ProfileContainer>
          <FloraButton />
          <Icon name="help_outline" color={secondaryBase} size={28} onClick={() => navigate(`/application/help`)} />
          <User user={user} />
        </ProfileContainer>
      </Container>
    </AppShell.Header>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  padding: 24px 16px 0px 16px;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1em;
`;
