import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { completeOauth } from '../../../api/integrations-client/integrations-client';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { UserContext } from '../../../contexts';
import { useOrganizationId } from '../../../helpers/auth-helpers/auth.hooks';
import { IntegrationsContext } from '../context/integrations.context';
import { completeReauth } from '../integrations.helpers';
import { useRedirect } from '../integrations.hooks';
import { ADOResponse } from './ado.type';

/**
 * Custom hook to handle OAuth authentication flow for Azure DevOps integration
 *
 * @param {Object} params - Hook parameters
 * @param {function} params.onSuccess - Callback function called when OAuth flow completes successfully
 * @returns {Object} Object containing the OAuth completion query result
 * @property {QueryObserverResult<Record<string, unknown>>} completeOauthQuery - Query result for the OAuth completion request
 */
const useOauth = ({
  onSuccess,
}: {
  onSuccess: (data: ADOResponse) => void;
}): { completeOauthQuery: QueryObserverResult<Record<string, unknown>> } => {
  const { user } = useContext(UserContext);
  const { query: integrationsQuery } = useContext(IntegrationsContext);
  const organizationId = useOrganizationId();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { redirect, setRedirect } = useRedirect();

  const completeOauthQuery = useQuery({
    queryKey: [
      'complete-oauth',
      ExternalService.ADO,
      user?.id ?? null,
      organizationId ?? null,
      searchParams.get('code'),
      searchParams.get('state'),
    ] as const,
    queryFn: async () => {
      if (!user?.id || !organizationId || !searchParams.get('code') || !searchParams.get('state')) {
        throw new Error('Missing required parameters');
      }
      return await completeOauth(
        ExternalService.ADO,
        user.id.toString(),
        organizationId,
        searchParams.get('code')!,
        searchParams.get('state')!,
      );
    },
    enabled: !!user?.id && !!organizationId && !!searchParams.get('code') && !!searchParams.get('state'),
  });

  const handleSettled = useCallback(() => {
    const paramsToDelete = ['code', 'state', 'RESPONSE_TYPE', 'STATE', 'SCOPE', 'REDIRECT_URI'];
    paramsToDelete.forEach((param) => searchParams.delete(param));
    setSearchParams(searchParams);

    if (redirect) {
      completeReauth();
      navigate(redirect);
      integrationsQuery.refetch();
      setRedirect(null);
    }
  }, [redirect, navigate, integrationsQuery, setRedirect, searchParams, setSearchParams]);

  useEffect(() => {
    if (completeOauthQuery.isSuccess && completeOauthQuery.data) {
      onSuccess(completeOauthQuery.data as ADOResponse);
    }
  }, [completeOauthQuery.isSuccess, completeOauthQuery.data, onSuccess]);

  useEffect(() => {
    if (completeOauthQuery.isSuccess || completeOauthQuery.isError) {
      handleSettled();
    }
  }, [completeOauthQuery.isSuccess, completeOauthQuery.isError, handleSettled]);

  return {
    completeOauthQuery,
  };
};

export { useOauth };
