import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { AllocationOfCostsCategory, ViewBy } from '../../api/financials-client/financials-client.type';
import { getRecentlyCompletedMonth } from '../general-helpers';
import { AuthenticationObject, AuthenticationStorage } from './storage.type';

const env = import.meta.env.VITE_APP_ENV_NAME;

/**
 * Logs out the user
 *
 * @returns void
 */
function logout(): void {
  clearAuthenticationStore();
  window.location.replace('/');
  window.sessionStorage.clear();
  window.localStorage.clear();
  window.location.reload();
}

/**
 * Gets the stored Authentication Object
 *
 * @returns stored Authentication Object
 */
function getStoredAuthenticationObject(): AuthenticationStorage {
  const applicationStorage = {
    access: '',
    refresh: '',
  };
  applicationStorage.access = Cookies.get('access') || '';
  applicationStorage.refresh = Cookies.get('refresh') || '';

  return applicationStorage;
}

/**
 * Method to set the authentication object in the cookies
 *
 * @param authenticationObject a dict containing the access and refresh tokens
 */
function storeAuthenticationObject(authenticationObject: AuthenticationObject): void {
  /* To be uncommented when we handle multiple user logging into same browser in same machine*/
  // Cookies.set("user", JSON.stringify(authenticationObject.user));
  Cookies.set('access', authenticationObject.access || '', { secure: env !== 'development' });
  Cookies.set('refresh', authenticationObject.refresh || '', { secure: env !== 'development' });
}

/**
 * Refreshes the stored tokens
 *
 * @param access: the encoded access token
 * @param refresh: the encoded refresh token
 */
function refreshAuthenticationStore(access: string, refresh: string): void {
  Cookies.set('access', access);
  Cookies.set('refresh', refresh);
}

/**
 * Decodes a token and checks if it is expired
 *
 * @param token: the encoded token to check
 * @returns Boolean
 */
function isTokenExpired(token: string): boolean {
  const { exp } = jwtDecode(token) as { exp: number };
  return !exp || new Date(exp * 1000) < new Date();
}

/**
 * Clears the authentication store
 *
 * @returns void
 */
function clearAuthenticationStore(): void {
  Cookies.remove('access', { path: '/' });
  Cookies.remove('refresh', { path: '/' });
  Sentry.setUser(null);
}

/**
 * Removes all cookies
 *
 * @returns void
 */
function removeAllCookies(): void {
  const cookies = Cookies.get();

  for (const cookieName in cookies) {
    Cookies.remove(cookieName);
  }
}

/**
 * fetches the access token from cookies
 *
 * @returns the access token
 */
function getRequestToken(): string | undefined {
  return Cookies.get('access');
}

/**
 * fetches the refresh token from cookies
 *
 * @returns the refresh token
 */
function getRefreshToken(): string | undefined {
  return Cookies.get('refresh');
}

function getSelectedSubprojectId(): string | null {
  return localStorage.getItem('subproject');
}

function setSelectedSubprojectId(subprojectId: string): void {
  localStorage.setItem('subproject', subprojectId);
}

function deleteSelectedSubprojectId(): void {
  localStorage.removeItem('subproject');
}

function getFinancialsStartDate(): Date {
  const storedDate = window.sessionStorage.getItem('financialsStartDate');
  if (storedDate) {
    return new Date(storedDate);
  }

  // return the most recent 3 months (quarter) of data
  const recentlyCompletedMonth = getRecentlyCompletedMonth();
  let year = recentlyCompletedMonth.getFullYear();
  let month = recentlyCompletedMonth.getMonth() - 3;

  if (month < 0) {
    month += 12;
    year -= 1;
  }

  return new Date(year, month, 1);
}

function setFinancialsStartDate(date: Date): void {
  window.sessionStorage.setItem('financialsStartDate', date.toLocaleDateString());
}

function getFinancialsEndDate(): Date {
  return new Date(window.sessionStorage.getItem('financialsEndDate') || getRecentlyCompletedMonth());
}

function setFinancialsEndDate(date: Date): void {
  window.sessionStorage.setItem('financialsEndDate', date.toLocaleDateString());
}

function getAllocationOfCostsCategory(): AllocationOfCostsCategory {
  return (
    (window.sessionStorage.getItem('allocationOfCostsCategory') as AllocationOfCostsCategory) ||
    AllocationOfCostsCategory.ASSIGNEE
  );
}

function setAllocationOfCostsCategory(category: AllocationOfCostsCategory): void {
  window.sessionStorage.setItem('allocationOfCostsCategory', category);
}

function getAllocationOfCostsUnit(): ViewBy {
  const fromStorage = window.sessionStorage.getItem('allocationOfCostsUnit');
  if (!fromStorage || !Object.values(ViewBy).includes(fromStorage as ViewBy)) {
    return ViewBy.TASKS;
  }
  return fromStorage as ViewBy;
}

function setAllocationOfCostsUnit(unit: ViewBy): void {
  window.sessionStorage.setItem('allocationOfCostsUnit', unit);
}

export {
  clearAuthenticationStore,
  deleteSelectedSubprojectId,
  getAllocationOfCostsCategory,
  getAllocationOfCostsUnit,
  getFinancialsEndDate,
  getFinancialsStartDate,
  getRefreshToken,
  getRequestToken,
  getSelectedSubprojectId,
  getStoredAuthenticationObject,
  isTokenExpired,
  logout,
  refreshAuthenticationStore,
  removeAllCookies,
  setAllocationOfCostsCategory,
  setAllocationOfCostsUnit,
  setFinancialsEndDate,
  setFinancialsStartDate,
  setSelectedSubprojectId,
  storeAuthenticationObject,
};
