import { Route, Routes } from 'react-router-dom';
import { FinancialsTasks } from '../../components/financials-tasks/financials-tasks';
import { OutOfBloom } from '../../components/out-of-bloom/out-of-bloom';
import { ProjectProvider } from '../../contexts';
import { AddEditFinancials } from '../add-edit-financials/add-edit-financials';
import { Adherence } from '../adherence/adherence';
import { Dashboard } from '../dashboard/dashboard';
import { Dashboards } from '../dashboards/dashboards';
import { DataManagement } from '../data-management/data-management';
import { Financials } from '../financials/financials';
import { Help } from '../help/help';
import { AddNewIntegrationAuthorize } from '../integrations/add-new-integration/add-new-integration-authorize';
import { Integrations } from '../integrations/integrations';
import { IntegrationsList } from '../integrations/integrations-list/integrations-list';
import { TermsAndConditions } from '../legal/terms-and-conditions';
import { AssessmentTaskFlow } from '../process-analysis/assessment-view/assessment-task-flow';
import { ProcessAnalysis } from '../process-analysis/process-analysis';
import { ProcessAnalysisAuditLog } from '../process-analysis/process-analysis-audit-log';
import { ProcessAnalysisAuditLogTasks } from '../process-analysis/process-analysis-audit-log-tasks';
import { ProcessAnalysisTasks } from '../process-analysis/process-analysis-tasks';
import { Process } from '../process/process';
import { TaskDetails } from '../process/task-details/task-details';
import { GeneralViewNames } from '../side-bar/side-bar.type';
import { StrategyV2 } from '../strategy-v2/strategy-v2';
import { InitiativePerformance } from '../strategy/initiative-performance/initiative-performance';
import { InitiativeTasks } from '../strategy/initiative-tasks/initiative-tasks';
import { Strategy } from '../strategy/strategy';
import styles from './navigation.module.css';

const strategyV2Enabled = import.meta.env.VITE_FEATURE_FLAG_STRATEGY_V2 === 'true';

/**
 * How to add a new protected route:
 * (unprotected routes can be modified in the App component)
 *
 * Create a new entry in this list of routes.
 * 1. The view property is used to determine which navigation item is highlighted in the sidebar.
 *    (null can be passed if the route should not highlight any navigation item).
 * 2. The path property is used by the navigator to determine which route to render.
 *    Path must be unique.
 * 3. The element property is the component that will be rendered when the path is matched.
 */
export const ROUTES = [
  { view: GeneralViewNames.Adherence, path: 'adherence', element: <Adherence /> },
  { view: GeneralViewNames.Portfolio, path: 'dashboard', element: <Dashboard /> },
  { view: GeneralViewNames.Dashboards, path: 'dashboards', element: <Dashboards /> },
  { view: GeneralViewNames.Strategy, path: 'strategy', element: strategyV2Enabled ? <StrategyV2 /> : <Strategy /> },
  { view: GeneralViewNames.DataManagement, path: 'data-management', element: <DataManagement /> },
  {
    view: GeneralViewNames.Strategy,
    path: 'strategy/:portfolioId/:initiativeId',
    element: <InitiativePerformance />,
  },
  {
    view: GeneralViewNames.Strategy,
    path: 'strategy/:portfolioId/:initiativeId/tasks',
    element: <InitiativeTasks />,
  },
  { view: GeneralViewNames.Process, path: 'process', element: <Process /> },
  { view: GeneralViewNames.Process, path: 'process/task-details', element: <TaskDetails /> },
  { view: GeneralViewNames.ProcessAnalysis, path: 'process-analysis', element: <ProcessAnalysis /> },
  { view: GeneralViewNames.ProcessAnalysis, path: 'process-analysis/tasks', element: <ProcessAnalysisTasks /> },
  { view: GeneralViewNames.ProcessAnalysis, path: 'process-analysis/task-flow', element: <AssessmentTaskFlow /> },
  { view: GeneralViewNames.ProcessAnalysis, path: 'process-analysis/audit-log', element: <ProcessAnalysisAuditLog /> },
  {
    view: GeneralViewNames.ProcessAnalysis,
    path: 'process-analysis/audit-log/:taskId',
    element: <ProcessAnalysisAuditLogTasks />,
  },
  { view: GeneralViewNames.Integrations, path: 'integrations', element: <Integrations /> },
  { view: GeneralViewNames.Integrations, path: 'integrations/jira', element: <Integrations view="jira" /> },
  { view: GeneralViewNames.Integrations, path: 'integrations/jdc', element: <Integrations view="jdc" /> },
  { view: GeneralViewNames.Integrations, path: 'integrations/github', element: <Integrations view="github" /> },
  { view: GeneralViewNames.Integrations, path: 'integrations/figma', element: <Integrations view="figma" /> },
  { view: GeneralViewNames.Integrations, path: 'integrations/ado', element: <Integrations view="ado" /> },
  { view: GeneralViewNames.Integrations, path: 'integrations/gitlab', element: <Integrations view="gitlab" /> },
  { view: GeneralViewNames.Integrations, path: 'integrations/aha', element: <Integrations view="aha" /> },
  { view: GeneralViewNames.Integrations, path: 'integrations/bitbucket', element: <Integrations view="bitbucket" /> },
  { view: GeneralViewNames.Integrations, path: 'integrations/notion', element: <Integrations view="notion" /> },
  { view: GeneralViewNames.Integrations, path: 'integrations/confluence', element: <Integrations view="confluence" /> },
  { view: GeneralViewNames.Integrations, path: 'integrations/authorize', element: <AddNewIntegrationAuthorize /> },
  { view: GeneralViewNames.Integrations, path: 'integrations-list', element: <IntegrationsList /> },
  { view: GeneralViewNames.Financials, path: 'financials/portfolio/:portfolioId?', element: <Financials /> },
  {
    view: GeneralViewNames.Financials,
    path: 'financials/portfolio/:portfolioId/team/:teamId/subproject/:subprojectId',
    element: <Financials />,
  },
  {
    view: GeneralViewNames.Financials,
    path: 'financials/portfolio/:portfolioId/team/:teamId',
    element: <Financials />,
  },
  {
    view: GeneralViewNames.Financials,
    path: 'financials/portfolio/:portfolioId/add-edit-financials',
    element: <AddEditFinancials />,
  },
  {
    view: GeneralViewNames.Financials,
    path: 'financials/portfolio/:portfolioId/tasks',
    element: <FinancialsTasks />,
  },
  { view: null, path: 'legal/terms-and-conditions', element: <TermsAndConditions /> },
  { view: null, path: 'help', element: <Help /> },
];

export function NavigationContainer() {
  return (
    <div className={styles.navigationContainer}>
      <div className={styles.mainView}>
        <Routes>
          {ROUTES.map(({ path, element }) => (
            <Route key={path} path={path} element={<ProjectProvider>{element}</ProjectProvider>} />
          ))}
          {/* Default view to render incase of a 404 */}
          <Route path="*" element={<OutOfBloom />} />
        </Routes>
      </div>
    </div>
  );
}
