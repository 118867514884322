import { Box, Text } from '@mantine/core';
import React from 'react';

interface TileWidgetProps {
  data: {
    average: number;
    total: number;
  };
}

export const TileWidget: React.FC<TileWidgetProps> = ({ data }) => {
  return (
    <Box>
      <Text size="xl" fw={700} c="blue">
        {data.average.toLocaleString()}
      </Text>
      <Text size="sm" c="dimmed" mt={4}>
        Total: {data.total.toLocaleString()}
      </Text>
    </Box>
  );
};
