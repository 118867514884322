import { useDisclosure } from '@mantine/hooks';
import { useEffect, useReducer, useState } from 'react';
import { usePortfolioStatusesByProjectsAndBoards } from '../../../../api/portfolio-client/portfolio-client.hooks';
import { Workflow } from '../../../../api/workflows-client/workflows-client.type';
import {
  useCreateWorkflow,
  useDeleteWorkflow,
  useGetWorkflows,
  useUpdateWorkflow,
} from '../../../../api/workflows-client/workflows.client.hooks';
import { useGlobalStore } from '../../../../store/global-store/global-store';
import { WorkflowAction, workflowReducer } from './shared-components/reducer';
import { WorkflowsContainer } from './shared-components/styles';
import { WorkflowsList } from './shared-components/workflows-list';
import { CreateWorkflow } from './workflow/create/create-workflow';
import { DeleteWorkflowModal } from './workflow/edit/delete-workflow-modal';
import { EditWorkflow } from './workflow/edit/edit-workflow';
import { ViewWorkflow } from './workflow/view/view-workflow';

export function Workflows({ isDirty, setIsDirty }: { isDirty: boolean; setIsDirty: (isDirty: boolean) => void }) {
  const portfolio = useGlobalStore((state) => state.portfolio);
  const [pageState, setPageState] = useState<'update' | 'create' | 'idle'>('idle');
  const [deleteWorkflowModalOpened, { open: openDeleteWorkflowModal, close: closeDeleteWorkflowModal }] =
    useDisclosure(false);

  const [state, dispatch] = useReducer<React.Reducer<Workflow, WorkflowAction>>(workflowReducer, {} as Workflow);

  const { data: workflows, query: workflowsQuery } = useGetWorkflows(portfolio?.id || null);
  const { statusesByProjectsAndBoards, query: statusesByProjectsAndBoardsQuery } =
    usePortfolioStatusesByProjectsAndBoards(portfolio?.id || null);
  const { mutate: createWorkflow } = useCreateWorkflow();
  const { mutate: updateWorkflow } = useUpdateWorkflow();
  const { mutate: deleteWorkflow } = useDeleteWorkflow();

  useEffect(() => {
    if (pageState === 'idle') {
      setIsDirty(false);
    }
  }, [pageState, setIsDirty]);

  useEffect(() => {
    if (pageState === 'create' || pageState === 'update') {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setIsDirty]);

  // If the page is idle, select the first workflow if one is not already selected
  useEffect(() => {
    if (
      pageState === 'idle' &&
      workflowsQuery.isSuccess &&
      workflows &&
      workflows.length &&
      statusesByProjectsAndBoardsQuery.isSuccess
    ) {
      if (state.id === null) {
        dispatch({
          type: 'SET_WORKFLOW',
          payload: workflows[0],
        });
      } else {
        dispatch({
          type: 'SET_WORKFLOW',
          payload: workflows.find((workflow) => workflow.id === state.id) ?? workflows[0],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflows, workflowsQuery.isSuccess, statusesByProjectsAndBoards, statusesByProjectsAndBoardsQuery.isSuccess]);

  return (
    <WorkflowsContainer>
      <WorkflowsList
        onCreate={
          pageState === 'idle'
            ? () => {
                dispatch({
                  type: 'SET_WORKFLOW',
                  payload: {} as Workflow,
                });
                setPageState('create');
              }
            : undefined
        }
        selectedWorkflowId={state.id}
        onSelectWorkflow={(workflow) => {
          if (isDirty) {
            if (
              window.confirm(
                "This page is asking you to confirm that you want to leave - information you've entered may not be saved.",
              )
            ) {
              dispatch({
                type: 'SET_WORKFLOW',
                payload: workflow,
              });
              setPageState('idle');
            }
          } else {
            dispatch({
              type: 'SET_WORKFLOW',
              payload: workflow,
            });
            setPageState('idle');
          }
        }}
        workflows={workflows || []}
      />
      {pageState === 'idle' && (
        <ViewWorkflow
          state={state}
          dispatch={dispatch}
          setPageState={setPageState}
          statusesByProjectsAndBoards={statusesByProjectsAndBoards}
          workflows={workflows || []}
          workflowsQuery={workflowsQuery}
        />
      )}
      {pageState === 'update' && (
        <EditWorkflow
          state={state}
          dispatch={dispatch}
          setPageState={setPageState}
          isDirty={isDirty}
          handleSelectWorkflow={(workflow: Workflow) => dispatch({ type: 'SET_WORKFLOW', payload: workflow })}
          statusesByProjectsAndBoards={statusesByProjectsAndBoards}
          workflows={workflows || []}
          updateWorkflow={updateWorkflow}
          workflowsQuery={workflowsQuery}
          openDeleteWorkflowModal={openDeleteWorkflowModal}
        />
      )}
      {pageState === 'create' && (
        <CreateWorkflow
          state={state}
          dispatch={dispatch}
          setPageState={setPageState}
          isDirty={isDirty}
          handleSelectWorkflow={(workflow: Workflow) => dispatch({ type: 'SET_WORKFLOW', payload: workflow })}
          statusesByProjectsAndBoards={statusesByProjectsAndBoards}
          workflows={workflows || []}
          createWorkflow={createWorkflow}
          workflowsQuery={workflowsQuery}
        />
      )}
      <DeleteWorkflowModal
        opened={deleteWorkflowModalOpened}
        workflowName={state.name}
        onClose={closeDeleteWorkflowModal}
        onConfirm={() => {
          closeDeleteWorkflowModal();
          if (!state.id) {
            return;
          }
          deleteWorkflow(state.id, {
            onSuccess: () => {
              dispatch({
                type: 'SET_WORKFLOW',
                payload: {} as Workflow,
              });
              setPageState('idle');
              workflowsQuery.refetch();
            },
          });
        }}
      />
    </WorkflowsContainer>
  );
}
