import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import { resetLocalMessage } from '../../store/process-analysis-store/process-analysis-store.actions';
import { useViewType } from '../../store/process-analysis-store/process-analysis-store.hooks';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { PageContainer } from '../process/process.styled';
import { AssessmentView } from './assessment-view/assessment-view';
import { ComparisonView } from './comparison-view/comparison-view';
import { ProcessAnalysisProvider } from './process-analysis.context';
import { useAvailableMeasuresData } from './process-analysis.hooks';
import { Tab, ViewType, WorkPeriodType } from './process-analysis.type';
import { ScopeExplorer } from './scope-explorer/scope-explorer';
import { SingularView } from './singular-view';
import { SprintComparisonView } from './sprint-comparison-view/sprint-comparison-view';

const isSprintComparisonEnabled = import.meta.env.VITE_FEATURE_FLAG_SPRINT_COMPARISON === 'true';

export function ProcessAnalysis() {
  useDocumentTitle('Process Analysis - Bloomfilter');
  const viewType = useViewType();
  const localMessage = useProcessAnalysisStore((state) => state.localMessage);
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const showSprintComparison = useProcessAnalysisStore((state) => state.showSprintComparison);
  const { isFetching: isFetchingAvailableMeasures } = useAvailableMeasuresData();

  const [init, setInit] = useState<boolean>(false);
  useEffectOnce(() => setInit(true));

  return (
    <ProcessAnalysisProvider>
      <MenuDrivenContainer header="Process Analysis">
        <BrandedLoadingOverlay visible={isFetchingAvailableMeasures} transitionDuration={30} variant="colored" />
        <PageContainer style={{ maxWidth: '99%' }}>
          {localMessage && activeTab === Tab.WorkPeriods && workPeriodType === WorkPeriodType.Defined && (
            <PageHeaderMessage message={localMessage} color="red" onClose={resetLocalMessage} />
          )}
          <ScopeExplorer />
          {init && (
            <>
              {viewType === ViewType.Comparison && <ComparisonView />}
              {viewType === ViewType.Single && <SingularView />}

              {/* For custom work periods, always show the Assessment view */}
              {viewType === ViewType.Assessment && workPeriodType === WorkPeriodType.Custom && <AssessmentView />}

              {/* For defined work periods, toggle between Assessment and Sprint Comparison */}
              {viewType === ViewType.Assessment &&
                workPeriodType === WorkPeriodType.Defined &&
                !showSprintComparison && <AssessmentView />}
              {viewType === ViewType.Assessment &&
                isSprintComparisonEnabled &&
                workPeriodType === WorkPeriodType.Defined &&
                showSprintComparison && <SprintComparisonView />}
            </>
          )}
        </PageContainer>
      </MenuDrivenContainer>
    </ProcessAnalysisProvider>
  );
}
