import { styled } from '@linaria/react';
import { Checkbox, MultiSelect, Paper, Text } from '@mantine/core';
import { Fragment, useMemo, useState } from 'react';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { newCOLORS } from '../../../styles/colors';
import { RiskAnalysisInitiativeTable } from './risk-analysis-initiative-table';
import { RiskAnalysisProjectList } from './risk-analysis-project-list';
import { RiskAnalysisTaskTable } from './risk-analysis-task-table';
import { getPlaceholder } from './risk-analysis.helpers';
import {
  useRiskAnalysisCreators,
  useRiskAnalysisEpics,
  useRiskAnalysisInitiatives,
  useRiskAnalysisInitiativesOptions,
  useRiskAnalysisProjectOptions,
  useRiskAnalysisTaskTypes,
  useRiskAnalysisTasks,
} from './risk-analysis.hooks';
import { RiskScoreChart } from './risk-score-chart';
import { TaskTypeChart } from './task-type-chart';

export function RiskAnalysis() {
  const useAI = useGlobalStore((state) => state.useAI);
  const portfolio = useGlobalStore((state) => state.portfolio);
  const [creatorFilters, setCreatorFilters] = useState<string[]>([]);
  const [projectFilters, setProjectFilters] = useState<string[]>([]);
  const [typeFilters, setTypeFilters] = useState<string[]>([]);
  const [epicFilters, setEpicFilters] = useState<string[]>([]);
  const [initiativeFilters, setInitiativeFilters] = useState<string[]>([]);
  const [includeDone, setIncludeDone] = useState(true);

  const { data: tasks, isFetching: tasksIsFetching } = useRiskAnalysisTasks(portfolio?.id ?? '');
  const { data: initiatives, isFetching: initiativesIsFetching } = useRiskAnalysisInitiatives(portfolio?.id ?? '');

  const taskTypes = useRiskAnalysisTaskTypes(tasks ?? []);
  const epics = useRiskAnalysisEpics(tasks ?? []);
  const creators = useRiskAnalysisCreators(tasks ?? []);
  const projectOptions = useRiskAnalysisProjectOptions(tasks ?? []);
  const initiativeOptions = useRiskAnalysisInitiativesOptions(initiatives ?? []);

  const filteredTasks = useMemo(
    () =>
      (tasks ?? []).filter((task) => {
        const creatorMatch = creatorFilters.length === 0 || creatorFilters.includes(task.reporter?.name || '');
        const projectMatch = projectFilters.length === 0 || projectFilters.includes(task.project.name);
        const typeMatch = typeFilters.length === 0 || typeFilters.includes(task.type);
        const epicMatch = epicFilters.length === 0 || epicFilters.includes(task.epic?.name || '');
        const initiativeMatch =
          initiativeFilters.length === 0 ||
          task.initiatives?.some((initiative) => initiativeFilters.includes(initiative.id));

        const doneMatch = includeDone ? true : task.done_date === null;

        return creatorMatch && projectMatch && typeMatch && epicMatch && initiativeMatch && doneMatch;
      }),
    [tasks, creatorFilters, projectFilters, typeFilters, epicFilters, initiativeFilters, includeDone],
  );

  return (
    <Fragment>
      <BrandedLoadingOverlay
        visible={tasksIsFetching || initiativesIsFetching}
        transitionDuration={30}
        variant="colored"
      />
      <FiltersContainer>
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <StyledMultiSelect
            placeholder={getPlaceholder(projectFilters, 'Filter by Project')}
            data={projectOptions}
            value={projectFilters}
            onChange={setProjectFilters}
            searchable
            clearable
          />
          <StyledMultiSelect
            placeholder={getPlaceholder(initiativeFilters, 'Filter by Initiative')}
            data={initiativeOptions}
            value={initiativeFilters}
            onChange={setInitiativeFilters}
            searchable
            clearable
          />
          <StyledMultiSelect
            placeholder={getPlaceholder(typeFilters, 'Filter by Type')}
            data={taskTypes}
            value={typeFilters}
            onChange={setTypeFilters}
            searchable
            clearable
          />
          <StyledMultiSelect
            placeholder={getPlaceholder(epicFilters, 'Filter by Epic')}
            data={epics}
            value={epicFilters}
            onChange={setEpicFilters}
            searchable
            clearable
          />
          <StyledMultiSelect
            placeholder={getPlaceholder(creatorFilters, 'Filter by Creator')}
            data={creators}
            value={creatorFilters}
            onChange={setCreatorFilters}
            searchable
            clearable
          />
        </div>
        <StyledCheckbox
          label="Include Done Tasks"
          checked={includeDone}
          onChange={(event) => setIncludeDone(event.currentTarget.checked)}
        />
      </FiltersContainer>

      {useAI ? (
        <Fragment>
          <OverviewContainer>
            <OverviewCard>
              <OverviewHeader>Task Breakdown by Type</OverviewHeader>
              <TaskTypeChart tasks={filteredTasks} />
            </OverviewCard>
            <OverviewCard>
              <OverviewHeader>Average Risk Score</OverviewHeader>
              <RiskScoreChart tasks={filteredTasks} />
            </OverviewCard>
            <OverviewCard>
              <OverviewHeader>Average Risk Score by Project</OverviewHeader>
              <RiskAnalysisProjectList tasks={filteredTasks} />
            </OverviewCard>
          </OverviewContainer>

          <TableContainer>
            <RiskAnalysisInitiativeTable initiatives={initiatives ?? []} />
            <div style={{ marginTop: 24 }}>
              <RiskAnalysisTaskTable tasks={filteredTasks} />
            </div>
          </TableContainer>
        </Fragment>
      ) : (
        <Fragment>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
              textAlign: 'center',
            }}
          >
            <Text size="xl" fw={500} c="dimmed">
              AI analysis is not enabled for this organization
            </Text>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 16px 8px;
`;

const OverviewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 8px;
`;

const OverviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;

const OverviewCard = styled(Paper)`
  background-color: ${newCOLORS.white};
  border-radius: 8px;
  padding: 16px;
`;

const TableContainer = styled.div`
  margin-left: 8px;
  margin-right: 40px;
`;

const StyledCheckbox = styled(Checkbox)`
  .mantine-Checkbox-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledMultiSelect = styled(MultiSelect)`
  width: 200px;

  .mantine-Input-input {
    padding-right: 30px;
  }

  .mantine-Input-input:not([data-expanded]) .mantine-MultiSelect-pillsList:has(span) > .mantine-MultiSelect-inputField {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  .mantine-MultiSelect-pill {
    max-width: 150px;
  }
`;
