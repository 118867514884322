import { Table } from '@mantine/core';
import React from 'react';

interface TableWidgetProps {
  data: {
    id: string;
    name: string;
    date: string;
    value: number;
  }[];
}

export const TableWidget: React.FC<TableWidgetProps> = ({ data }) => {
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Object Name</Table.Th>
          <Table.Th>Date</Table.Th>
          <Table.Th>Value</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {data.map(
          (row) =>
            row.value > 0 && (
              <Table.Tr key={row.id}>
                <Table.Td>{row.name}</Table.Td>
                <Table.Td>{row.date}</Table.Td>
                <Table.Td>{row.value.toLocaleString()}</Table.Td>
              </Table.Tr>
            ),
        )}
      </Table.Tbody>
    </Table>
  );
};
