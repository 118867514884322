import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Link } from 'react-router-dom';
import { icons } from '../../assets/icons/icons';
import { newCOLORS } from '../../styles/colors';

type PageHeaderMessageProps = {
  message: string | React.ReactNode;
  color: 'red' | 'yellow' | 'green' | 'gray';
  navigationMessage?: string;
  navigationPath?: string;
  messageAfterLink?: string;
  onClose?: () => void;
};

export const PageHeaderMessage = ({
  message,
  color,
  navigationMessage,
  navigationPath,
  messageAfterLink,
  onClose,
}: PageHeaderMessageProps) => {
  let icon = null;
  let borderColor = null;
  switch (color) {
    case 'red':
      icon = icons.iconRedAlert;
      borderColor = newCOLORS.red;
      break;
    case 'yellow':
      icon = icons.iconYellowAlert;
      borderColor = newCOLORS.darkYellow;
      break;
    case 'green':
      icon = icons.iconGreenAlert;
      borderColor = newCOLORS.green;
      break;
    case 'gray':
      icon = icons.iconGrayAlert;
      borderColor = newCOLORS.darkGray;
      break;
    default:
      icon = icons.iconRedAlert;
      borderColor = newCOLORS.red;
      break;
  }

  return (
    <PageHeaderMessageContainer borderColor={borderColor}>
      <PageHeaderMessageContent>
        <img src={icon} alt="notification" />
        <div>{message}</div>
        {navigationMessage && navigationPath ? (
          <Link to={navigationPath} target="_blank" rel="noreferrer">
            {navigationMessage}
          </Link>
        ) : null}
        {messageAfterLink ? <div>{messageAfterLink}</div> : null}
      </PageHeaderMessageContent>
      {onClose ? (
        <Icon icon="jam:close" width={24} height={24} style={{ cursor: 'pointer' }} onClick={onClose} />
      ) : null}
    </PageHeaderMessageContainer>
  );
};

const PageHeaderMessageContainer = styled.div<{ borderColor: string }>`
  border: 2px solid ${(props: { borderColor: string }) => props.borderColor};
  background-color: ${(props: { borderColor: string }) => props.borderColor + '10'};
  padding: 10px;
  max-width: 95%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;

const PageHeaderMessageContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;
