import { newCOLORS } from '../../../styles/colors';

/**
 * Determines the risk category and associated color based on a risk score.
 *
 * @param {number | undefined | null} score - The risk score to categorize
 * @returns {{ label: string; color: string }} An object containing:
 *   - label: The risk category label ('High', 'Medium', 'Low', or 'N/A')
 *   - color: The color associated with the risk category from the color palette
 */
const getRiskCategory = (score: number | undefined | null): { label: string; color: string } => {
  if (score === undefined || score === null) {
    return { label: 'N/A', color: newCOLORS.gray };
  }

  if (score > 7) {
    return { label: 'High', color: newCOLORS.red };
  }
  if (score >= 4) {
    return { label: 'Medium', color: newCOLORS.orange };
  }
  return { label: 'Low', color: newCOLORS.green };
};

/**
 * Returns a placeholder string if the provided array is empty, undefined otherwise.
 *
 * @template T The type of elements in the array
 * @param {T[]} value - The array to check for emptiness
 * @param {string} placeholder - The placeholder text to return if array is empty
 * @returns {string | undefined} The placeholder string if array is empty, undefined otherwise
 */
const getPlaceholder = <T>(value: T[], placeholder: string): string | undefined => {
  return value.length > 0 ? undefined : placeholder;
};

export { getPlaceholder, getRiskCategory };
