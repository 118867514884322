import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ComponentData } from '../../../api/pages-client/pages-client.type';
import { getWorkPeriodsMeasure } from '../../../api/work-periods-client/work-periods-client';
import {
  GetWorkPeriodsMeasurePayload,
  Measure,
  MeasureDataResponse,
  Transformer,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { ProcessedWidgetData, WidgetDataPoint } from './dashboards.hooks.types';

/**
 * Hook to fetch and process widget data
 * @param {ComponentData} componentData - The component data to fetch data for
 * @returns {ProcessedWidgetData} - The processed widget data
 */
const useWidgetData = (componentData: ComponentData): ProcessedWidgetData => {
  const portfolios = useGlobalStore((state) => state.portfolios);
  const teams = useGlobalStore((state) => state.teams);

  const boardNameMap = useMemo(() => {
    const map = new Map<string, string>();
    teams.forEach((team) => {
      team.subprojects.forEach((board) => {
        map.set(board.id, `${team.name} - ${board.name}`);
      });
    });
    return map;
  }, [teams]);

  // Helper function to get object name
  const getObjectName = (objectId: string, objectType: string): string => {
    switch (objectType) {
      case 'portfolio':
        return portfolios.find((p) => p.id === objectId)?.name || objectId;
      case 'project':
        return teams.find((t) => t.id === objectId)?.name || objectId;
      case 'board':
        return boardNameMap.get(objectId) || objectId;
      default:
        return objectId;
    }
  };

  // Get date range based on range_type
  const getDateRange = () => {
    const endDate = new Date().toISOString().split('T')[0];
    let days = 30;
    switch (componentData.range_type) {
      case 'last_7_days':
        days = 7;
        break;
      case 'last_14_days':
        days = 14;
        break;
      case 'last_30_days':
        days = 30;
        break;
      case 'last_60_days':
        days = 60;
        break;
      case 'last_90_days':
        days = 90;
        break;
    }
    const startDate = new Date(Date.now() - days * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    return { startDate, endDate };
  };

  const { startDate, endDate } = getDateRange();

  // Create query configurations
  const queryConfigs = componentData.object_ids.map((objectId) => {
    const isCustom = !Object.values(Measure).includes(componentData.measure as Measure);
    const payload: GetWorkPeriodsMeasurePayload = {
      start_date: startDate,
      end_date: endDate,
      measure_name: [componentData.measure as Measure],
      force_recalculation: false,
      is_custom: isCustom,
    };
    if (isCustom) {
      payload.transformer = Transformer.ReturnFirstMetricOnly;
    }

    // Set the appropriate ID based on object type
    switch (componentData.object_type) {
      case 'project':
        payload.project_id = objectId;
        break;
      case 'board':
        payload.subproject_id = objectId;
        break;
      case 'portfolio':
        payload.portfolio_id = objectId;
        break;
    }

    return {
      queryKey: [
        'widget-measure',
        objectId,
        componentData.object_type,
        componentData.measure,
        startDate,
        endDate,
      ] as const,
      queryFn: () => getWorkPeriodsMeasure<MeasureDataResponse>(payload),
      staleTime: 1000 * 60 * 5, // Cache for 5 minutes
      gcTime: 1000 * 60 * 30, // Keep in cache for 30 minutes
      retry: false,
    };
  });

  // Use useQueries hook
  const queries = useQueries({ queries: queryConfigs });

  // Check if any queries are loading
  const isLoading = queries.some((query) => query.isLoading);

  // Check for errors
  const error = queries.find((query) => query.error)?.error || null;

  // Process data if available
  const allData: WidgetDataPoint[] = [];
  queries.forEach((query, index) => {
    const objectId = componentData.object_ids[index];
    if (query.data && componentData.measure) {
      const measureData = query.data[componentData.measure as Measure];
      if (measureData && Object.keys(measureData).length > 0) {
        const objectName = getObjectName(objectId, componentData.object_type);
        const transformedData = Object.entries(measureData).map(([date, value]) => ({
          date,
          value: typeof value === 'number' ? value : 0,
          objectId,
          objectName,
        }));
        allData.push(...transformedData);
      }
    }
  });

  // Process data for different widget types
  const tableData = allData.map(({ date, value, objectId, objectName }) => ({
    id: objectId,
    name: objectName,
    date,
    value,
  }));

  // Calculate averages and totals for tile view
  const total = allData.filter((item) => item.value !== 0).reduce((sum, item) => sum + item.value, 0);
  const average = allData.length > 0 ? total / allData.length : 0;

  return {
    chartData: allData,
    tableData,
    tileData: { average, total },
    isLoading,
    error,
  };
};

export { useWidgetData };
