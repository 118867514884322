import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { TextInput, UnstyledButton } from '@mantine/core';
import { useState } from 'react';
import { ChatHistory } from '../../containers/flora/flora.types';
import { addOpacityToColor } from '../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../styles/colors';

type ChatHistoryItemProps = {
  history: ChatHistory;
  onClick: () => void;
  onDelete: (id: string) => void;
  onEdit: (id: string, newDescription: string) => void;
};

export const ChatHistoryItem = ({ history, onClick, onDelete, onEdit }: ChatHistoryItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState(history.description || 'New Chat');

  const handleSubmitEdit = () => {
    if (editedDescription.trim()) {
      onEdit(history.id, editedDescription.slice(0, 50));
    } else {
      setEditedDescription(history.description || 'New Chat');
    }
    setIsEditing(false);
  };

  return (
    <StyledHistoryItem>
      <ItemContent onClick={!isEditing ? onClick : undefined}>
        <Icon icon="material-symbols:chat-bubble-outline" style={{ flexShrink: 0 }} />
        {isEditing ? (
          <EditInput
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.currentTarget.value.slice(0, 50))}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmitEdit();
              } else if (e.key === 'Escape') {
                setIsEditing(false);
                setEditedDescription(history.description || 'New Chat');
              }
            }}
            onBlur={handleSubmitEdit}
            autoFocus
            maxLength={50}
            placeholder="Enter description (max 50 chars)"
          />
        ) : (
          <Title>{history.description || 'New Chat'}</Title>
        )}
      </ItemContent>
      <ButtonGroup>
        <ActionButton
          data-edit
          onClick={(e: any) => {
            e.stopPropagation();
            setIsEditing(true);
          }}
        >
          <Icon icon={isEditing ? 'material-symbols:check' : 'material-symbols:edit-outline'} width={18} />
        </ActionButton>
        <ActionButton
          data-delete
          onClick={(e: any) => {
            e.stopPropagation();
            onDelete(history.id);
          }}
        >
          <Icon icon="material-symbols:delete-outline" width={18} />
        </ActionButton>
      </ButtonGroup>
    </StyledHistoryItem>
  );
};

const StyledHistoryItem = styled.div`
  width: 100%;
  height: 40px;
  padding: 0;
  text-align: left;
  transition: background-color 0.2s;
  border-radius: 15px;
  border: 1px solid ${newCOLORS.gray2};
  margin-bottom: 4px;
  max-width: 85%;
  font-size: 12px;
  margin-left: auto;
  margin-right: 10px;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    background-color: ${() => addOpacityToColor(newCOLORS.gray2, 0.2)};

    button[data-delete],
    button[data-edit] {
      opacity: 1;
    }
  }
`;

const ItemContent = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  color: ${newCOLORS.gray3};
  gap: 12px;
  padding: 0 8px;
  flex: 1;
  width: 90%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  color: ${newCOLORS.gray3};
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  max-width: 180px;
  line-height: 40px;
`;

const ButtonGroup = styled.div`
  position: absolute;
  right: 4px;
  display: flex;
`;

const ActionButton = styled(UnstyledButton)`
  opacity: 0;
  transition: opacity 0.2s;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${newCOLORS.red};
  }

  &[data-edit]:hover {
    color: ${newCOLORS.purple2};
  }
` as any;

const EditInput = styled(TextInput)`
  flex: 1;
  margin-right: 80px;
  height: 100%;

  input {
    height: 40px;
    min-height: 40px;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    border: none;
    background: transparent;
    line-height: 40px;

    &:focus {
      border: none;
      outline: none;
    }

    &::placeholder {
      color: ${newCOLORS.gray};
      font-weight: normal;
    }
  }
`;
