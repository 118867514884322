import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Collapse, Divider } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { Fragment, useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Board, Team } from '../../api/projects-client/projects-client.type';
import { BoardContext, TeamContext } from '../../contexts';
import { getRecentlyCompletedMonth } from '../../helpers/general-helpers';
import { useGlobalStore } from '../../store/global-store/global-store';
import { newCOLORS } from '../../styles/colors';
import { SmallerHeading, StandardText } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { SubprojectSelect, SubprojectSelectRef } from '../select/subproject-select';
import { TeamSelect, TeamSelectRef } from '../select/team-select';
import { FinancialsScopeProps, ScopeSelectorProps } from './financials-scope.type';

const ScopeSelector = ({ opened, startDate, setStartDate, endDate, setEndDate }: ScopeSelectorProps) => {
  const navigate = useNavigate();

  const teamRef = useRef<TeamSelectRef>(null);
  const availableTeams = teamRef?.current?.getTeams();
  const hasAvailableTeams = availableTeams && availableTeams.length > 0;

  const subprojectRef = useRef<SubprojectSelectRef>(null);
  const availableBoards = subprojectRef?.current?.getBoards();
  const hasAvailableBoards = availableBoards && availableBoards.length > 0;
  const { team, setTeam } = useContext(TeamContext);

  const { board, setBoard } = useContext(BoardContext);

  const portfolio = useGlobalStore((state) => state.portfolio);
  const { portfolioId, teamId, boardId } = useParams<{
    portfolioId: string;
    teamId: string;
    boardId: string;
  }>();

  const hasTeamIdParam = teamId && teamId.length > 0;
  const hasBoardIdParam = boardId && boardId.length > 0;

  const navigateToSelectedTeam = useCallback(
    (tm: Team) => {
      const selectedPortfolioId = portfolioId || portfolio?.id;

      if (!selectedPortfolioId || selectedPortfolioId.length === 0) {
        return;
      }

      const routeBase = `/application/financials/portfolio`;

      if (tm && tm.id !== 'aggregate') {
        navigate(`${routeBase}/${selectedPortfolioId}/team/${tm.id}`);
      } else {
        navigate(routeBase);
      }
    },
    [portfolioId, portfolio, navigate],
  );

  const navigateToSelectedSubproject = useCallback(
    (subproject: Board | undefined) => {
      const selectedPortfolioId = portfolioId || portfolio?.id;
      const selectedTeamId = teamId || team?.id;
      if (!selectedPortfolioId || !selectedTeamId) {
        return;
      }
      let route = `/application/financials/portfolio/${selectedPortfolioId}/team/${selectedTeamId}`;
      if (subproject?.id === 'aggregate') {
        navigate(`${route}`);
        return;
      }

      if (subproject) {
        route += `/subproject/${subproject.id}`;
      }
      navigate(`${route}`);
    },
    [portfolioId, portfolio, teamId, team, navigate],
  );

  useEffect(() => {
    if (hasAvailableTeams && hasTeamIdParam && (!team || teamId !== team.id)) {
      const properTeam = availableTeams.find((tm) => tm.id === teamId);
      setTeam(properTeam || null);
    } else if (!hasTeamIdParam && team?.id !== 'aggregate') {
      setTeam(availableTeams?.[0] || null);
    }
  }, [teamId, team, setTeam, availableTeams, hasAvailableTeams, hasTeamIdParam]);

  useEffect(() => {
    if (hasAvailableBoards && hasBoardIdParam && (!board || boardId !== board.id)) {
      const properBoard = availableBoards.find((tm) => tm.id === boardId);
      setBoard(properBoard || null);
    }
  }, [boardId, board, setBoard, availableBoards, hasAvailableBoards, hasBoardIdParam]);

  return (
    <div style={{ display: 'flex' }}>
      <OpenedStyle opened={opened}>
        <StandardText style={{ margin: opened ? '8px 0px' : '0px 8px' }}>Team</StandardText>
        <TeamSelect
          hideLabel={true}
          includeAggregate={true}
          ref={teamRef}
          handleTeamSelected={navigateToSelectedTeam}
        />
      </OpenedStyle>
      {team && team.id !== 'aggregate' && (
        <OpenedStyle opened={opened}>
          <StandardText style={{ margin: opened ? '8px 0px' : '0px 8px' }}>Board</StandardText>
          <SubprojectSelect
            selectFirstByDefault={false}
            team={team || null}
            ref={subprojectRef}
            handleSubprojectSelected={navigateToSelectedSubproject}
          />
        </OpenedStyle>
      )}

      <Divider orientation="vertical" size="xs" />

      <OpenedStyle opened={opened}>
        <StandardText style={{ margin: opened ? '8px 0px' : '0px 8px' }}>Start Date</StandardText>
        <MonthPickerInput
          {...{ placeholder: 'Pick start date' }}
          leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
          leftSectionPointerEvents="none"
          placeholder="Pick start date"
          value={startDate}
          onChange={(date: Date | null) => {
            if (date) {
              setStartDate(date);
            }
          }}
          mx="auto"
          maw={400}
          maxDate={endDate || new Date()}
        />
      </OpenedStyle>

      <OpenedStyle opened={opened}>
        <StandardText style={{ margin: opened ? '8px 0px' : '0px 8px' }}>End Date</StandardText>
        <MonthPickerInput
          {...{ placeholder: 'Pick end date' }}
          leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
          leftSectionPointerEvents="none"
          placeholder="Pick end date"
          value={endDate}
          minDate={startDate as Date}
          onChange={(date: Date | null) => {
            if (date) {
              setEndDate(dayjs(date).endOf('month').toDate());
            }
          }}
          mx="auto"
          maw={400}
          maxDate={getRecentlyCompletedMonth()}
        />
      </OpenedStyle>
    </div>
  );
};

const OpenedStyle = styled.div<{ opened: boolean }>`
  display: flex;
  flex-direction: ${(props: { opened: boolean }) => (props.opened ? 'column' : 'row')};
  justify-content: ${(props: { opened: boolean }) => (props.opened ? 'center' : 'start')};
  align-items: ${(props: { opened: boolean }) => (props.opened ? 'start' : 'center')};
  margin: 0px 16px;
`;

export const FinancialsScope = ({ startDate, setStartDate, endDate, setEndDate }: FinancialsScopeProps) => {
  const [opened, { toggle }] = useDisclosure(true);

  return (
    <FinancialsScopeContainer>
      <CollapseHeader>
        <CollapseIcon>
          {opened ? (
            <Icon icon="icon-park-solid:down-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
          ) : (
            <Icon icon="icon-park-solid:right-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
          )}
        </CollapseIcon>
        <SmallerHeading>Scope</SmallerHeading>
        {!opened && (
          <Fragment>
            <Divider orientation="vertical" size="xs" style={{ marginLeft: 16 }} />
            <ScopeSelector
              opened={opened}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </Fragment>
        )}
      </CollapseHeader>

      <Collapse in={opened}>
        <CollapseContent>
          {opened && (
            <ScopeSelector
              opened={opened}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          )}
        </CollapseContent>
      </Collapse>
    </FinancialsScopeContainer>
  );
};

const FinancialsScopeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-bottom: 16px;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const CollapseHeader = styled.div`
  display: flex;
  align-items: center;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 6px;
  font-weight: 800;
  cursor: pointer;
`;

const CollapseContent = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
`;
