import { styled } from '@linaria/react';
import { UnstyledButton } from '@mantine/core';
import { icons } from '../../assets/icons/icons';
import { useGlobalStore } from '../../store/global-store/global-store';
import { useFlora } from './flora.context';

type FloraButtonProps = {
  externalData?: any[];
  size?: number;
};

export const FloraButton = ({ externalData, size = 25 }: FloraButtonProps) => {
  const { isFloraVisible, toggleFlora, askQuestion } = useFlora();

  const { useAI } = useGlobalStore();
  // We may move this to backend at some point but this is fine for now.
  const prompt = `
    Analyze the following data and provide a summary of the key findings. 
    Use your expertise and various tools to help provide context for your analysis. 
  `;
  const handleClick = () => {
    if (externalData) {
      let dataPrompt = prompt;
      for (const data of externalData) {
        dataPrompt += JSON.stringify(data);
      }
      askQuestion(dataPrompt);
    } else {
      toggleFlora();
    }
  };

  if (isFloraVisible) {
    return null;
  }

  if (!useAI) {
    return null;
  }

  return (
    <StyledButton onClick={handleClick}>
      <img src={icons.iconFlora} alt="Flora AI" style={{ width: size, height: size }} />
    </StyledButton>
  );
};

const StyledButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  transition: background 0.2s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;
