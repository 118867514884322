import { Combobox, ComboboxItem, InputBase, useCombobox } from '@mantine/core';
import { useState } from 'react';

type Props = {
  options: ComboboxItem[];
  onChange: (value: string) => void;
  placeholder: string;
  zIndex?: number;
};

export function ComboboxSelect({ options, onChange, placeholder, zIndex }: Props) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [search, setSearch] = useState('');

  const shouldFilterOptions = options.every(({ label }) => label !== search);
  const filteredOptions = shouldFilterOptions
    ? options.filter(({ label }) => label.toLowerCase().includes(search.toLowerCase().trim()))
    : options;

  const comboboxOptions = filteredOptions.map(({ value, label }) => (
    <Combobox.Option value={value} key={value}>
      {label}
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(value) => {
        onChange(value);
        setSearch('');
        combobox.closeDropdown();
      }}
      zIndex={zIndex}
    >
      <Combobox.Target>
        <InputBase
          placeholder={placeholder}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch('');
          }}
          rightSection={<Combobox.Chevron />}
          rightSectionPointerEvents="none"
          pointer
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        {comboboxOptions.length ? (
          <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
            {comboboxOptions}
          </Combobox.Options>
        ) : (
          <Combobox.Empty>Nothing found...</Combobox.Empty>
        )}
      </Combobox.Dropdown>
    </Combobox>
  );
}
