import { useEffect, useState } from 'react';

/**
 * Hook to get the viewport height and adjust it based on the window size
 *
 * @returns {number} The viewport height
 */
const useViewportHeight = (): number => {
  const [viewportHeight, setViewportHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight > 100 ? window.innerHeight - 100 : window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return viewportHeight;
};

export { useViewportHeight };
