const inkDarkest = "#090a0a";
const inkDarker = "#202325";
const inkDark = "#303437";
const inkBase = "#404446";
const inkLight = "#6c7072";
const inkLighter = "#72777a";
const skyBase = "#cdcfd0";
const skyDark = "#979c9e";
const skyLight = "#e3e5e5";
const skyLightest = "#f7f9fa";
const skyLighter = "#f2f4f5";
const skyTight = "#FCFCFC";
const baseWhite = "#ffffff";
const baseBlack = "#000000";
const pinkDarkest = "#ef4096";
const pinkBase = "#ff64b0";
const pinkLight = "#ff84c0";
const pinkLighter = "#ffadd5";
const pinkLightest = "#ffd8eb";
const redDarkest = "#d3180c";
const redBase = "#ff5247";
const redLight = "#ff6d6d";
const redLighter = "#ff9898";
const redLightest = "#ffe5e5";
const primaryDarkest = "#198155";
const primaryBase = "#23c16b";
const primaryLight = "#4cd471";
const primaryLighter = "#7dde86";
const primaryLightest = "#ecfce5";
const yellowDarkest = "#e2cc31";
const yellowBase = "#ffe636";
const yellowLight = "#ffed6b";
const yellowLighter = "#fff39c";
const yellowLightest = "#fff9d1";
const blueDarkest = "#0065d0";
const blueBase = "#48a7f8";
const blueLight = "#6ec2fb";
const blueLighter = "#9bdcfd";
const blueLightest = "#E8F9FF";
const backgroundDark = "#282628";
const backgroundLight = "#363436";
const backgroundLightest = "#484649";
const backgroundTransparent = "#ffffff00";
const purpleDarkest = "#9F38B3";
const purpleBase = "#C24AD9";
const purpleLight = "#cc6fdd";
const purpleLighter = "#daa2e5";
const purpleLightest = "#f6dffa";
const secondaryDarkest = "#4448b5";
const secondaryBase = "#575CE5";
const secondaryLight = "#6f74f9";
const secondaryLighter = "#bcbdea";
const secondaryLightest = "#e7e7f8";
const gradientsLinear = "linear-gradient(180deg, #ffe636 0%, #ff3ecd 33.19%, #c24ad9 64.96%, #575ce5 97.78%)";
const gradientsLogo = "radial-gradient(50.03% 50.03% at 50.51% 49.98%, #5B5FED 42%, #625EEB 46%, #755DE7 52%, #955BE0 60%, #C158D6 68%, #FF54C9 77%, #FF55C6 83%, #FF5BBE 85%, #FF64B0 88%, #FF729D 89%, #FF8384 91%, #FF9965 93%, #FFB340 94%, #FFE000 96%, #FFF399 100%)";
const boxShadowSmallSoft = "0px 1px 4px #00000029";
const boxShadowSmallHard = "0px 2px 5px -1px #32325d40, 0px 1px 3px -1px #0000004d";
const boxShadowMediumSoft = "0px 50px 100px -20px #24242440, 0px 30px 60px -30px #0000004d";
const boxShadowMediumHard = "0px 13px 27px -5px #32325d40, 0px 8px 16px -8px #0000004d";
const boxShadowBigSoft = "0px 7px 29px 0px #64646f33";
const boxShadowBigHard = "0px 5px 15px 0px #00000059";
const fontFamily = "Figtree";
const lineHeightXsmall = "0.75rem";
const lineHeightSmall = "0.875rem";
const lineHeightRegular = "1rem";
const lineHeightMedium = "1.125rem";
const lineHeightXmedium = "1.25rem";
const lineHeightLarge = "1.5rem";
const lineHeightMidxlarge = "2.25rem";
const lineHeightXlarge = "2rem";
const lineHeightXxxlarge = "3.5rem";
const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightSemibold = 600;
const fontWeightBold = 700;
const fontWeightExtrabold = 800;
const fontWeightBlack = 900;
const fontSizeXsmall = "0.75rem";
const fontSizeSmall = "0.875rem";
const fontSizeRegular = "1rem";
const fontSizeMedium = "1.125rem";
const fontSizeLarge = "1.5rem";
const fontSizeXlarge = "2rem";
const fontSizeXxlarge = "3rem";
const letterSpacingNone = 0;
const paragraphSpacingNone = 0;
const headlineH1FontFamily = "Figtree";
const headlineH1FontWeight = 600;
const headlineH1LineHeight = "3.5rem";
const headlineH1FontSize = "3rem";
const headlineH1TextCase = "none";
const headlineH2FontFamily = "Figtree";
const headlineH2FontWeight = 600;
const headlineH2LineHeight = "2.25rem";
const headlineH2FontSize = "2rem";
const headlineH2TextCase = "none";
const headlineH3FontFamily = "Figtree";
const headlineH3FontWeight = 600;
const headlineH3LineHeight = "2rem";
const headlineH3FontSize = "1.5rem";
const headlineH3TextCase = "none";
const largeNoneBoldFontFamily = "Figtree";
const largeNoneBoldFontWeight = 700;
const largeNoneBoldLineHeight = "1.125rem";
const largeNoneBoldFontSize = "1.125rem";
const largeNoneBoldTextCase = "none";
const largeNoneMediumFontFamily = "Figtree";
const largeNoneMediumFontWeight = 500;
const largeNoneMediumLineHeight = "1.125rem";
const largeNoneMediumFontSize = "1.125rem";
const largeNoneMediumTextCase = "none";
const largeNoneRegularFontFamily = "Figtree";
const largeNoneRegularFontWeight = 400;
const largeNoneRegularLineHeight = "1.125rem";
const largeNoneRegularFontSize = "1.125rem";
const largeNoneRegularTextCase = "none";
const largeTightBoldFontFamily = "Figtree";
const largeTightBoldFontWeight = 700;
const largeTightBoldLineHeight = "1.25rem";
const largeTightBoldFontSize = "1.125rem";
const largeTightBoldTextCase = "none";
const largeTightMediumFontFamily = "Figtree";
const largeTightMediumFontWeight = 500;
const largeTightMediumLineHeight = "1.25rem";
const largeTightMediumFontSize = "1.125rem";
const largeTightMediumTextCase = "none";
const largeTightRegularFontFamily = "Figtree";
const largeTightRegularFontWeight = 400;
const largeTightRegularLineHeight = "1.25rem";
const largeTightRegularFontSize = "1.125rem";
const largeTightRegularTextCase = "none";
const largeNormalBoldFontFamily = "Figtree";
const largeNormalBoldFontWeight = 700;
const largeNormalBoldLineHeight = "1.5rem";
const largeNormalBoldFontSize = "1.125rem";
const largeNormalBoldTextCase = "none";
const largeNormalMediumFontFamily = "Figtree";
const largeNormalMediumFontWeight = 500;
const largeNormalMediumLineHeight = "1.5rem";
const largeNormalMediumFontSize = "1.125rem";
const largeNormalMediumTextCase = "none";
const largeNormalRegularFontFamily = "Figtree";
const largeNormalRegularFontWeight = 400;
const largeNormalRegularLineHeight = "1.5rem";
const largeNormalRegularFontSize = "1.125rem";
const largeNormalRegularTextCase = "none";
const regularNoneBoldFontFamily = "Figtree";
const regularNoneBoldFontWeight = 700;
const regularNoneBoldLineHeight = "1rem";
const regularNoneBoldFontSize = "1rem";
const regularNoneBoldTextCase = "none";
const regularNoneMediumFontFamily = "Figtree";
const regularNoneMediumFontWeight = 500;
const regularNoneMediumLineHeight = "1rem";
const regularNoneMediumFontSize = "1rem";
const regularNoneMediumTextCase = "none";
const regularNoneRegularFontFamily = "Figtree";
const regularNoneRegularFontWeight = 400;
const regularNoneRegularLineHeight = "1rem";
const regularNoneRegularFontSize = "1rem";
const regularNoneRegularTextCase = "none";
const regularTightBoldFontFamily = "Figtree";
const regularTightBoldFontWeight = 700;
const regularTightBoldLineHeight = "1.25rem";
const regularTightBoldFontSize = "1rem";
const regularTightBoldTextCase = "none";
const regularTightMediumFontFamily = "Figtree";
const regularTightMediumFontWeight = 500;
const regularTightMediumLineHeight = "1.25rem";
const regularTightMediumFontSize = "1rem";
const regularTightMediumTextCase = "none";
const regularTightRegularFontFamily = "Figtree";
const regularTightRegularFontWeight = 400;
const regularTightRegularLineHeight = "1.25rem";
const regularTightRegularFontSize = "1rem";
const regularTightRegularTextCase = "none";
const regularNormalBoldFontFamily = "Figtree";
const regularNormalBoldFontWeight = 700;
const regularNormalBoldLineHeight = "1.5rem";
const regularNormalBoldFontSize = "1rem";
const regularNormalBoldTextCase = "none";
const regularNormalMediumFontFamily = "Figtree";
const regularNormalMediumFontWeight = 500;
const regularNormalMediumLineHeight = "1.5rem";
const regularNormalMediumFontSize = "1rem";
const regularNormalMediumTextCase = "none";
const regularNormalRegularFontFamily = "Figtree";
const regularNormalRegularFontWeight = 400;
const regularNormalRegularLineHeight = "1.5rem";
const regularNormalRegularFontSize = "1rem";
const regularNormalRegularTextCase = "none";
const smallNoneBoldFontFamily = "Figtree";
const smallNoneBoldFontWeight = 700;
const smallNoneBoldLineHeight = "0.875rem";
const smallNoneBoldFontSize = "0.875rem";
const smallNoneBoldTextCase = "none";
const smallNoneMediumFontFamily = "Figtree";
const smallNoneMediumFontWeight = 500;
const smallNoneMediumLineHeight = "0.875rem";
const smallNoneMediumFontSize = "0.875rem";
const smallNoneMediumTextCase = "none";
const smallNoneRegularFontFamily = "Figtree";
const smallNoneRegularFontWeight = 400;
const smallNoneRegularLineHeight = "0.875rem";
const smallNoneRegularFontSize = "0.875rem";
const smallNoneRegularTextCase = "none";
const smallTightBoldFontFamily = "Figtree";
const smallTightBoldFontWeight = 700;
const smallTightBoldLineHeight = "1rem";
const smallTightBoldFontSize = "0.875rem";
const smallTightBoldTextCase = "none";
const smallTightMeduimFontFamily = "Figtree";
const smallTightMeduimFontWeight = 500;
const smallTightMeduimLineHeight = "1rem";
const smallTightMeduimFontSize = "0.875rem";
const smallTightMeduimTextCase = "none";
const smallTightRegularFontFamily = "Figtree";
const smallTightRegularFontWeight = 400;
const smallTightRegularLineHeight = "1rem";
const smallTightRegularFontSize = "0.875rem";
const smallTightRegularTextCase = "none";
const smallNormalBoldFontFamily = "Figtree";
const smallNormalBoldFontWeight = 700;
const smallNormalBoldLineHeight = "1.25rem";
const smallNormalBoldFontSize = "0.875rem";
const smallNormalBoldTextCase = "none";
const smallNormalMediumFontFamily = "Figtree";
const smallNormalMediumFontWeight = 500;
const smallNormalMediumLineHeight = "1.25rem";
const smallNormalMediumFontSize = "0.875rem";
const smallNormalMediumTextCase = "none";
const smallNormalRegularFontFamily = "Figtree";
const smallNormalRegularFontWeight = 400;
const smallNormalRegularLineHeight = "1.25rem";
const smallNormalRegularFontSize = "0.875rem";
const smallNormalRegularTextCase = "none";
const tinyNoneBoldFontFamily = "Figtree";
const tinyNoneBoldFontWeight = 700;
const tinyNoneBoldLineHeight = "0.75rem";
const tinyNoneBoldFontSize = "0.75rem";
const tinyNoneBoldTextCase = "none";
const tinyNoneMediumFontFamily = "Figtree";
const tinyNoneMediumFontWeight = 500;
const tinyNoneMediumLineHeight = "0.75rem";
const tinyNoneMediumFontSize = "0.75rem";
const tinyNoneMediumTextCase = "none";
const tinyNoneRegularFontFamily = "Figtree";
const tinyNoneRegularFontWeight = 400;
const tinyNoneRegularLineHeight = "0.75rem";
const tinyNoneRegularFontSize = "0.75rem";
const tinyNoneRegularTextCase = "none";
const tinyTightBoldFontFamily = "Figtree";
const tinyTightBoldFontWeight = 700;
const tinyTightBoldLineHeight = "0.875rem";
const tinyTightBoldFontSize = "0.75rem";
const tinyTightBoldTextCase = "none";
const tinyTightMediumFontFamily = "Figtree";
const tinyTightMediumFontWeight = 500;
const tinyTightMediumLineHeight = "0.875rem";
const tinyTightMediumFontSize = "0.75rem";
const tinyTightMediumTextCase = "none";
const tinyTightRegularFontFamily = "Figtree";
const tinyTightRegularFontWeight = 400;
const tinyTightRegularLineHeight = "0.875rem";
const tinyTightRegularFontSize = "0.75rem";
const tinyTightRegularTextCase = "none";
const tinyNormalBoldFontFamily = "Figtree";
const tinyNormalBoldFontWeight = 700;
const tinyNormalBoldFontSize = "0.75rem";
const tinyNormalBoldTextCase = "none";
const tinyNormalBoldLineHeight = "1rem";
const tinyNormalMediumFontFamily = "Figtree";
const tinyNormalMediumFontWeight = 500;
const tinyNormalMediumLineHeight = "1rem";
const tinyNormalMediumFontSize = "0.75rem";
const tinyNormalMediumTextCase = "none";
const tinyNormalRegularFontFamily = "Figtree";
const tinyNormalRegularFontWeight = 400;
const tinyNormalRegularLineHeight = "1rem";
const tinyNormalRegularFontSize = "0.75rem";
const tinyNormalRegularTextCase = "none";
const borderRadiusNone = 0;
const borderRadiusXxxtiny = "0.125rem";
const borderRadiusXxtiny = "0.25rem";
const borderRadiusXtiny = "0.5rem";
const borderRadiusTiny = "0.625rem";
const borderRadiusXxsmall = "0.75rem";
const borderRadiusXsmall = "0.875rem";
const borderRadiusSmall = "1rem";
const borderRadiusXlarge = "2rem";
const textCaseNone = "none";
const textCaseLowercase = "lowercase";
const textCaseUppercase = "uppercase";
const textCaseCapitalize = "capitalize";
const borderWidthSmall = "1px";
const borderWidthMedium = "2px";
const borderWidthLarge = "3px";
const borderWidthExtraLarge = "4px";
const graphTropicalIndigo = "#8979FF";
const graphLightRed = "#FF928A";
const graphPictonBlue = "#3CC3DF";
const graphSandyBrown = "#FFAE4C";
const graphChefchaouenBlue = "#537FF1";
const graphCambridgeBlue = "#6FD195";
const graphAmethyst = "#8C63DA";
const graphPacificCyan = "#2BB7DC";
const graphDodgerBlue = "#1F94FF";
const graphSunglow = "#F4CF3B";
const graphVerdigris = "#55C4AE";
const graphSilverLakeBlue = "#6186CC";
const graphButterscotch = "#EA9245";
const graphPistachio = "#8EC655";
const graphOldRose = "#CD7575";
const graphArgentinianBlue = "#63C1D6";
const graphKhaki = "#CAAA8F";
const graphMoonstone = "#33A0C3";
const graphMint = "#50A682";
const graphPurple = "#8873C5";

export {
  inkDarkest,
  inkDarker,
  inkDark,
  inkBase,
  inkLight,
  inkLighter,
  skyBase,
  skyDark,
  skyLight,
  skyLightest,
  skyLighter,
  skyTight,
  baseWhite,
  baseBlack,
  pinkDarkest,
  pinkBase,
  pinkLight,
  pinkLighter,
  pinkLightest,
  redDarkest,
  redBase,
  redLight,
  redLighter,
  redLightest,
  primaryDarkest,
  primaryBase,
  primaryLight,
  primaryLighter,
  primaryLightest,
  yellowDarkest,
  yellowBase,
  yellowLight,
  yellowLighter,
  yellowLightest,
  blueDarkest,
  blueBase,
  blueLight,
  blueLighter,
  blueLightest,
  backgroundDark,
  backgroundLight,
  backgroundLightest,
  backgroundTransparent,
  purpleDarkest,
  purpleBase,
  purpleLight,
  purpleLighter,
  purpleLightest,
  secondaryDarkest,
  secondaryBase,
  secondaryLight,
  secondaryLighter,
  secondaryLightest,
  gradientsLinear,
  gradientsLogo,
  boxShadowSmallSoft,
  boxShadowSmallHard,
  boxShadowMediumSoft,
  boxShadowMediumHard,
  boxShadowBigSoft,
  boxShadowBigHard,
  fontFamily,
  lineHeightXsmall,
  lineHeightSmall,
  lineHeightRegular,
  lineHeightMedium,
  lineHeightXmedium,
  lineHeightLarge,
  lineHeightMidxlarge,
  lineHeightXlarge,
  lineHeightXxxlarge,
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightSemibold,
  fontWeightBold,
  fontWeightExtrabold,
  fontWeightBlack,
  fontSizeXsmall,
  fontSizeSmall,
  fontSizeRegular,
  fontSizeMedium,
  fontSizeLarge,
  fontSizeXlarge,
  fontSizeXxlarge,
  letterSpacingNone,
  paragraphSpacingNone,
  headlineH1FontFamily,
  headlineH1FontWeight,
  headlineH1LineHeight,
  headlineH1FontSize,
  headlineH1TextCase,
  headlineH2FontFamily,
  headlineH2FontWeight,
  headlineH2LineHeight,
  headlineH2FontSize,
  headlineH2TextCase,
  headlineH3FontFamily,
  headlineH3FontWeight,
  headlineH3LineHeight,
  headlineH3FontSize,
  headlineH3TextCase,
  largeNoneBoldFontFamily,
  largeNoneBoldFontWeight,
  largeNoneBoldLineHeight,
  largeNoneBoldFontSize,
  largeNoneBoldTextCase,
  largeNoneMediumFontFamily,
  largeNoneMediumFontWeight,
  largeNoneMediumLineHeight,
  largeNoneMediumFontSize,
  largeNoneMediumTextCase,
  largeNoneRegularFontFamily,
  largeNoneRegularFontWeight,
  largeNoneRegularLineHeight,
  largeNoneRegularFontSize,
  largeNoneRegularTextCase,
  largeTightBoldFontFamily,
  largeTightBoldFontWeight,
  largeTightBoldLineHeight,
  largeTightBoldFontSize,
  largeTightBoldTextCase,
  largeTightMediumFontFamily,
  largeTightMediumFontWeight,
  largeTightMediumLineHeight,
  largeTightMediumFontSize,
  largeTightMediumTextCase,
  largeTightRegularFontFamily,
  largeTightRegularFontWeight,
  largeTightRegularLineHeight,
  largeTightRegularFontSize,
  largeTightRegularTextCase,
  largeNormalBoldFontFamily,
  largeNormalBoldFontWeight,
  largeNormalBoldLineHeight,
  largeNormalBoldFontSize,
  largeNormalBoldTextCase,
  largeNormalMediumFontFamily,
  largeNormalMediumFontWeight,
  largeNormalMediumLineHeight,
  largeNormalMediumFontSize,
  largeNormalMediumTextCase,
  largeNormalRegularFontFamily,
  largeNormalRegularFontWeight,
  largeNormalRegularLineHeight,
  largeNormalRegularFontSize,
  largeNormalRegularTextCase,
  regularNoneBoldFontFamily,
  regularNoneBoldFontWeight,
  regularNoneBoldLineHeight,
  regularNoneBoldFontSize,
  regularNoneBoldTextCase,
  regularNoneMediumFontFamily,
  regularNoneMediumFontWeight,
  regularNoneMediumLineHeight,
  regularNoneMediumFontSize,
  regularNoneMediumTextCase,
  regularNoneRegularFontFamily,
  regularNoneRegularFontWeight,
  regularNoneRegularLineHeight,
  regularNoneRegularFontSize,
  regularNoneRegularTextCase,
  regularTightBoldFontFamily,
  regularTightBoldFontWeight,
  regularTightBoldLineHeight,
  regularTightBoldFontSize,
  regularTightBoldTextCase,
  regularTightMediumFontFamily,
  regularTightMediumFontWeight,
  regularTightMediumLineHeight,
  regularTightMediumFontSize,
  regularTightMediumTextCase,
  regularTightRegularFontFamily,
  regularTightRegularFontWeight,
  regularTightRegularLineHeight,
  regularTightRegularFontSize,
  regularTightRegularTextCase,
  regularNormalBoldFontFamily,
  regularNormalBoldFontWeight,
  regularNormalBoldLineHeight,
  regularNormalBoldFontSize,
  regularNormalBoldTextCase,
  regularNormalMediumFontFamily,
  regularNormalMediumFontWeight,
  regularNormalMediumLineHeight,
  regularNormalMediumFontSize,
  regularNormalMediumTextCase,
  regularNormalRegularFontFamily,
  regularNormalRegularFontWeight,
  regularNormalRegularLineHeight,
  regularNormalRegularFontSize,
  regularNormalRegularTextCase,
  smallNoneBoldFontFamily,
  smallNoneBoldFontWeight,
  smallNoneBoldLineHeight,
  smallNoneBoldFontSize,
  smallNoneBoldTextCase,
  smallNoneMediumFontFamily,
  smallNoneMediumFontWeight,
  smallNoneMediumLineHeight,
  smallNoneMediumFontSize,
  smallNoneMediumTextCase,
  smallNoneRegularFontFamily,
  smallNoneRegularFontWeight,
  smallNoneRegularLineHeight,
  smallNoneRegularFontSize,
  smallNoneRegularTextCase,
  smallTightBoldFontFamily,
  smallTightBoldFontWeight,
  smallTightBoldLineHeight,
  smallTightBoldFontSize,
  smallTightBoldTextCase,
  smallTightMeduimFontFamily,
  smallTightMeduimFontWeight,
  smallTightMeduimLineHeight,
  smallTightMeduimFontSize,
  smallTightMeduimTextCase,
  smallTightRegularFontFamily,
  smallTightRegularFontWeight,
  smallTightRegularLineHeight,
  smallTightRegularFontSize,
  smallTightRegularTextCase,
  smallNormalBoldFontFamily,
  smallNormalBoldFontWeight,
  smallNormalBoldLineHeight,
  smallNormalBoldFontSize,
  smallNormalBoldTextCase,
  smallNormalMediumFontFamily,
  smallNormalMediumFontWeight,
  smallNormalMediumLineHeight,
  smallNormalMediumFontSize,
  smallNormalMediumTextCase,
  smallNormalRegularFontFamily,
  smallNormalRegularFontWeight,
  smallNormalRegularLineHeight,
  smallNormalRegularFontSize,
  smallNormalRegularTextCase,
  tinyNoneBoldFontFamily,
  tinyNoneBoldFontWeight,
  tinyNoneBoldLineHeight,
  tinyNoneBoldFontSize,
  tinyNoneBoldTextCase,
  tinyNoneMediumFontFamily,
  tinyNoneMediumFontWeight,
  tinyNoneMediumLineHeight,
  tinyNoneMediumFontSize,
  tinyNoneMediumTextCase,
  tinyNoneRegularFontFamily,
  tinyNoneRegularFontWeight,
  tinyNoneRegularLineHeight,
  tinyNoneRegularFontSize,
  tinyNoneRegularTextCase,
  tinyTightBoldFontFamily,
  tinyTightBoldFontWeight,
  tinyTightBoldLineHeight,
  tinyTightBoldFontSize,
  tinyTightBoldTextCase,
  tinyTightMediumFontFamily,
  tinyTightMediumFontWeight,
  tinyTightMediumLineHeight,
  tinyTightMediumFontSize,
  tinyTightMediumTextCase,
  tinyTightRegularFontFamily,
  tinyTightRegularFontWeight,
  tinyTightRegularLineHeight,
  tinyTightRegularFontSize,
  tinyTightRegularTextCase,
  tinyNormalBoldFontFamily,
  tinyNormalBoldFontWeight,
  tinyNormalBoldFontSize,
  tinyNormalBoldTextCase,
  tinyNormalBoldLineHeight,
  tinyNormalMediumFontFamily,
  tinyNormalMediumFontWeight,
  tinyNormalMediumLineHeight,
  tinyNormalMediumFontSize,
  tinyNormalMediumTextCase,
  tinyNormalRegularFontFamily,
  tinyNormalRegularFontWeight,
  tinyNormalRegularLineHeight,
  tinyNormalRegularFontSize,
  tinyNormalRegularTextCase,
  borderRadiusNone,
  borderRadiusXxxtiny,
  borderRadiusXxtiny,
  borderRadiusXtiny,
  borderRadiusTiny,
  borderRadiusXxsmall,
  borderRadiusXsmall,
  borderRadiusSmall,
  borderRadiusXlarge,
  textCaseNone,
  textCaseLowercase,
  textCaseUppercase,
  textCaseCapitalize,
  borderWidthSmall,
  borderWidthMedium,
  borderWidthLarge,
  borderWidthExtraLarge,
  graphTropicalIndigo,
  graphLightRed,
  graphPictonBlue,
  graphSandyBrown,
  graphChefchaouenBlue,
  graphCambridgeBlue,
  graphAmethyst,
  graphPacificCyan,
  graphDodgerBlue,
  graphSunglow,
  graphVerdigris,
  graphSilverLakeBlue,
  graphButterscotch,
  graphPistachio,
  graphOldRose,
  graphArgentinianBlue,
  graphKhaki,
  graphMoonstone,
  graphMint,
  graphPurple,
};