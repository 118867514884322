import { Select } from '@mantine/core';
import { Fragment, forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useBoardsByTeam } from '../../api/projects-client/projects-client.hooks';
import { Board, Team } from '../../api/projects-client/projects-client.type';
import { BoardContext } from '../../contexts';

export type SubprojectSelectRef = {
  getBoards: () => Board[];
};

export type SubprojectSelectProps = {
  team: Team | null;
  hideLabel?: boolean;
  includeAggregate?: boolean;
  selectFirstByDefault?: boolean;
  handleSubprojectSelected: (subproject: Board | undefined) => void;
};

export const SubprojectSelect = forwardRef<SubprojectSelectRef, SubprojectSelectProps>(
  ({ team, includeAggregate, selectFirstByDefault = true, handleSubprojectSelected }, ref) => {
    const { board, setBoard } = useContext(BoardContext);
    const [cacheTeam, setCacheTeam] = useState<Team | null>(null);
    const [subprojects, setSubprojects] = useState<Board[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { boards: fetchedSubprojects = [] } = useBoardsByTeam(team?.id || '', {
      enabled: team?.id !== 'aggregate',
    });

    useDeepCompareEffect(() => {
      // Reset state when team changes
      const handleTeamChange = () => {
        setCacheTeam(team);
        setBoard(null);
        setSubprojects([]);
      };

      // Handle board selection
      const updateBoardSelection = (newBoard: Board | null) => {
        setBoard(newBoard);
        // Only notify parent if the board actually changed
        if (newBoard?.id !== board?.id) {
          handleSubprojectSelected(newBoard || undefined);
        }
      };

      // Initial team setup
      if (cacheTeam == null && team != null) {
        setCacheTeam(team);
      }

      // Handle aggregate team
      if (team?.id === 'aggregate') {
        updateBoardSelection(null);
        setSubprojects([]);
        return;
      }

      // Handle team change
      if (cacheTeam?.id !== team?.id) {
        handleTeamChange();
      }

      const fetchSubprojects = async () => {
        if (!team?.id) return;

        setIsLoading(true);
        try {
          const aggregateBoard = { id: 'aggregate', name: 'All Boards' } as Board;
          const allSubprojects = [aggregateBoard, ...fetchedSubprojects];
          setSubprojects(allSubprojects);

          const currentSelectionExists = board && allSubprojects.find((sp) => sp.id === board.id);

          if (!currentSelectionExists) {
            const newBoard = selectFirstByDefault && allSubprojects.length > 0 ? allSubprojects[0] : aggregateBoard;
            updateBoardSelection(newBoard);
          }
        } catch (error) {
          console.error('Error fetching subprojects:', error);
          setSubprojects([]);
        } finally {
          setIsLoading(false);
        }
      };

      fetchSubprojects();
    }, [
      team?.id,
      includeAggregate,
      setBoard,
      selectFirstByDefault,
      handleSubprojectSelected,
      cacheTeam,
      team,
      board,
      fetchedSubprojects,
    ]);

    useImperativeHandle(ref, () => ({
      getBoards: () => subprojects,
    }));

    return (
      <Fragment>
        <Select
          data={subprojects.map((sp) => ({ value: sp.id, label: sp.name }))}
          value={board?.id}
          onChange={(value) => {
            const selected = subprojects.find((sp) => sp.id === value);
            setBoard(selected || null);
            handleSubprojectSelected(selected || undefined);
          }}
          allowDeselect={false}
          disabled={isLoading}
          placeholder={isLoading ? 'Loading...' : 'Select subproject'}
        />
      </Fragment>
    );
  },
);
