import { styled } from '@linaria/react';
import { Group, TextInput } from '@mantine/core';
import { useState } from 'react';
import { Portfolio } from '../../../../api/portfolio-client/portfolio-client.type';
import { ProjectsResponse } from '../../../../api/projects-client/projects-client.type';
import { redBase, skyBase, skyDark } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Divider } from '../../../../ui-library/divider/divider';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { Table, TableColumn } from '../../../data-management/components/table';
import { DataManagementDropShadowContainer } from '../data-management-header';
import { DeleteConfirmationModal } from '../delete-confirmation-modal';
import { mapProjectToTableRow } from './portfolios.helpers';
import { usePortfolioDelete, usePortfolioNameEdit, usePortfolioProjects } from './portfolios.hooks';

export type ProjectTableRow = {
  id: string;
  projectKey: string;
  taskCount: string;
  contributorCount: string;
  dataSource: string | React.ReactNode;
  owner: string;
  isActive: boolean;
  actions?: React.ReactNode;
};

type PortfolioPanelProps = {
  portfolio: Portfolio | null;
  portfolioProjects: ProjectsResponse[];
  selectedProject: ProjectsResponse | null;
  onProjectSelect(project_id: string): void;
  isEditingPortfolio: boolean;
  setIsEditingPortfolio: (isEditing: boolean) => void;
};

const columns: TableColumn<ProjectTableRow>[] = [
  {
    key: 'projectKey',
    label: 'Project Key-Name',
    render: (_value: React.ReactNode, row: ProjectTableRow) => (
      <div style={{ marginLeft: 8 }}>
        <Text weight="medium">{row.projectKey}</Text>
      </div>
    ),
  },
  { key: 'taskCount', label: 'Number of Tasks' },
  { key: 'contributorCount', label: 'Contributors' },
  {
    key: 'dataSource',
    label: 'Data Source',
    render: (_value: React.ReactNode, row: ProjectTableRow) => row.dataSource,
  },
  { key: 'owner', label: 'Owner' },
];

export function PortfolioPanel({
  portfolio,
  portfolioProjects,
  selectedProject,
  onProjectSelect,
  isEditingPortfolio,
  setIsEditingPortfolio,
}: PortfolioPanelProps) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState(portfolio?.name || '');
  const [search, setSearch] = useState('');

  const { editPortfolioName, isEditing: isEditingPortfolioName } = usePortfolioNameEdit();
  const { removeProjectFromPortfolio, isUpdating: isUpdatingProjects } = usePortfolioProjects();
  const { deletePortfolio, isDeleting, error } = usePortfolioDelete();

  const handleNameEdit = () => {
    setIsEditingName(true);
    setEditedName(portfolio?.name || '');
  };

  const handleNameSave = async () => {
    if (portfolio && editedName.trim()) {
      await editPortfolioName(portfolio, editedName);
      setIsEditingName(false);
    }
  };

  const handleNameKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleNameSave();
    } else if (e.key === 'Escape') {
      setIsEditingName(false);
      setEditedName(portfolio?.name || '');
    }
  };

  const handleRemoveProject = async (projectId: string) => {
    if (portfolio) {
      await removeProjectFromPortfolio(portfolio, projectId);
    }
  };

  const tableData = portfolioProjects.map((project) => {
    return mapProjectToTableRow(project);
  });

  const filteredData = tableData.filter((item) => {
    const query = search.toLowerCase().trim();
    if (!query) {
      return true;
    }
    return Object.values(item).some((value) => value?.toString().toLowerCase().includes(query));
  });

  return (
    <DataManagementDropShadowContainer>
      {portfolio && (
        <>
          <HeaderContainer>
            <Group gap="8px">
              {isEditingName ? (
                <TextInput
                  value={editedName}
                  onChange={(e) => setEditedName(e.currentTarget.value)}
                  onBlur={handleNameSave}
                  onKeyDown={handleNameKeyPress}
                  autoFocus
                  size="md"
                  radius="xl"
                  style={{ width: '300px', height: '24px', color: skyBase }}
                  disabled={isEditingPortfolioName}
                  rightSection={
                    <Icon
                      name="check_circle"
                      variant="outlined"
                      size={20}
                      color={skyDark}
                      style={{ cursor: isEditingPortfolioName ? 'not-allowed' : 'pointer' }}
                      onClick={handleNameSave}
                    />
                  }
                />
              ) : (
                <>
                  <Text weight="bold" size="large">
                    {portfolio?.name}
                  </Text>
                  <Icon name="edit" size={20} color={skyDark} style={{ cursor: 'pointer' }} onClick={handleNameEdit} />
                </>
              )}
            </Group>
            <Group>
              <TextInput
                placeholder="Search for Project"
                value={search}
                onChange={(event) => setSearch(event.currentTarget.value)}
                leftSection={<Icon name="search" size={16} color={skyDark} />}
                style={{ width: 250 }}
                radius="xl"
                size="xs"
              />
              {isEditingPortfolio ? (
                <Button
                  variant="outline"
                  size="xs"
                  radius="xl"
                  leftSection={<Icon name="delete" size={16} color={portfolio.is_default ? skyDark : redBase} />}
                  style={{
                    padding: '8px 16px',
                    gap: '8px',
                    borderColor: portfolio.is_default ? skyDark : redBase,
                    color: portfolio.is_default ? skyDark : redBase,
                  }}
                  onClick={() => setShowDeleteModal(true)}
                  disabled={portfolio.is_default || isUpdatingProjects}
                >
                  <Text>Delete</Text>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  size="xs"
                  radius="xl"
                  leftSection={<Icon name="edit" variant="filled" size={16} color="white" />}
                  style={{ padding: '8px 16px', gap: '8px' }}
                  onClick={() => setIsEditingPortfolio(true)}
                >
                  <Text>Edit Portfolio</Text>
                </Button>
              )}
            </Group>
          </HeaderContainer>
          <DeleteConfirmationModal
            isOpen={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onDelete={() => deletePortfolio(portfolio)}
            title="Delete Portfolio"
            itemName={portfolio.name}
            isDeleting={isDeleting}
            error={error}
          />
        </>
      )}
      <Divider style={{ marginTop: portfolio ? 24 : 54 }} />
      {portfolio && portfolioProjects.length > 0 ? (
        <TableContainer>
          <Table
            data={filteredData}
            columns={columns}
            onRowClick={(row) => (isEditingPortfolio ? handleRemoveProject(row.id) : onProjectSelect(row.id))}
            selectedRowId={selectedProject?.id}
            getRowId={(row) => row.id}
            stickyHeader
          />
        </TableContainer>
      ) : portfolio ? (
        <EmptyStateContainer>
          <Icon name="add_circle_outlined" size={40} color={skyDark} />
          <Text size="large" color={skyDark}>
            Start adding projects to your portfolio
          </Text>
        </EmptyStateContainer>
      ) : (
        <EmptyStateContainer>
          <Icon name="check_circle_outlined" size={40} color={skyDark} />
          <Text size="large" color={skyDark}>
            No portfolio selected
          </Text>
        </EmptyStateContainer>
      )}
    </DataManagementDropShadowContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TableContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
