import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Collapse, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Controls } from '@reactflow/controls';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import ReactFlow, { useReactFlow } from 'reactflow';
import { GraphData, ProcessMapping } from '../../api/process-client/process-client.type';
import { newCOLORS } from '../../styles/colors';
import { FloraButton } from '../flora/flora-button';
import { ProcessContext } from './context/process.context';
import { StageEdge } from './edges/stage-edge';
import { WorkflowEdgeIn, WorkflowEdgeOut } from './edges/workflow-edge';
import { StageNode } from './nodes/stage-node';
import { WorkflowNode } from './nodes/workflow-node';
import { calculateGraph } from './process.helpers';
import { SectionContainer, Text } from './process.styled';
import { Legend } from './scope/legend';
import { Scope } from './scope/scope';
const nodeTypes = {
  stageNode: StageNode,
  workflowNode: WorkflowNode,
};

const edgeTypes = {
  stageEdge: StageEdge,
  workflowEdgeIn: WorkflowEdgeIn,
  workflowEdgeOut: WorkflowEdgeOut,
};

type Props = {
  graphData?: GraphData;
  mapping: ProcessMapping | null;
};

export const SectionGraph = memo(({ graphData, mapping }: Props) => {
  const [opened, { toggle }] = useDisclosure(true);
  const [isPercentage, setIsPercentage] = useState<boolean>(false);
  const { fitView } = useReactFlow();
  const { stages } = useContext(ProcessContext);
  const { nodes, edges } = useMemo(
    () => calculateGraph(graphData, mapping, stages, isPercentage),
    [graphData, mapping, stages, isPercentage],
  );

  const handleToggle = useCallback(() => {
    toggle();
    setTimeout(fitView);
  }, [toggle, fitView]);

  const flowProps = useMemo(
    () => ({
      nodeOrigin: [0.5, 0.5] as [number, number],
      fitView: true,
      nodeTypes,
      edgeTypes,
      nodes,
      edges,
      proOptions: { hideAttribution: true },
      edgesFocusable: false,
      elementsSelectable: false,
    }),
    [nodes, edges],
  );

  return (
    <SectionContainer style={{ padding: '24px 16px' }}>
      <HeadingContainer>
        <CollapseIcon>
          <Icon
            icon={opened ? 'icon-park-solid:down-one' : 'icon-park-solid:right-one'}
            width={16}
            height={16}
            color={newCOLORS.purple2}
            onClick={handleToggle}
          />
        </CollapseIcon>
        <Text fontSize={19}>Task Process Map</Text>
        <FloraButton externalData={[graphData]} size={30} />
      </HeadingContainer>
      <Collapse in={opened} style={{ width: '100%' }}>
        <Text fontSize={13} style={{ marginLeft: 40 }}>
          Click on any stage box below to see skipped stages and backwards tasks. Click it again to return to this
          default view.
        </Text>
        <Flex style={{ height: 800, marginTop: 24 }}>
          <ScopeContainer>
            <Scope mapping={mapping} setIsPercentage={setIsPercentage} isPercentage={isPercentage} />
            <Legend />
          </ScopeContainer>
          <StyledReactFlow {...flowProps}>
            <Controls position="bottom-right" showInteractive={false} />
          </StyledReactFlow>
        </Flex>
      </Collapse>
    </SectionContainer>
  );
});

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 16px;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 2px 6px;
  font-weight: 800;
  cursor: pointer;
`;

const StyledReactFlow = styled(ReactFlow)`
  .react-flow__handle {
    opacity: 0;
    border: none;
    min-height: 0;
  }

  .react-flow__controls {
    background: #f5f5f5;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  }

  .react-flow__edge-path {
    stroke: #000;
  }
`;

const ScopeContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ced4da;
  width: 400px;
  padding: 24px;
  gap: 32px;
  min-width: 260px;
`;
