import { Divider } from '@mantine/core';
import { PortfolioStatusesByProjectsAndBoards } from '../../../../../../api/portfolio-client/portfolio-client.type';
import { Workflow } from '../../../../../../api/workflows-client/workflows-client.type';
import { EditBoards } from '../../shared-components/boards';
import { WorkflowAction } from '../../shared-components/reducer';
import { Statuses } from '../../shared-components/statuses';
import { EditGridContainer, GridItem } from '../../shared-components/styles';
import { WorkflowConfiguration } from '../../shared-components/workflow-configuration';

export function EditWorkflowContent({
  state,
  dispatch,
  statusesByProjectsAndBoards,
}: {
  state: Workflow;
  dispatch: React.Dispatch<WorkflowAction>;
  statusesByProjectsAndBoards: PortfolioStatusesByProjectsAndBoards | undefined;
}) {
  return (
    <EditGridContainer>
      <GridItem>
        <EditBoards state={state} dispatch={dispatch} statusesByProjectsAndBoards={statusesByProjectsAndBoards} />
      </GridItem>
      <Divider orientation="vertical" />
      <GridItem>
        <Statuses state={state} dispatch={dispatch} statusesByProjectsAndBoards={statusesByProjectsAndBoards} />
      </GridItem>
      <Divider orientation="vertical" />
      <GridItem>
        <WorkflowConfiguration
          state={state}
          dispatch={dispatch}
          statusesByProjectsAndBoards={statusesByProjectsAndBoards}
        />
      </GridItem>
    </EditGridContainer>
  );
}
