/**
 * Returns the background color CSS variable based on the given variant.
 *
 * @param {string} [variant] The variant of the button.
 * @returns {string} The CSS variable for the background color.
 */
const getBackgroundColor = (variant?: 'primary' | 'secondary' | 'outline' | 'subtle' | 'warning'): string => {
  switch (variant) {
    case 'primary':
      return 'var(--primary-base)';
    case 'secondary':
      return 'var(--secondary-base)';
    case 'outline':
    case 'subtle':
      return 'var(--background-transparent)';
    case 'warning':
      return 'var(--red-base)';
    default:
      return 'var(--primary-base)';
  }
};

/**
 * Returns the background color CSS variable based on the given variant, used when the button is hovered.
 *
 * @param {string} [variant] The variant of the button.
 * @returns {string} The CSS variable for the background color.
 */
const getBackgroundColorHover = (variant?: 'primary' | 'secondary' | 'outline' | 'subtle' | 'warning'): string => {
  switch (variant) {
    case 'primary':
      return 'var(--primary-light)';
    case 'secondary':
      return 'var(--secondary-light)';
    case 'outline':
    case 'subtle':
      return 'var(--background-transparent)';
    case 'warning':
      return 'var(--red-light)';
    default:
      return 'var(--primary-light)';
  }
};

/**
 * Returns the CSS border-radius variable based on the given radius value.
 *
 * @param {string} [radius] The radius value.
 * @returns {string} The CSS border-radius variable.
 */
const getBorderRadius = (radius?: 'none' | 'sm' | 'md' | 'lg' | 'xl'): string => {
  switch (radius) {
    case 'none':
      return 'var(--border-radius-none)';
    case 'sm':
      return 'var(--border-radius-xxtiny)';
    case 'md':
      return 'var(--border-radius-xtiny)';
    case 'lg':
      return 'var(--border-radius-tiny)';
    case 'xl':
      return 'var(--border-radius-xlarge)';
    default:
      return 'var(--border-radius-xtiny)';
  }
};

/**
 * Returns the padding in pixels as a string based on the given size.
 *
 * @param {string} [size] The size of the element.
 * @returns {string} The padding in pixels.
 */
const getPadding = (size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): string => {
  switch (size) {
    case 'xs':
      return '6px 12px';
    case 'sm':
      return '8px 16px';
    case 'md':
      return '10px 22px';
    case 'lg':
      return '12px 24px';
    case 'xl':
      return '16px 32px';
    default:
      return '8px 16px';
  }
};

/**
 * Returns the text color CSS variable based on the given variant.
 *
 * @param {string} [variant] The variant of the button.
 * @returns {string} The CSS variable for the text color.
 */
const getColor = (variant?: 'primary' | 'secondary' | 'outline' | 'subtle' | 'warning'): string => {
  switch (variant) {
    case 'primary':
    case 'secondary':
      return 'var(--base-white)';
    case 'outline':
    case 'subtle':
      return 'var(--ink-light)';
    case 'warning':
      return 'var(--base-white)';
    default:
      return 'var(--base-white)';
  }
};

/**
 * Returns the text color CSS variable when the button is hovered, based on the given variant.
 *
 * @param {string} [variant] The variant of the button.
 * @returns {string} The CSS variable for the text color when hovered.
 */
const getColorHover = (variant?: 'primary' | 'secondary' | 'outline' | 'subtle' | 'warning'): string => {
  switch (variant) {
    case 'primary':
    case 'secondary':
      return 'var(--base-white)';
    case 'outline':
      return 'var(--ink-light)';
    case 'subtle':
      return 'var(--sky-dark)';
    case 'warning':
      return 'var(--base-white)';
    default:
      return 'var(--base-white)';
  }
};

export { getBackgroundColor, getBackgroundColorHover, getBorderRadius, getColor, getColorHover, getPadding };
