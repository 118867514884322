import { styled } from '@linaria/react';
import { Flex } from '@mantine/core';
import * as Sentry from '@sentry/browser';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSystemAccessCreate } from '../../../api/integrations-client/integrations-client.hooks';
import { IntegrationAuthType } from '../../../api/integrations-client/integrations-client.type';
import { useOrgId } from '../../../store/global-store/global-store.hooks';
import { useIntegrationsStore } from '../../../store/integrations-store/integrations-store';
import { setIntegration, updateIntegration } from '../../../store/integrations-store/integrations-store.actions';
import { baseWhite } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Divider } from '../../../ui-library/divider/divider';
import { Icon } from '../../../ui-library/icon/icon';
import { H3, Text } from '../../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../../menu-driven-container/menu-driven-container';
import { encodeBasicAuth, formatLogoSrc } from './add-new-integration.helpers';
import { BasicAuthForm as BasicAuthFormType, TokenAuthForm as TokenAuthFormType } from './add-new-integration.types';
import { BasicAuthForm } from './forms/basic-auth-form';
import { TokenAuthForm } from './forms/token-auth-form';

export const AddNewIntegrationAuthorize = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const baseUrl = searchParams.get('baseUrl');

  const integration = useIntegrationsStore((state) => state.integration);
  const orgId = useOrgId();

  const { mutateAsync: createSystemAccess } = useSystemAccessCreate();

  const submitSystemAccess = async (token: string) => {
    if (!integration || !orgId) {
      return;
    }

    const { id } = integration;

    try {
      const result = await createSystemAccess({ id, orgId, token });
      if (result) {
        updateIntegration({ baseUrl, system_access_id: result.id });
        navigate(`/application/integrations/${integration.name}`);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleBasicAuthSubmit = async (values: BasicAuthFormType) => {
    const { email, password } = values;

    const token = encodeBasicAuth(email, password);

    await submitSystemAccess(token);
  };

  const handleTokenAuthSubmit = async (values: TokenAuthFormType) => {
    await submitSystemAccess(values.apiToken);
  };

  const header = (
    <Flex align="center" gap={16}>
      <Button
        variant="secondary"
        radius="xl"
        leftSection={<Icon name="arrow_back" size={18} color={baseWhite} />}
        onClick={() => {
          setIntegration(null);
          navigate('/application/integrations');
        }}
      >
        Back
      </Button>
      <H3>Add New Integrations</H3>
    </Flex>
  );

  if (!integration) {
    return null;
  }

  return (
    <MenuDrivenContainer header={header}>
      <PageContainer>
        <Flex direction="column" gap={8}>
          <Text size="large" lineHeight="none" weight="bold" style={{ padding: 10 }}>
            {integration.name}
          </Text>
          <Divider />
        </Flex>
        <Flex direction="column" gap={32} miw={400} style={{ margin: '0 auto' }}>
          <Flex gap={16}>
            <IntegrationIcon src={formatLogoSrc(integration.logo)} alt={integration.name} />
            <H3>Authorize {integration.name}</H3>
          </Flex>
          {integration?.auth_type === IntegrationAuthType.Basic ? (
            <BasicAuthForm onSubmit={handleBasicAuthSubmit} />
          ) : (
            <TokenAuthForm onSubmit={handleTokenAuthSubmit} />
          )}
        </Flex>
      </PageContainer>
    </MenuDrivenContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 150px;
  box-sizing: border-box;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  height: 100%;
  overflow-y: auto;
`;

const IntegrationIcon = styled.img`
  width: 32px;
  height: 32px;
`;
