import { Flex, Group } from '@mantine/core';
import React from 'react';
import { newCOLORS } from '../../../styles/colors';
import { baseBlack } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';

interface DashboardActionsProps {
  onAddWidget: () => void;
  onEditDashboard: () => void;
  dashboardDescription: string;
}

export const DashboardActions: React.FC<DashboardActionsProps> = ({
  onAddWidget,
  onEditDashboard,
  dashboardDescription,
}) => {
  return (
    <Flex justify="space-between" align="center" mb="xl">
      <Text color={baseBlack} size="regular">
        {dashboardDescription}
      </Text>
      <Group gap="sm">
        <Button
          size="sm"
          radius="xl"
          variant="outline"
          color={newCOLORS.darkGray}
          leftSection={<Icon color={newCOLORS.darkGray} name="edit" size={16} />}
          onClick={onEditDashboard}
        >
          Edit
        </Button>
        <Button
          size="sm"
          radius="xl"
          variant="primary"
          leftSection={<Icon name="add_circle" size={20} color="white" />}
          onClick={onAddWidget}
        >
          Add New Widget
        </Button>
      </Group>
    </Flex>
  );
};
