import { newCOLORS } from '../../../styles/colors';

const SPRINT_COMPARISON_COLORS = [
  newCOLORS.indigo,
  newCOLORS.pink,
  newCOLORS.green,
  newCOLORS.orange,
  newCOLORS.purple,
  newCOLORS.blue,
  newCOLORS.red,
  newCOLORS.teal,
];

interface SelectedWorkPeriod {
  id: string;
  name: string;
}

export { SPRINT_COMPARISON_COLORS };
export type { SelectedWorkPeriod };
