import { QueryObserverResult, useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { editMe, fetchUserInfo } from './user-client';
import { UpdateUserOptions, UpdateUserResult, UserInfo } from './user-client.type';

/**
 * Query manager for fetching the currently logged in user's information.
 *
 * @param {Partial<UseQueryOptions<UserInfo>>} options - Options for the query.
 * @return {{ data: UserInfo | undefined; query: QueryObserverResult<UserInfo> }} An object containing the user
 * information and the query result.
 */
const useUser = (
  options?: Partial<UseQueryOptions<UserInfo>>,
): { data: UserInfo | undefined; query: QueryObserverResult<UserInfo> } => {
  const query = useQuery({
    queryKey: ['user'],
    queryFn: fetchUserInfo,
    staleTime: 1000 * 60 * 5,
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Hook to update the current user's information
 *
 * @param {UpdateUserOptions} [options] - Mutation options for the update
 * @returns {UpdateUserResult} Mutation result containing updated user data or error
 */
export const useUpdateUser = (options?: UpdateUserOptions): UpdateUserResult => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userData: Partial<UserInfo>) => editMe(userData),
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch user data
      queryClient.invalidateQueries({ queryKey: ['user'] });
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
};

export { useUser };
