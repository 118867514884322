export enum Tab {
  DataSources = 'data-sources',
  Portfolios = 'portfolios',
  BoardSettings = 'board-settings',
  Workflows = 'workflows',
}

export interface State {
  activeTab: Tab;
}
