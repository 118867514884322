import { useGlobalStore } from '../../store/global-store/global-store';
import { GeneralViewNames, NavItem } from './side-bar.type';

const showDashboards: boolean = import.meta.env.VITE_FEATURE_FLAG_DASHBOARDS === 'true';

export function useNavItems(): NavItem[] {
  const useAI = useGlobalStore((state) => state.useAI);

  const navItems: NavItem[] = [
    {
      label: GeneralViewNames.Portfolio,
      icon: 'business_center',
      path: '/application/dashboard',
      show: true,
    },
    {
      label: GeneralViewNames.Dashboards,
      icon: 'dashboard',
      path: '/application/dashboards',
      isBeta: true,
      show: showDashboards,
    },
    {
      label: GeneralViewNames.Strategy,
      icon: 'ads_click',
      path: '/application/strategy',
      show: true,
    },
    {
      label: GeneralViewNames.Financials,
      icon: 'payments',
      path: '/application/financials/portfolio',
      show: true,
    },
    {
      label: GeneralViewNames.Process,
      icon: 'map',
      path: `/application/process/`,
      show: true,
    },
    {
      label: GeneralViewNames.ProcessAnalysis,
      icon: 'auto_graph',
      path: '/application/process-analysis',
      show: true,
    },
    {
      label: GeneralViewNames.Adherence,
      icon: 'emergency',
      path: '/application/adherence',
      show: useAI,
      isBeta: true,
    },
    {
      label: GeneralViewNames.Integrations,
      icon: 'hub',
      path: '/application/integrations-list',
      show: true,
    },
  ];

  return navItems;
}
