import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { OutOfBloom } from '../../components/out-of-bloom/out-of-bloom';
import { ProtectedRoute } from '../../components/protected-route/protected-route';
import { Setup } from '../../containers/setup/setup';
import { BoardProvider, GlobalProvider, TeamProvider, UserProvider } from '../../contexts';
import { GlobalFilterProvider } from '../../contexts/global-filter';
import { theme } from '../../styles/theme';
import { FloraProvider } from '../flora/flora.context';
import { IntegrationsProvider } from '../integrations/context/integrations.context';
import { NavigationContainer } from '../navigation/navigation';
import { CreatePasswordForm } from '../onboarding/reset-password/create-password-form';
import { CreatePasswordSuccess } from '../onboarding/reset-password/create-password-success';
import { ResetPasswordForm } from '../onboarding/reset-password/reset-password-form';
import { ResetPasswordSuccess } from '../onboarding/reset-password/reset-password-success';
import { SignIn } from '../onboarding/sign-in/sign-in';
import { SignUp } from '../onboarding/sign-up/sign-up';
import { ProcessProvider } from '../process/context/process.context';
import { SidebarProvider } from '../side-bar/side-bar.context';
import { StrategyProvider } from '../strategy/context/strategy.context';
import { reactQueryConfig } from './app.config';

export function App() {
  return (
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <ToastContainer theme="colored" />
      <MantineProvider theme={theme} defaultColorScheme="light">
        <QueryClientProvider client={new QueryClient(reactQueryConfig)}>
          <UserProvider>
            <GlobalProvider>
              <GlobalFilterProvider>
                <TeamProvider>
                  <BoardProvider>
                    <IntegrationsProvider>
                      <StrategyProvider>
                        <ProcessProvider>
                          <FloraProvider>
                            <SidebarProvider>
                              <Routes>
                                <Route path="/" element={<SignIn />} />
                                <Route path="/sign-up" element={<SignUp />} />
                                <Route path="/reset-password" element={<ResetPasswordForm />} />
                                <Route path="/reset-password-success" element={<ResetPasswordSuccess />} />
                                <Route
                                  path="/password/reset/confirm/:uid/:token/:email"
                                  element={<CreatePasswordForm />}
                                />
                                <Route path="/create-password-success" element={<CreatePasswordSuccess />} />
                                <Route
                                  path="/application/setup"
                                  element={
                                    <ProtectedRoute>
                                      <Setup />
                                    </ProtectedRoute>
                                  }
                                />
                                <Route
                                  path="/application/*"
                                  element={
                                    <ProtectedRoute>
                                      <NavigationContainer />
                                    </ProtectedRoute>
                                  }
                                />
                                <Route path="*" element={<OutOfBloom />} />
                              </Routes>
                            </SidebarProvider>
                          </FloraProvider>
                        </ProcessProvider>
                      </StrategyProvider>
                    </IntegrationsProvider>
                  </BoardProvider>
                </TeamProvider>
              </GlobalFilterProvider>
            </GlobalProvider>
          </UserProvider>
        </QueryClientProvider>
      </MantineProvider>
    </Router>
  );
}
