import { styled } from '@linaria/react';
import { Loader as MantineLoader } from '@mantine/core';
import { secondaryBase } from '../../styles/design-tokens';

interface Props {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  variant?: 'dots' | 'oval' | 'bars';
}

export const Loader = ({ size = 'md', variant = 'oval', ...props }: Props) => {
  return <StyledLoader data-testid="loader" size={size} type={variant} color={secondaryBase} {...props} />;
};

const StyledLoader = styled(MantineLoader)`
  &::after {
    box-sizing: border-box;
    border-width: calc(var(--loader-size) / 6);
    border-color: var(--secondary-base) var(--secondary-lighter) var(--secondary-lighter) var(--secondary-lighter);
  }
`;
