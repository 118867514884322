import { styled } from '@linaria/react';
import { Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MeasureDetail } from '../../api/work-periods-client/work-periods-client.type';
import { skyDark } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { MeasureInfoBoxButton } from './measure-info-box-button';
import { MeasureInfoBoxCard } from './measure-info-box-card';

type Props = {
  measure: MeasureDetail;
};

export function MeasureInfoBox({ measure }: Props) {
  const [isOpen, { toggle }] = useDisclosure(true);

  return (
    <Container align="center" justify="center" gap={24}>
      <Flex gap={24} style={{ width: '100%' }}>
        <MeasureInfoBoxCard
          icon={<Icon name="tips_and_updates" size={30} color={skyDark} />}
          title="How should I use this measure?"
          description={measure.purpose}
          expanded={isOpen}
          style={{ flex: 1 }}
        />
        <MeasureInfoBoxCard
          icon={<Icon name="calculate" size={30} color={skyDark} />}
          title="How it is calculated"
          description={measure.description}
          expanded={isOpen}
          style={{ flex: 1 }}
        />
      </Flex>
      <MeasureInfoBoxButton isOpen={isOpen} onClick={toggle} />
    </Container>
  );
}

const Container = styled(Flex)`
  box-sizing: border-box;
  min-width: 1100px;
  width: 100%;
  border: 1px solid var(--sky-light);
  background: var(--base-white);
  padding: 24px;
  border-radius: 8px;
`;
