import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { Workflow, WorkflowPayload } from './workflows-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/api/workflows/';

/**
 * Creates a workflow
 * @param {WorkflowPayload} payload The workflow payload
 * @returns {Promise<Workflow>} The created workflow
 */
async function createWorkflow(payload: WorkflowPayload): Promise<Workflow> {
  const apiURL = baseURL;
  const response = await axiosInstance.post(apiURL, payload);
  return response.data;
}

/**
 * Updates a workflow
 * @param {string} workflowId The workflow ID
 * @param {WorkflowPayload} payload The workflow payload
 * @returns {Promise<Workflow>} The updated workflow
 */
async function updateWorkflow({
  workflowId,
  payload,
}: {
  workflowId: string;
  payload: WorkflowPayload;
}): Promise<Workflow> {
  const apiURL = `${baseURL}${workflowId}/`;
  const response = await axiosInstance.put(apiURL, payload);
  return response.data;
}

/**
 * Fetches all workflows for a given portfolio
 * @param {string} portfolioId The portfolio ID
 * @returns {Promise<Workflow[]>} The list of workflows
 */
async function getWorkflows(portfolioId: string): Promise<Workflow[]> {
  const params = new URLSearchParams({
    portfolio_id: portfolioId,
  });
  const apiURL = baseURL + '?' + params.toString();
  const response = await axiosInstance.get(apiURL);
  return response.data;
}

/**
 * Deletes a workflow
 * @param {string} workflowId The workflow ID
 * @returns {Promise<void>}
 */
async function deleteWorkflow(workflowId: string): Promise<void> {
  const apiURL = `${baseURL}${workflowId}/`;
  await axiosInstance.delete(apiURL);
}

export { createWorkflow, deleteWorkflow, getWorkflows, updateWorkflow };
