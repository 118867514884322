import { Workflow } from '../../../../../api/workflows-client/workflows-client.type';
import { PanelList } from '../../panel-list';

export function WorkflowsList({
  workflows,
  onCreate,
  onSelectWorkflow,
  selectedWorkflowId,
}: {
  workflows: Workflow[];
  onCreate?: () => void;
  onSelectWorkflow: (workflow: Workflow) => void;
  selectedWorkflowId: string | null;
}) {
  return (
    <PanelList
      title="Workflows"
      items={workflows}
      selectedItem={workflows.find((workflow) => workflow.id === selectedWorkflowId) ?? null}
      onSelect={onSelectWorkflow}
      onAddNew={onCreate ? onCreate : undefined}
      getItemId={(workflow) => workflow.id ?? ''}
      getItemDisplayText={(workflow) => workflow.name}
    />
  );
}
