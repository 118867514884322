import { styled } from '@linaria/react';
import { useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Flex } from '@mantine/core';
import { PortfolioStatusesByProjectsAndBoards } from '../../../../../api/portfolio-client/portfolio-client.type';
import { Workflow } from '../../../../../api/workflows-client/workflows-client.type';
import { baseWhite, secondaryBase, skyDark } from '../../../../../styles/design-tokens';
import { Icon } from '../../../../../ui-library/icon/icon';
import { Text } from '../../../../../ui-library/typography/typography';
import {
  filterPortfolioStatusesByProjectsAndBoardsBySubprojectIds,
  getMappedAndUnmappedStatusesForWorkflow,
} from '../workflows.helpers';
import { WorkflowAction } from './reducer';

export function Statuses({
  state,
  dispatch,
  statusesByProjectsAndBoards,
}: {
  state: Workflow;
  dispatch: (action: WorkflowAction) => void;
  statusesByProjectsAndBoards: PortfolioStatusesByProjectsAndBoards | undefined;
}) {
  const statusesForWorkflowSubprojects = filterPortfolioStatusesByProjectsAndBoardsBySubprojectIds(
    statusesByProjectsAndBoards,
    state.subprojects,
  );
  const { unmappedStatuses } = getMappedAndUnmappedStatusesForWorkflow(state, statusesForWorkflowSubprojects);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        overflow: 'hidden',
      }}
    >
      <Text weight="bold">Active Statuses</Text>

      <div
        style={{
          flex: 1,
          minHeight: 0,
          overflow: 'auto',
          paddingBottom: '16px',
        }}
      >
        <Flex direction="column" gap="xs" h="100%" my="md">
          {unmappedStatuses.length > 0 ? (
            unmappedStatuses.map((status) => <Status key={status} status={status} dispatch={dispatch} />)
          ) : (
            <Text color={skyDark} size="small" style={{ textAlign: 'left', padding: '0' }}>
              Add boards to view statuses here
            </Text>
          )}
        </Flex>
      </div>
    </div>
  );
}

export function Status({
  status,
  dispatch,
  backgroundColor,
}: {
  status: string;
  dispatch: (action: WorkflowAction) => void;
  backgroundColor?: string;
}) {
  const ref = useRef(null);
  // Each Status can be dragged
  const [dragging, setDragging] = useState<boolean>(false);
  // Each Status can be "dragged over" as a drop target
  const [_, setDraggedOver] = useState<'idle' | 'top' | 'bottom'>('idle');

  useEffect(() => {
    const element = ref.current;
    invariant(element);

    return combine(
      draggable({
        element,
        getInitialData: () => ({ status, type: 'status' }),
        onDragStart: () => setDragging(true),
        onDrop: () => setDragging(false),
      }),
      dropTargetForElements({
        element,
        onDragEnter: ({ location }) => {
          const target = location.current.dropTargets[0];
          if (!target) {
            return;
          }

          const closestEdgeOfTarget = extractClosestEdge(target.data) as 'top' | 'bottom';
          setDraggedOver(closestEdgeOfTarget);
        },
        onDragLeave: () => setDraggedOver('idle'),
        onDrop: ({ location }) => {
          const target = location.current.dropTargets[0];
          if (!target) {
            return;
          }
          setDraggedOver('idle');
        },
        getData({ input }) {
          return attachClosestEdge(
            { status },
            {
              element,
              input,
              allowedEdges: ['top', 'bottom'],
            },
          );
        },
      }),
    );
  }, [status, dispatch]);

  return dragging ? null : (
    <FlexContainer ref={ref} backgroundColor={backgroundColor}>
      <Text color={baseWhite}>{status}</Text>
      <Icon name="drag_indicator" size={16} color={baseWhite} style={{ cursor: 'grab' }} />
    </FlexContainer>
  );
}

const FlexContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor || secondaryBase};
  z-index: 100;
  &:hover {
    cursor: grab;
  }
`;
