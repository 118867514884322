import { ValidationError } from '../sign-up.type';

const PASSWORD_RULES = [
  {
    test: (pwd: string) => pwd.length >= 8,
    error: ValidationError.PasswordTooShort,
  },
  {
    test: (pwd: string) => /[a-z]/.test(pwd),
    error: ValidationError.PasswordMissingLowercase,
  },
  {
    test: (pwd: string) => /[A-Z]/.test(pwd),
    error: ValidationError.PasswordMissingUppercase,
  },
  {
    test: (pwd: string) => /\d/.test(pwd),
    error: ValidationError.PasswordMissingNumber,
  },
  {
    test: (pwd: string) => /[~!@#$%^&*()_+{}\\":;'[\]]/.test(pwd),
    error: ValidationError.PasswordMissingSpecial,
  },
  {
    test: (pwd: string) => !/\s/.test(pwd),
    error: ValidationError.PasswordContainsSpaces,
  },
] as const;

/**
 * Validates a password against a set of security rules
 *
 * Rules checked:
 * - Must be at least 9 characters long
 * - Must contain at least one lowercase letter
 * - Must contain at least one uppercase letter
 * - Must contain at least one number
 * - Must contain at least one special character (!@#$%^&*)
 * - Must not contain any spaces
 *
 * @param {string} password - The password to validate
 * @returns {ValidationError | null} Returns null if valid, otherwise returns the specific ValidationError
 */
const checkPassword = (password: string): ValidationError | null => {
  for (const rule of PASSWORD_RULES) {
    if (!rule.test(password)) {
      return rule.error;
    }
  }

  return null;
};

/**
 * Validates a name against a set of rules
 *
 * Rules checked:
 * - Must not exceed specified maximum length (defaults to 256 characters)
 * - Must only contain letters, numbers, hyphens (-), underscores (_), plus signs (+),
 *   at signs (@), spaces, apostrophes ('), periods (.) or commas (,)
 * - Must not begin or end with a period, hyphen, or space
 * - Must not contain consecutive periods
 *
 * @param {string} name - The name to validate
 * @param {number} [maxLength=256] - Maximum allowed length for the name
 * @returns {ValidationError | null} Returns null if valid, otherwise returns the specific ValidationError
 */
const checkName = (name: string, maxLength = 256): ValidationError | null => {
  if (name.length > maxLength) {
    return ValidationError.NameTooLong;
  }

  const validChars = "a-zA-Z0-9'@_+," as const;
  const validMiddle = `${validChars}.\\s-` as const;
  const invalidEnds = '.\\s-' as const;
  const pattern = new RegExp(`^(?:[^${invalidEnds}][${validMiddle}]*[^${invalidEnds}]|[${validChars}])$`);

  if (!pattern.test(name)) {
    return ValidationError.InvalidNameCharacters;
  }

  if (name.includes('..')) {
    return ValidationError.ConsecutivePeriods;
  }

  return null;
};

export { checkName, checkPassword };
