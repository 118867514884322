import { Box, Card, Flex, Grid } from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useCreateComponent,
  useCreatePage,
  useDeleteComponent,
  useDeletePage,
  usePages,
  useUpdateComponent,
  useUpdatePage,
} from '../../api/pages-client/pages-client.hooks';
import { Component, Page } from '../../api/pages-client/pages-client.type';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { useGlobalStore } from '../../store/global-store/global-store';
import { primaryBase } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';
import { DeleteConfirmationModal } from '../data-management/components/delete-confirmation-modal';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { AddWidgetModal } from './components/add-widget-modal';
import { DashboardActions } from './components/dashboard-actions';
import { DashboardHeader } from './components/dashboard-header';
import { EditDashboardModal } from './components/dashboard-modal';
import { DashboardWidget } from './components/dashboard-widget';

export const Dashboards: React.FC = () => {
  useDocumentTitle('Dashboards - Bloomfilter');

  const [isAddWidgetOpen, setIsAddWidgetOpen] = useState(false);
  const [isEditDashboardOpen, setIsEditDashboardOpen] = useState(false);
  const [isCreateDashboardOpen, setIsCreateDashboardOpen] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState('');
  const [localDashboards, setLocalDashboards] = useState<Page[]>([]);
  const [isDeleteDashboardOpen, setIsDeleteDashboardOpen] = useState(false);
  const [hasClosedInitialCreate, setHasClosedInitialCreate] = useState(false);

  // Get organization and portfolio IDs from global store
  const organization = useGlobalStore((state) => state.organization);
  const portfolio = useGlobalStore((state) => state.portfolio);

  const { pages, query } = usePages(
    organization?.id || '',
    portfolio?.id || '',
    'dashboard', // type
    'dashboard', // moduleType
  );

  // Keep local state in sync with server data
  useEffect(() => {
    if (!query.isLoading && !query.isFetching && pages) {
      const pagesArray = pages || [];

      // Only update local dashboards if they've actually changed
      if (JSON.stringify(localDashboards) !== JSON.stringify(pagesArray)) {
        setLocalDashboards(pagesArray);
      }

      // Only set selected dashboard if we have pages and no dashboard is currently selected
      if (!selectedDashboard && pagesArray.length > 0 && pagesArray[0].id) {
        setSelectedDashboard(pagesArray[0].id);
      }

      // Only show create dashboard modal if pages are loaded and there are none
      // and user hasn't explicitly closed it
      if (pagesArray.length === 0 && !isCreateDashboardOpen && !hasClosedInitialCreate) {
        setIsCreateDashboardOpen(true);
      }
    }
  }, [
    pages,
    query.isLoading,
    query.isFetching,
    hasClosedInitialCreate,
    isCreateDashboardOpen,
    localDashboards,
    selectedDashboard,
  ]);

  const selectedPage = useMemo(() => {
    return selectedDashboard ? localDashboards.find((page: Page) => page.id === selectedDashboard) : null;
  }, [selectedDashboard, localDashboards]);

  const createPageMutation = useCreatePage();
  const createComponentMutation = useCreateComponent();
  const deletePageMutation = useDeletePage();
  const updatePageMutation = useUpdatePage();
  const deleteComponentMutation = useDeleteComponent();
  const updateComponentMutation = useUpdateComponent();

  const handleAddWidget = async (widgetData: Component) => {
    try {
      if (selectedPage && selectedPage.id) {
        // Add widget to existing page using createComponent
        const newComponent = await createComponentMutation.mutateAsync({
          component: {
            name: widgetData.name,
            description: widgetData.description || '',
            type: widgetData.type,
            sub_type: widgetData.sub_type,
            show_title: widgetData.show_title,
            page: selectedPage.id,
            component_data: widgetData.component_data?.map((data) => ({
              measure: data.measure,
              range_type: data.range_type,
              object_type: data.object_type,
              object_ids: data.object_ids,
            })),
          },
        });

        // Update local state after successful API call
        setLocalDashboards(
          localDashboards.map((page) =>
            page.id === selectedPage.id
              ? {
                  ...page,
                  components: [...(page.components || []), newComponent],
                }
              : page,
          ),
        );
        // Refetch data to ensure we have the latest state
        query.refetch();
      } else {
        // Create new page with widget
        const newPage: Page = {
          id: '', // Will be assigned by backend
          name: 'New Dashboard',
          description: 'New dashboard with widget',
          type: 'dashboard',
          layout: 'grid',
          components: [], // Start with empty components, we'll add the widget after creation
          organization: organization?.id || '',
          portfolio: portfolio?.id || '',
          module_type: 'dashboard',
        };
        const createdPage = await createPageMutation.mutateAsync(newPage);

        // Add the widget to the new page
        const newComponent = await createComponentMutation.mutateAsync({
          component: {
            name: widgetData.name,
            page: createdPage.id,
            description: widgetData.description || '',
            type: widgetData.type,
            sub_type: widgetData.sub_type,
            show_title: widgetData.show_title,
            component_data: widgetData.component_data?.map((data) => ({
              measure: data.measure,
              range_type: data.range_type,
              object_type: data.object_type,
              object_ids: data.object_ids,
            })),
          },
        });

        // Update local state after successful API calls
        setLocalDashboards([
          ...localDashboards,
          {
            ...createdPage,
            components: [newComponent],
          },
        ]);
        setSelectedDashboard(createdPage.id);
        // Refetch data to ensure we have the latest state
        query.refetch();
      }
      setIsAddWidgetOpen(false);
    } catch (error) {
      console.error('Failed to add widget:', error);
    }
  };

  const handleEditDashboard = async (data: { name: string; description: string }) => {
    if (!selectedPage || !organization?.id) return;

    try {
      // Ensure all components have required fields
      const components: Component[] = (selectedPage.components || []).map(
        (comp) =>
          ({
            id: comp.id,
            name: comp.name,
            page: selectedPage.id,
            description: comp.description || '',
            type: comp.type,
            sub_type: comp.sub_type || 'line',
            show_title: comp.show_title,
            component_data: comp.component_data?.map((data) => ({
              id: data.id || '',
              measure: data.measure,
              range_type: data.range_type,
              object_type: data.object_type,
              object_ids: data.object_ids,
              unit: 'unit' in data ? data.unit : undefined,
            })),
          }) as Component,
      );

      const updatedPage: Page = {
        id: selectedPage.id,
        name: data.name,
        description: data.description,
        type: selectedPage.type,
        layout: 'grid',
        organization: organization.id || '',
        portfolio: portfolio?.id || '',
        module_type: 'dashboard',
        components,
      };

      // First argument is id, second is the page data
      const result = await updatePageMutation.mutateAsync({ id: selectedPage.id, payload: updatedPage });

      // Update local state after successful API call
      setLocalDashboards(localDashboards.map((page) => (page.id === selectedDashboard ? result : page)));
      // Refetch data to ensure we have the latest state
      query.refetch();
      setIsEditDashboardOpen(false);
    } catch (error) {
      console.error('Failed to update dashboard:', error);
    }
  };

  const handleCreateDashboard = async (data: { name: string; description: string }) => {
    if (!organization?.id) return;

    try {
      const newPage: Page = {
        name: data.name,
        description: data.description,
        type: 'dashboard',
        layout: 'grid',
        organization: organization.id || '',
        portfolio: portfolio?.id || '',
        module_type: 'dashboard',
      };

      // Add organization_id to the request payload
      const createdPage = await createPageMutation.mutateAsync({
        ...newPage,
      });

      // Update local state after successful API call
      setLocalDashboards([...localDashboards, createdPage]);
      setSelectedDashboard(createdPage.id);
      // Refetch data to ensure we have the latest state
      query.refetch();
      setIsCreateDashboardOpen(false);
    } catch (error) {
      console.error('Failed to create dashboard:', error);
    }
  };

  const handleDeleteDashboard = async (dashboardId: string) => {
    try {
      await deletePageMutation.mutateAsync(dashboardId);

      // Update local state after successful deletion
      const updatedDashboards = localDashboards.filter((page) => page.id !== dashboardId);
      setLocalDashboards(updatedDashboards);

      // If the deleted dashboard was selected, select the first available dashboard
      if (dashboardId === selectedDashboard) {
        setSelectedDashboard(updatedDashboards[0]?.id || '');
      }
      // Refetch data to ensure we have the latest state
      query.refetch();
    } catch (error) {
      console.error('Failed to delete dashboard:', error);
    }
  };

  const confirmDeleteDashboard = () => {
    if (selectedDashboard) {
      handleDeleteDashboard(selectedDashboard);
    }
    setIsDeleteDashboardOpen(false);
  };

  const handleDeleteWidget = async (componentId: string) => {
    try {
      await deleteComponentMutation.mutateAsync({ componentId });

      // Update local state after successful deletion
      setLocalDashboards(
        localDashboards.map((page) =>
          page.id === selectedDashboard
            ? {
                ...page,
                components: page.components?.filter((comp) => comp.id !== componentId) || [],
              }
            : page,
        ),
      );
      // Refetch data to ensure we have the latest state
      query.refetch();
    } catch (error) {
      console.error('Failed to delete widget:', error);
    }
  };

  const handleEditWidget = async (updatedWidget: Component) => {
    try {
      if (!updatedWidget.id) {
        console.error('Cannot update widget without an id');
        return;
      }

      // Ensure component data is properly formatted
      const formattedWidget = {
        ...updatedWidget,
        component_data: updatedWidget.component_data?.map((data) => ({
          id: data.id || '',
          measure: data.measure,
          range_type: data.range_type,
          object_type: data.object_type,
          object_ids: data.object_ids,
        })),
      };

      // Update the component on the server
      const result = await updateComponentMutation.mutateAsync({
        componentId: updatedWidget.id,
        component: formattedWidget,
      });

      // Update local state after successful API call
      setLocalDashboards(
        localDashboards.map((page) =>
          page.id === selectedDashboard
            ? {
                ...page,
                components: page.components?.map((comp) => (comp.id === updatedWidget.id ? result : comp)) || [],
              }
            : page,
        ),
      );

      // Refetch data to ensure we have the latest state
      query.refetch();
    } catch (error) {
      console.error('Failed to update widget:', error);
    }
  };

  const handleCloseCreateDashboard = () => {
    setIsCreateDashboardOpen(false);
    setHasClosedInitialCreate(true);
  };

  if (query.isLoading) {
    return (
      <MenuDrivenContainer>
        <Box p="md">
          <BrandedLoadingOverlay visible={true} transitionDuration={30} variant="colored" />
        </Box>
      </MenuDrivenContainer>
    );
  }

  // Show null state when no dashboards exist
  if (!query.isLoading && (!localDashboards || localDashboards.length === 0)) {
    return (
      <MenuDrivenContainer
        header={
          <DashboardHeader
            selectedDashboard=""
            onDashboardChange={setSelectedDashboard}
            onCreateDashboard={() => setIsCreateDashboardOpen(true)}
            onDeleteDashboard={() => setIsDeleteDashboardOpen(false)}
            dashboards={[]}
          />
        }
      >
        <Box p="md">
          <Flex
            justify="center"
            align="center"
            style={{
              minHeight: 'calc(100vh - 250px)',
              width: '100%',
            }}
          >
            <Card
              shadow="sm"
              padding="xl"
              radius="md"
              withBorder
              style={{
                width: '416px',
                height: '240px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                backgroundColor: 'white',
              }}
              onClick={() => setIsCreateDashboardOpen(true)}
            >
              <Flex direction="column" align="center" gap="md">
                <Icon name="add_circle" variant="outlined" size={40} color={primaryBase} />
                <Text weight="medium" size="large">
                  Create Your First Dashboard
                </Text>
              </Flex>
            </Card>
          </Flex>
          <EditDashboardModal
            opened={isCreateDashboardOpen}
            onClose={handleCloseCreateDashboard}
            onSave={handleCreateDashboard}
            initialData={{
              name: '',
              description: '',
            }}
            isCreating={true}
          />
        </Box>
      </MenuDrivenContainer>
    );
  }

  const handleEditDashboardModal = () => {
    setIsEditDashboardOpen(true);
  };

  return (
    <MenuDrivenContainer
      header={
        <DashboardHeader
          selectedDashboard={selectedDashboard}
          onDashboardChange={setSelectedDashboard}
          onCreateDashboard={() => setIsCreateDashboardOpen(true)}
          onDeleteDashboard={() => setIsDeleteDashboardOpen(true)}
          dashboards={localDashboards}
        />
      }
    >
      <Box p="md">
        <BrandedLoadingOverlay visible={false} transitionDuration={30} variant="colored" />
        <DashboardActions
          onAddWidget={() => setIsAddWidgetOpen(true)}
          onEditDashboard={() => handleEditDashboardModal()}
          dashboardDescription={selectedPage?.description || ''}
        />
        {selectedPage?.components?.length === 0 ? (
          <Flex
            justify="center"
            align="center"
            style={{
              minHeight: 'calc(100vh - 250px)',
              width: '100%',
            }}
          >
            <Card
              shadow="sm"
              padding="xl"
              radius="md"
              withBorder
              style={{
                width: '416px',
                height: '240px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                backgroundColor: 'white',
              }}
              onClick={() => setIsAddWidgetOpen(true)}
            >
              <Flex direction="column" align="center" gap="md">
                <Icon name="add_circle" variant="outlined" size={40} color={primaryBase} />
                <Text weight="medium" size="large">
                  Add First Widget
                </Text>
              </Flex>
            </Card>
          </Flex>
        ) : (
          <Grid grow gutter={{ base: 3, xs: 'md', md: 'xl', xl: 50 }} style={{ width: '100%' }}>
            {selectedPage?.components?.map((component) => {
              if (!component.id) return null;
              component.page = selectedPage?.id || '';
              return (
                <Grid.Col key={component.id} span={3}>
                  <DashboardWidget
                    component={component}
                    onEdit={handleEditWidget}
                    onDelete={() => handleDeleteWidget(component.id!)}
                  />
                </Grid.Col>
              );
            })}
          </Grid>
        )}
        <AddWidgetModal opened={isAddWidgetOpen} onClose={() => setIsAddWidgetOpen(false)} onSave={handleAddWidget} />
        <EditDashboardModal
          opened={isCreateDashboardOpen || isEditDashboardOpen}
          onClose={() => (isEditDashboardOpen ? setIsEditDashboardOpen(false) : setIsCreateDashboardOpen(false))}
          onSave={isEditDashboardOpen ? handleEditDashboard : handleCreateDashboard}
          initialData={{
            name: selectedPage && isEditDashboardOpen ? selectedPage.name : '',
            description: selectedPage && isEditDashboardOpen ? selectedPage.description : '',
          }}
          isCreating={isCreateDashboardOpen}
        />
        <DeleteConfirmationModal
          isOpen={isDeleteDashboardOpen}
          onClose={() => setIsDeleteDashboardOpen(false)}
          onDelete={confirmDeleteDashboard}
          title="Delete Dashboard"
          itemName={selectedPage ? selectedPage.name : ''}
          isDeleting={deletePageMutation.isPending}
          error={deletePageMutation.error}
        />
      </Box>
    </MenuDrivenContainer>
  );
};
