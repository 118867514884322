import { Combobox, ComboboxItem, Input, InputBase, useCombobox } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Tooltip } from '../tooltip/tooltip';
import { Text } from '../typography/typography';
import { getBorderRadius, getFontStyle, getHeight } from './select.helpers';
import classes from './select.module.css';

type Props = {
  value: string | null;
  options: ComboboxItem[];
  onChange: (value: string) => void;
  leftIcon?: React.ReactNode;
  placeholder?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  radius?: 'none' | 'sm' | 'md' | 'lg' | 'xl';
  enableTooltip?: boolean;
  searchable?: boolean;
  disableChevron?: boolean;
};

export function Select({
  value,
  options,
  onChange,
  placeholder,
  leftIcon,
  size = 'md',
  radius = 'xl',
  enableTooltip = false,
  searchable = false,
  disableChevron = false,
}: Props) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (value) {
      const label = options.find(({ value: optionValue }) => optionValue === value)?.label || '';
      setSearch(label);
      setSelectedValue(label);
    }
  }, [value, options]);

  const shouldFilterOptions = options.every(({ label }) => label !== search);
  const filteredOptions = shouldFilterOptions
    ? options.filter(({ label }) => label.toLowerCase().includes(search.toLowerCase().trim()))
    : options;

  const comboboxOptions = filteredOptions.map(({ value, label }) => (
    <Combobox.Option value={value} key={value} style={{ padding: '4px 16px' }}>
      <Text weight="medium" style={{ color: label === selectedValue ? 'var(--sky-dark)' : 'var(--base-black)' }}>
        {label}
      </Text>
    </Combobox.Option>
  ));

  const inputStyles = {
    input: {
      borderRadius: getBorderRadius(radius),
      minHeight: getHeight(size),
      height: getHeight(size),
      fontFamily: 'var(--font-family)',
      paddingInlineEnd: disableChevron ? 2 : 34,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      ...getFontStyle(size),
    },
    section: {
      paddingLeft: 1,
    },
  };

  const input = searchable ? (
    <InputBase
      placeholder={placeholder}
      value={search}
      onChange={(event) => {
        combobox.openDropdown();
        combobox.updateSelectedOptionIndex();
        setSearch(event.currentTarget.value);
      }}
      onClick={() => combobox.toggleDropdown()}
      onBlur={() => {
        combobox.closeDropdown();
        setSearch(selectedValue || '');
      }}
      rightSection={disableChevron ? null : <Combobox.Chevron />}
      rightSectionPointerEvents="none"
      leftSection={leftIcon}
      leftSectionPointerEvents="none"
      pointer
      styles={inputStyles}
      classNames={classes}
    />
  ) : (
    <InputBase
      component="button"
      type="button"
      onClick={() => combobox.toggleDropdown()}
      rightSection={disableChevron ? null : <Combobox.Chevron />}
      rightSectionPointerEvents="none"
      leftSection={leftIcon}
      leftSectionPointerEvents="none"
      pointer
      styles={inputStyles}
      classNames={classes}
    >
      {selectedValue || <Input.Placeholder>{placeholder}</Input.Placeholder>}
    </InputBase>
  );

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(newValue) => {
        const label = options.find(({ value }) => value === newValue)?.label || '';
        setSelectedValue(label);
        setSearch(label);
        onChange(newValue);
        combobox.closeDropdown();
      }}
      styles={{
        dropdown: {
          zIndex: 1000,
        },
      }}
      offset={{ mainAxis: 2, crossAxis: -4 }}
      classNames={classes}
      position="bottom-start"
    >
      <Tooltip position="right" label={selectedValue} disabled={!enableTooltip || !selectedValue} variant="white">
        <Combobox.Target>{input}</Combobox.Target>
      </Tooltip>

      <Combobox.Dropdown>
        {comboboxOptions.length ? (
          <Combobox.Options
            mah={200}
            style={{
              overflowY: 'auto',
              padding: '24px 16px',
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
            }}
          >
            {comboboxOptions}
          </Combobox.Options>
        ) : (
          <Combobox.Empty>Nothing found...</Combobox.Empty>
        )}
      </Combobox.Dropdown>
    </Combobox>
  );
}
