import * as Sentry from '@sentry/browser';
import { Dispatch, useCallback } from 'react';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { ExternalBoard, ExternalProject } from '../../../api/projects-client/projects-client.type';
import { UserInfo } from '../../../api/user-client/user-client.type';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { trackEvent } from '../../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../../helpers/analytics-event/analytics-event.type';
import { useBasicAuth, useHandleErrors, useRedirect } from '../integrations.hooks';
import { ProjectView } from '../views/project-view';
import { useOauth } from './jira.hooks';
import { JiraReducerAction, JiraState } from './jira.type';

type ProjectsProps = {
  state: JiraState;
  dispatch: Dispatch<JiraReducerAction>;
  user: UserInfo | null;
};

/** The view for selecting projects during the jira integration workflow.
 *
 * @param props: { state: JiraState, dispatch: Dispatch<JiraReducerAction> }
 * @returns JSX.Element
 */
export function Projects({ state, dispatch, user }: ProjectsProps) {
  const { redirect } = useRedirect();

  useHandleErrors();

  const onSuccess = useCallback(
    (data: Record<string, unknown>) => {
      const { boards, projects, system_access_id } = data as {
        boards: ExternalBoard[];
        projects: ExternalProject[];
        system_access_id: string;
      };
      trackEvent(AnalyticsEventType.JiraProjectsViewed, { userContext: user });
      dispatch({
        type: 'options',
        payload: {
          allBoards: boards.sort((a: ExternalBoard, b: ExternalBoard) => a.name.localeCompare(b.name)),
          projects: projects.sort((a: ExternalProject, b: ExternalProject) => a.name.localeCompare(b.name)),
          systemAccessId: system_access_id,
        },
      });
      dispatch({ type: 'init' });
    },
    [dispatch, user],
  );

  const { completeOauthQuery } = useOauth({ state, onSuccess });
  useBasicAuth({ service: ExternalService.Jira, onSuccess });

  /** Handles the change of the project selection.
   * Filters the boards options to only show the boards for the selected project.
   *
   * @param project: ExternalProject - the selected project
   */
  const handleChange = useCallback(
    (projectId: string) => {
      try {
        const project = (state.options?.projects || []).find((p) => p.id === projectId);
        const filteredBoards = state.options?.allBoards.filter(
          (b) => !b.location || Number(b.location.projectId) === Number(projectId),
        );
        dispatch({ type: 'options', payload: { boards: filteredBoards } });
        dispatch({ type: 'project', payload: project });
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [state.options?.allBoards, state.options?.projects, dispatch],
  );

  return (
    <>
      <BrandedLoadingOverlay
        visible={!state.options?.projects?.length && completeOauthQuery.isPending}
        variant="colored"
      />
      {!redirect && (
        <ProjectView
          projects={state.options?.projects || []}
          selectedProjectId={state.project?.id || null}
          onChange={handleChange}
        />
      )}
    </>
  );
}
