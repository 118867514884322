const reactQueryConfig = {
  defaultOptions: {
    queries: {
      // Number of retry attempts for failed queries, value from env variable
      retry: parseInt(import.meta.env.REACT_QUERY_RETRY_TIMES as string),

      // Duration (5min) during which cached data is considered fresh
      // During this time, React Query will return cached data without making network requests
      staleTime: 1000 * 60 * 5,

      // Duration (15min) that inactive/unused data is kept in cache before garbage collection
      gcTime: 1000 * 60 * 15,

      // Disable automatic refetching when window regains focus
      refetchOnWindowFocus: false,
    },
  },
};

export { reactQueryConfig };
