import { QueryObserverResult, useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useGlobalStore } from '../../store/global-store/global-store';
import { EntityType } from '../common/types';
import { MinifiedTask } from '../tasks-client/task-client.type';
import {
  getAvailableMeasures,
  getMeasureDetail,
  getTimeAllocations,
  getWorkPeriods,
  getWorkPeriodsMeasure,
  getWorkPeriodsTasks,
} from './work-periods-client';
import { sanitizeMeasureDataResponse } from './work-periods-client.helpers';
import {
  AvailableMeasuresResponse,
  GetAvailableMeasuresPayload,
  GetTimeAllocationsPayload,
  GetWorkPeriodsMeasurePayload,
  GetWorkPeriodsPayload,
  MeasureDataByEntity,
  MeasureDataResponse,
  MeasureDetail,
  TimeAllocation,
  UseWorkPeriodsMeasurePayloadMultiple,
  UseWorkPeriodsMeasurePayloadSingular,
  WorkPeriod,
} from './work-periods-client.type';

/**
 * Hook to fetch available measures for a given scope and portfolio/project/subproject
 *
 * @param {Object} params - The parameters object
 * @param {GetAvailableMeasuresPayload} params.payload - Payload containing scope and ID parameters
 * @param {UseQueryOptions<AvailableMeasuresResponse>} [params.options] - Optional React Query options
 * @returns {Object} Object containing:
 *   - data: The available measures response data
 *   - query: The React Query result object
 */
const useAvailableMeasures = ({
  payload,
  options,
}: {
  payload: GetAvailableMeasuresPayload;
  options?: Partial<UseQueryOptions<AvailableMeasuresResponse>>;
}): { data: AvailableMeasuresResponse | undefined; query: QueryObserverResult<AvailableMeasuresResponse> } => {
  const organizationId = useGlobalStore((state) => state.organization?.id);
  const query = useQuery({
    queryKey: [
      'available-measures',
      payload.scope,
      payload.portfolio_id,
      payload.project_id,
      payload.subproject_id,
      payload.work_period_id,
      organizationId,
    ] as const,
    queryFn: () => getAvailableMeasures({ ...payload, organization_id: organizationId }),
    enabled: !!organizationId,
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Hook to fetch time allocations for a given date range and allocation type
 *
 * @param {Object} params - The parameters object
 * @param {GetTimeAllocationsPayload} params.payload - Payload containing start date, end date and allocation type
 * @param {UseQueryOptions<TimeAllocation[]>} [params.options] - Optional React Query options
 * @returns {Object} Object containing:
 *   - data: Array of time allocation data
 *   - query: The React Query result object
 */
const useTimeAllocations = ({
  payload,
  options,
}: {
  payload: GetTimeAllocationsPayload;
  options?: Partial<UseQueryOptions<TimeAllocation[]>>;
}): { data: TimeAllocation[] | undefined; query: QueryObserverResult<TimeAllocation[]> } => {
  const query = useQuery({
    queryKey: ['time-allocations', payload.start_date, payload.end_date, payload.time_allocation_type] as const,
    queryFn: () => getTimeAllocations(payload),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Hook to fetch work periods based on provided parameters
 *
 * @param {Object} params - The parameters object
 * @param {GetWorkPeriodsPayload} params.payload - Payload containing filters like subproject_id, parent, external_id, etc
 * @param {UseQueryOptions<WorkPeriod[]>} [params.options] - Optional React Query options
 * @returns {Object} Object containing:
 *   - data: Array of work period data
 *   - query: The React Query result object
 */
const useWorkPeriods = ({
  payload,
  options,
}: {
  payload: GetWorkPeriodsPayload;
  options?: Partial<UseQueryOptions<WorkPeriod[]>>;
}): { data: WorkPeriod[] | undefined; query: QueryObserverResult<WorkPeriod[]> } => {
  const query = useQuery({
    queryKey: [
      'work-periods',
      payload.subproject_id,
      payload.parent,
      payload.external_id,
      payload.time_span,
      payload.time_tense,
    ] as const,
    queryFn: () => getWorkPeriods(payload),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Hook to fetch work period measures for multiple entities
 *
 * @param {Object} params - The parameters object
 * @param {UseWorkPeriodsMeasurePayloadMultiple} params.payload - Payload containing entity type, ids, dates, measure name etc
 * @param {UseQueryOptions<MeasureDataResponse>} [params.options] - Optional React Query options
 * @returns {Object} Object containing:
 *   - data: Measure data grouped by entity
 *   - isFetching: Boolean indicating if any queries are still fetching
 *   - queries: Array of React Query result objects
 */
const useWorkPeriodsMeasureMultiple = ({
  payload,
  options,
}: {
  payload: UseWorkPeriodsMeasurePayloadMultiple;
  options?: Partial<UseQueryOptions<MeasureDataResponse>>;
}): {
  data: MeasureDataByEntity | undefined;
  isFetching: boolean;
  queries: QueryObserverResult<MeasureDataResponse>[];
} => {
  const { entity, ids, ...params } = payload;

  const entityParams: Record<EntityType, string> = {
    [EntityType.Portfolio]: 'portfolio_id',
    [EntityType.Project]: 'project_id',
    [EntityType.Subproject]: 'subproject_id',
    [EntityType.Task]: 'task_id',
    [EntityType.Sprint]: 'sprint_id',
    [EntityType.Team]: 'team_id',
  };

  const queryOptions = {
    staleTime: 1000 * 60 * 5,
    ...options,
  };

  const queries = useQueries({
    queries: ids.map((id) => ({
      queryKey: [
        'work-periods-measure-multiple',
        payload.entity,
        id,
        payload.start_date,
        payload.end_date,
        payload.measure_name,
        payload.time_allocation_type,
        payload.transformer,
        payload.force_recalculation,
      ] as const,
      queryFn: () =>
        getWorkPeriodsMeasure({
          ...params,
          [entityParams[payload.entity as keyof typeof entityParams]]: id,
        }) as Promise<MeasureDataResponse>,
      ...queryOptions,
    })),
  });

  const measuresData = queries.reduce<MeasureDataByEntity>((acc, query, index) => {
    return {
      ...acc,
      [ids[index]]: sanitizeMeasureDataResponse(query.data as MeasureDataResponse, payload.measure_name),
    };
  }, {} as MeasureDataByEntity);

  const isFetching = queries.some((query) => query.isFetching);

  return { data: measuresData, isFetching, queries };
};

/**
 * Hook for fetching a single work period measure
 *
 * @template T The expected return type of the measure data
 * @param {Object} params The hook parameters
 * @param {UseWorkPeriodsMeasurePayloadSingular} params.payload The payload containing measure parameters
 * @param {Partial<UseQueryOptions<T>>} [params.options] Optional react-query options
 * @returns {Object} Object containing the measure data and query result
 */
const useWorkPeriodsMeasureSingular = <T>({
  payload,
  options,
}: {
  payload: UseWorkPeriodsMeasurePayloadSingular;
  options?: Partial<UseQueryOptions<T>>;
}): {
  data: T | undefined;
  query: QueryObserverResult<T>;
} => {
  const query = useQuery({
    queryKey: ['work-periods-measure-singular', ...Object.values(payload)] as const,
    queryFn: () => getWorkPeriodsMeasure<T>(payload),
    staleTime: 1000 * 60 * 5, // 5 minutes
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Hook for fetching tasks for a single work period
 *
 * @param {Object} params The hook parameters
 * @param {GetWorkPeriodsMeasurePayload} params.payload The payload containing work period parameters
 * @param {Partial<UseQueryOptions<MinifiedTask[]>>} [params.options] Optional react-query options
 * @returns {Object} Object containing the tasks data and query result
 */
const useWorkPeriodsTasksSingular = ({
  payload,
  options,
}: {
  payload: GetWorkPeriodsMeasurePayload;
  options?: Partial<UseQueryOptions<MinifiedTask[]>>;
}): {
  data: MinifiedTask[] | undefined;
  query: QueryObserverResult<MinifiedTask[]>;
} => {
  const query = useQuery({
    queryKey: ['work-periods-tasks-singular', ...Object.values(payload)] as const,
    queryFn: () => getWorkPeriodsTasks(payload),
    staleTime: 1000 * 60 * 5,
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Hook for fetching the details of a given measure such as
 * description, calculation, units etc..,
 * @param {string} measureName name of the measure to fetch
 * @returns {Object} Object containing the measure details
 * data and query result
 */
const useMeasureDetail = ({
  measureName,
  options,
}: {
  measureName: string;
  options?: Partial<UseQueryOptions<MeasureDetail>>;
}): { data: MeasureDetail | undefined; query: QueryObserverResult<MeasureDetail> } => {
  const query = useQuery({
    queryKey: ['measure-detail', measureName],
    queryFn: () => getMeasureDetail(measureName),
    ...options,
  });

  return { data: query.data, query };
};

export {
  useAvailableMeasures,
  useMeasureDetail,
  useTimeAllocations,
  useWorkPeriods,
  useWorkPeriodsMeasureMultiple,
  useWorkPeriodsMeasureSingular,
  useWorkPeriodsTasksSingular,
};
