import { styled } from '@linaria/react';
import { Switch, TextInput, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { Portfolio } from '../../../../api/portfolio-client/portfolio-client.type';
import { ProjectsResponse } from '../../../../api/projects-client/projects-client.type';
import { primaryBase, secondaryBase, skyDark } from '../../../../styles/design-tokens';
import { Divider } from '../../../../ui-library/divider/divider';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { DataManagementDropShadowContainer } from '../data-management-header';
import { usePortfolioProjects, usePortfolioSettings } from './portfolios.hooks';

type EditPortfolioPanelProps = {
  allProjects: ProjectsResponse[];
  portfolioProjects: ProjectsResponse[];
  setIsEditingPortfolio: (isEditing: boolean) => void;
  portfolio: Portfolio;
};

export function EditPortfolioPanel({
  allProjects,
  portfolioProjects,
  setIsEditingPortfolio,
  portfolio,
}: EditPortfolioPanelProps) {
  const [search, setSearch] = useState('');
  const { addProjectToPortfolio, removeProjectFromPortfolio, isUpdating } = usePortfolioProjects();
  const { updatePortfolioSettings, isUpdating: isUpdatingSettings } = usePortfolioSettings();

  const handleProjectToggle = async (project: ProjectsResponse) => {
    const isSelected = portfolioProjects.some((p) => p.id === project.id);
    if (isSelected) {
      await removeProjectFromPortfolio(portfolio, project.id);
    } else {
      await addProjectToPortfolio(portfolio, project.id);
    }
  };

  const handleEndDateToggle = async (checked: boolean) => {
    await updatePortfolioSettings(portfolio, { use_end_date: checked });
  };

  const filteredProjects = allProjects.filter((project) =>
    project.name.toLowerCase().includes(search.toLowerCase().trim()),
  );

  return (
    <AddProjectPanelContainer>
      <HeaderContainer>
        <Text weight="bold" size="large">
          Edit Portfolio
        </Text>
        <Icon
          size={24}
          name="close"
          color={skyDark}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setIsEditingPortfolio(false);
          }}
        />
      </HeaderContainer>
      <Divider style={{ marginTop: 30, marginBottom: 12 }} />
      <SettingsContainer>
        <Switch
          label="Is Default"
          checked={portfolio.is_default}
          disabled={true}
          style={{ marginBottom: 16 }}
          color={secondaryBase}
        />
        <Tooltip
          label={
            portfolio.use_end_date
              ? 'Initiatives in this portfolio will use their end dates to determine if they are active'
              : 'Initiatives in this portfolio will not use end dates, and will use an algorithm to determine if they are active'
          }
          position="right"
          multiline
          w={300}
        >
          <div style={{ cursor: isUpdatingSettings ? 'not-allowed' : 'pointer' }}>
            <Switch
              label="Use End Dates"
              checked={portfolio.use_end_date}
              onChange={(event) => handleEndDateToggle(event.currentTarget.checked)}
              disabled={isUpdatingSettings}
              style={{ marginBottom: 16 }}
              color={secondaryBase}
            />
          </div>
        </Tooltip>
      </SettingsContainer>
      <Divider style={{ marginBottom: 12 }} />
      <TextInput
        placeholder="Search for Project"
        value={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
        leftSection={<Icon name="search" size={16} color={skyDark} />}
        style={{ width: 250 }}
        radius="xl"
        size="xs"
      />
      <Divider style={{ marginTop: 12, marginBottom: 8 }} />
      <ProjectListContainer>
        {filteredProjects.map((project) => {
          const isSelected = portfolioProjects.some((p) => p.id === project.id);
          return (
            <ProjectRowContainer key={project.id}>
              <Text>{project.name}</Text>
              <Icon
                name={isSelected ? 'check_circle' : 'add_circle_outline'}
                size={20}
                color={isSelected ? secondaryBase : primaryBase}
                style={{
                  cursor: isUpdating ? 'not-allowed' : 'pointer',
                  opacity: isUpdating ? 0.5 : 1,
                  marginRight: 16,
                }}
                onClick={() => !isUpdating && handleProjectToggle(project)}
              />
            </ProjectRowContainer>
          );
        })}
      </ProjectListContainer>
    </AddProjectPanelContainer>
  );
}

const AddProjectPanelContainer = styled(DataManagementDropShadowContainer)`
  max-width: 285px;
`;

const ProjectListContainer = styled.div`
  overflow-y: auto;
  scrollbar-width: 5px;
  margin-right: -5px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ProjectRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0px;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
