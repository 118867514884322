import { Divider as MantineDivider } from '@mantine/core';
import { inkBase, skyLight } from '../../styles/design-tokens';

type Props = {
  variant?: 'dark' | 'light';
  color?: string;
  style?: React.CSSProperties;
  orientation?: 'horizontal' | 'vertical';
};

export const Divider = ({ variant = 'light', color, style, orientation = 'horizontal', ...props }: Props) => {
  const dividerColor = variant === 'light' ? skyLight : inkBase;
  const finalColor = color || dividerColor;

  return <MantineDivider color={finalColor} style={{ width: '100%', ...style }} {...props} />;
};
