import { useQuery } from '@tanstack/react-query';
import { ReactNode, createContext, useContext, useState } from 'react';
import { canUseAI } from '../../api/agents-client/agent-client';
import { UseAIResult } from '../../api/agents-client/agent-client.class';
import { useOrganizationId } from '../../helpers/auth-helpers/auth.hooks';
import { setUseAI as setUseAIInGlobalStore } from '../../store/global-store/global-store.actions';

type FloraContextType = {
  isFloraVisible: boolean;
  setIsFloraVisible: (isVisible: boolean) => void;
  toggleFlora: () => void;
  askQuestion: (question: string) => void;
  currentExternalQuestion: string | null;
  setCurrentExternalQuestion: (question: string | null) => void;
};

const FloraContext = createContext<FloraContextType | undefined>(undefined);

export const FloraProvider = ({ children }: { children: ReactNode }) => {
  const orgId = useOrganizationId();
  const [isFloraVisible, setIsFloraVisible] = useState(false);
  const [currentExternalQuestion, setCurrentExternalQuestion] = useState<string | null>(null);
  const toggleFlora = () => {
    setIsFloraVisible(!isFloraVisible);
  };

  const askQuestion = (question: string) => {
    setIsFloraVisible(true);
    setCurrentExternalQuestion(question);
  };

  useQuery<UseAIResult>({
    queryKey: ['canUseAI', orgId] as const,
    queryFn: async () => {
      try {
        const data = await (orgId ? canUseAI(orgId) : Promise.resolve({ canUseAI: false }));
        if (data) {
          setUseAIInGlobalStore(data.canUseAI || false);
        }
        return data;
      } catch (error) {
        console.error('There was an issue determining if the user can use AI:', error);
        throw error;
      }
    },
    enabled: true,
    staleTime: 1000 * 60 * 5,
  });

  return (
    <FloraContext.Provider
      value={{
        isFloraVisible,
        setIsFloraVisible,
        toggleFlora,
        askQuestion,
        currentExternalQuestion,
        setCurrentExternalQuestion,
      }}
    >
      {children}
    </FloraContext.Provider>
  );
};

export const useFlora = () => {
  const context = useContext(FloraContext);
  if (context === undefined) {
    throw new Error('useFlora must be used within a FloraProvider');
  }
  return context;
};
