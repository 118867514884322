import { useQueries } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { getWorkPeriodsMeasure } from '../../../api/work-periods-client/work-periods-client';
import { useAvailableMeasures } from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  MeasureMetadata,
  ModuleType,
  Transformer,
  WorkPeriod,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';

/**
 * Custom hook to fetch and process data for sprint comparison view.
 *
 * @returns {object} An object containing:
 * @property {MeasureMetadata[]} customMeasures - Array of available custom measures
 * @property {Record<string, any>} measureData - Object mapping work period IDs to their measure data
 * @property {boolean} isFetching - Whether any queries are currently fetching
 * @property {WorkPeriod[]} workPeriods - Array of selected work periods
 * @property {boolean} hasWorkPeriods - Whether any work periods are selected
 *
 * This hook:
 * 1. Gets selected work periods from the work period form
 * 2. Fetches available measures for the first selected work period
 * 3. Filters for custom measures
 * 4. Makes parallel queries to fetch measure data for each selected work period
 * 5. Combines the results into a single object mapping work period IDs to their data
 */

const useSprintComparisonViewData = () => {
  const workPeriods = useProcessAnalysisStore.getState().workPeriods;
  const selectedWorkPeriods = useProcessAnalysisStore.getState().selectedWorkPeriods;
  const hasWorkPeriods = selectedWorkPeriods.length > 0;

  const { data: availableMeasuresResponse } = useAvailableMeasures({
    payload: {
      scope: ModuleType.WorkPeriod,
      work_period_id: hasWorkPeriods ? selectedWorkPeriods[0].id : '',
    },
    options: {
      enabled: hasWorkPeriods,
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 10000),
    },
  });

  const availableMeasures = availableMeasuresResponse?.work_period?.measures || [];
  const customMeasures = availableMeasures.filter((measure: MeasureMetadata) => measure.is_custom);
  const measureNames = customMeasures.map((measure) => measure.measure_name);

  // Batch both work periods and measures
  const workPeriodBatchSize = 3;
  const measureBatchSize = 3;

  // Batch work periods
  const batchedWorkPeriods = selectedWorkPeriods.reduce((acc, item, index) => {
    const batchIndex = Math.floor(index / workPeriodBatchSize);
    if (!acc[batchIndex]) {
      acc[batchIndex] = [];
    }
    acc[batchIndex].push(item);
    return acc;
  }, [] as WorkPeriod[][]);

  // Batch measures
  const batchedMeasures = measureNames.reduce((acc, item, index) => {
    const batchIndex = Math.floor(index / measureBatchSize);
    if (!acc[batchIndex]) {
      acc[batchIndex] = [];
    }
    acc[batchIndex].push(item);
    return acc;
  }, [] as string[][]);

  const queries = useQueries({
    queries: batchedWorkPeriods.flatMap((workPeriodBatch) =>
      workPeriodBatch.flatMap((workPeriod) =>
        batchedMeasures.map((measureBatch) => ({
          queryKey: ['work-periods-measure', workPeriod.id, measureBatch, workPeriods],
          queryFn: () =>
            getWorkPeriodsMeasure({
              work_period_id: workPeriod.id,
              start_date: dayjs(workPeriod.start_date).format('YYYY-MM-DD'),
              end_date: dayjs(workPeriod.end_date).format('YYYY-MM-DD'),
              measure_name: measureBatch,
              is_custom: true,
              transformer: Transformer.ReturnFirstMetricOnly,
            }),
          enabled: hasWorkPeriods && measureNames.length > 0,
          retry: 3,
          retryDelay: (attemptIndex: number) => Math.min(1000 * 2 ** attemptIndex, 10000),
          gcTime: 30000,
          staleTime: 30000,
          cacheTime: 5 * 60 * 1000,
        })),
      ),
    ),
  });

  const isFetching = queries.some((query) => query.isFetching);
  const measureData = queries.reduce(
    (acc, query, index) => {
      if (query.data) {
        // Calculate which work period this query belongs to
        const workPeriodIndex = batchedMeasures.length === 0 ? 0 : Math.floor(index / batchedMeasures.length);
        const workPeriod = selectedWorkPeriods[workPeriodIndex];

        // Initialize the work period data if it doesn't exist
        if (!acc[workPeriod.id]) {
          acc[workPeriod.id] = {};
        }

        // Merge the measure data into the existing work period data
        acc[workPeriod.id] = { ...acc[workPeriod.id], ...query.data };
      }
      return acc;
    },
    {} as Record<string, any>,
  );

  return {
    customMeasures,
    measureData,
    isFetching,
    workPeriods: selectedWorkPeriods,
    hasWorkPeriods,
  };
};

export { useSprintComparisonViewData };
