/**
 * Downloads data as a CSV file
 * @param data Array of arrays representing CSV rows (including header)
 * @param filename Name of the file to download
 */
const downloadCSV = (data: (string | number)[][], filename: string) => {
  const csvContent = data
    .map((row) =>
      row
        .map((cell) => {
          // Handle cells that might contain commas or quotes
          const cellStr = String(cell);
          if (cellStr.includes(',') || cellStr.includes('"') || cellStr.includes('\n')) {
            return `"${cellStr.replace(/"/g, '""')}"`;
          }
          return cellStr;
        })
        .join(','),
    )
    .join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export { downloadCSV };
