import { Box, Button, Card, Divider, Flex, Group, Menu, Text, Title } from '@mantine/core';
import React from 'react';
import { Page } from '../../../api/pages-client/pages-client.type';
import { newCOLORS } from '../../../styles/colors';
import { Icon } from '../../../ui-library/icon/icon';

interface DashboardHeaderProps {
  selectedDashboard: string;
  onDashboardChange: (dashboardId: string) => void;
  onCreateDashboard: () => void;
  onDeleteDashboard: (dashboardId: string) => void;
  dashboards: Page[];
}

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  selectedDashboard,
  onDashboardChange,
  onCreateDashboard,
  onDeleteDashboard,
  dashboards,
}) => {
  const selectedDashboardData = dashboards.find((d) => d.id === selectedDashboard);

  return (
    <Flex align="center" gap="md" mb="lg">
      <Group gap={8} style={{ marginTop: '1.5rem', marginLeft: '1rem' }}>
        <Icon name="dashboard" size={20} style={{ color: newCOLORS.black, fontSize: '2.0rem' }} />
        <Title order={4} style={{ margin: 0, fontSize: '2.0rem', color: newCOLORS.black }}>
          {selectedDashboardData?.name || 'Select Dashboard'}
        </Title>
        <Menu shadow="md" width={400} position="bottom-start">
          <Menu.Target>
            <Button variant="subtle" size="sm" px={4} style={{ color: 'inherit' }}>
              <Icon name="expand_circle_down" size={25} style={{ color: newCOLORS.black }} />
            </Button>
          </Menu.Target>
          <Menu.Dropdown p="large" style={{ padding: '1rem', marginTop: '.25rem', width: '35%', left: '310px' }}>
            <Flex direction="column" gap="sm" style={{ padding: '1rem', marginTop: '1rem' }}>
              {dashboards.map((dashboard) => (
                <Card
                  key={dashboard.id}
                  withBorder
                  padding="md"
                  radius="md"
                  onClick={() => dashboard.id && onDashboardChange(dashboard.id)}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: dashboard.id === selectedDashboard ? newCOLORS.lighterPurple : undefined,
                    borderColor: dashboard.id === selectedDashboard ? newCOLORS.darkPurple : undefined,
                  }}
                >
                  <Flex align="center" justify="space-between">
                    <Flex align="center" gap={8}>
                      <Box
                        style={{
                          width: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Icon
                          name="check"
                          size={16}
                          style={{
                            color: dashboard.id === selectedDashboard ? newCOLORS.darkPurple : 'transparent',
                            visibility: dashboard.id === selectedDashboard ? 'visible' : 'hidden',
                          }}
                        />
                      </Box>
                      <div>
                        <Text fw={500}>{dashboard.name}</Text>
                        <Text size="sm" c="dimmed" mt={4}>
                          {dashboard.description}
                        </Text>
                      </div>
                    </Flex>
                    {dashboards.length > 1 && (
                      <Button
                        variant="subtle"
                        color="red"
                        size="sm"
                        px={4}
                        onClick={(e) => {
                          e.stopPropagation();
                          dashboard.id && onDeleteDashboard(dashboard.id);
                        }}
                        style={{
                          visibility: dashboard.id === selectedDashboard ? 'visible' : 'hidden',
                        }}
                      >
                        <Icon name="delete" size={20} style={{ color: newCOLORS.red }} />
                      </Button>
                    )}
                  </Flex>
                </Card>
              ))}
              <Divider my="sm" />
              <Button
                variant="outline"
                rightSection={<Icon name="add" size={16} />}
                onClick={(e) => {
                  e.stopPropagation();
                  onCreateDashboard();
                }}
                fullWidth
              >
                Create New Dashboard
              </Button>
            </Flex>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Flex>
  );
};
