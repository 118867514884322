import { styled } from '@linaria/react';
import { IntegrationEntry, IntegrationType } from '../../../../api/integrations-client/integrations-client.type';
import { primaryBase, redBase } from '../../../../styles/design-tokens';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { formatDatetimeToLocal } from '../data-management.helpers';
import { DataSourceTableRow } from './data-sources-panel.types';
import { isUpToDate } from './data-sources.helpers';

/**
 * Returns a styled icon component indicating whether data is up to date
 * @param {boolean} upToDate - Boolean indicating if the data source is up to date
 * @returns {JSX.Element} - Icon component with check or cancel icon in appropriate color
 */

const getUpToDateIcon = (upToDate: boolean): JSX.Element => {
  return (
    <Icon name={upToDate ? 'check_circle_outlined' : 'cancel'} size={24} color={upToDate ? primaryBase : redBase} />
  );
};

/**
 * Creates a table row for each integration type
 * @param {IntegrationEntry[]} integrationEntries - Array of integration entries
 * @param {Set<string>} expandedRows - Set of expanded rows
 * @returns {Record<IntegrationType, DataSourceTableRow[]>} - Object with integration type as key and table rows as value
 */
export const createIntegrationTypeData = (
  integrationEntries: IntegrationEntry[],
  expandedRows: Set<string>,
): Record<IntegrationType, DataSourceTableRow[]> => {
  return integrationEntries.reduce(
    (acc, entry) => {
      const tableRows = entry.projects.map((project) => ({
        id: project.id,
        projectName: project.name,
        totalBoards: project.subproject_ids.length,
        owner: entry.user_name || 'N/A',
        lastUpdated: entry.last_completed_at ? formatDatetimeToLocal(entry.last_completed_at) : 'N/A',
        upToDate: getUpToDateIcon(isUpToDate(entry.last_completed_at)),
        boards: entry.subprojects.filter((sub) => project.subproject_ids.includes(sub.id)),
        isExpanded: expandedRows.has(project.id),
        searchableText: project.name.toLowerCase(),
      }));

      if (!acc[entry.integration_type]) {
        acc[entry.integration_type] = [];
      }
      acc[entry.integration_type].push(...tableRows);
      return acc;
    },
    {} as Record<IntegrationType, DataSourceTableRow[]>,
  );
};

/**
 * Filters table data based on search term
 * @param {DataSourceTableRow[]} data - Array of table rows
 * @param {string} search - Search term
 * @returns {DataSourceTableRow[]} - Filtered array of table rows
 */
export const filterTableData = (data: DataSourceTableRow[], search: string): DataSourceTableRow[] => {
  const tableData: DataSourceTableRow[] = [];
  data.forEach((project) => {
    const shouldIncludeProject =
      !search ||
      project.searchableText?.includes(search.toLowerCase()) ||
      project.boards.some((board) => board.name.toLowerCase().includes(search.toLowerCase()));

    if (shouldIncludeProject) {
      tableData.push(project);
      if (project.isExpanded) {
        project.boards.forEach((board) => {
          if (!search || board.name.toLowerCase().includes(search.toLowerCase())) {
            tableData.push({
              id: board.id,
              projectName: (
                <BoardRow>
                  <Text weight="medium">{board.name}</Text>
                </BoardRow>
              ),
              totalBoards: null,
              owner: null,
              lastUpdated: formatDatetimeToLocal(board.last_data_fetch),
              upToDate: getUpToDateIcon(isUpToDate(board.last_data_fetch)),
              isBoard: true,
              parentId: project.id,
              boards: [],
              searchableText: board.name.toLowerCase(),
            });
          }
        });
      }
    }
  });
  return tableData;
};

const BoardRow = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: -1px; // Remove the gap created by the border
`;
