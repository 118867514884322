import { newCOLORS } from '../../styles/colors';

type ChartType = 'line' | 'area' | 'bar' | 'stacked_bar' | 'pie';

const DATE_RANGES = [
  { value: 'last_7_days', label: 'Last 7 Days' },
  { value: 'last_14_days', label: 'Last 14 Days' },
  { value: 'last_30_days', label: 'Last 30 Days' },
  { value: 'last_60_days', label: 'Last 60 Days' },
  { value: 'last_90_days', label: 'Last 90 Days' },
  { value: 'custom', label: 'Custom' },
];

const OBJECT_TYPES = [
  { value: 'board', label: 'Board' },
  { value: 'project', label: 'Project' },
  { value: 'portfolio', label: 'Portfolio' },
];

const WIDGET_TYPES = [
  { value: 'table', label: 'Table' },
  { value: 'tile', label: 'Tile' },
  { value: 'line', label: 'Line Chart' },
  { value: 'area', label: 'Area Chart' },
  { value: 'bar', label: 'Bar Chart' },
  { value: 'stacked_bar', label: 'Stacked Bar Chart' },
  { value: 'pie', label: 'Pie Chart' },
];

const WIDGET_SIZES = [{ value: 'standard', label: 'Standard' }];

const COLORS = {
  primary: {
    stroke: newCOLORS.darkPurple,
    fill: `${newCOLORS.darkPurple}20`,
  },
  success: {
    stroke: newCOLORS.green,
    fill: `${newCOLORS.green}20`,
  },
  danger: {
    stroke: newCOLORS.red,
    fill: `${newCOLORS.red}20`,
  },
  warning: {
    stroke: newCOLORS.orange,
    fill: `${newCOLORS.orange}20`,
  },
  info: {
    stroke: newCOLORS.blue,
    fill: `${newCOLORS.blue}20`,
  },
  purple: {
    stroke: newCOLORS.purple,
    fill: `${newCOLORS.purple}20`,
  },
  pink: {
    stroke: newCOLORS.pink,
    fill: `${newCOLORS.pink}20`,
  },
  indigo: {
    stroke: newCOLORS.indigo,
    fill: `${newCOLORS.indigo}20`,
  },
  teal: {
    stroke: newCOLORS.teal,
    fill: `${newCOLORS.teal}20`,
  },
  yellow: {
    stroke: newCOLORS.yellow,
    fill: `${newCOLORS.yellow}20`,
  },
};

export { COLORS, DATE_RANGES, OBJECT_TYPES, WIDGET_SIZES, WIDGET_TYPES };

export type { ChartType };
