import { QueryObserverResult, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import {
  createPortfolio,
  deletePortfolio,
  fetchPortfolios,
  getInitiativeCompletion,
  getInitiativeFocus,
  getPortfolioStatuses,
  updatePortfolio,
} from './portfolio-client';
import {
  Portfolio,
  PortfolioStatusesByProjectsAndBoards,
  ProcessAnalysisInitiativeChartData,
} from './portfolio-client.type';

/**
 * Query manager for fetching a list of portfolios for the current user.
 *
 * @param options UseQueryOptions
 * @returns a object of portfolios along with the query manager
 */
const usePortfolios = (
  options?: Partial<UseQueryOptions<Portfolio[]>>,
): {
  portfolios: Portfolio[];
  query: QueryObserverResult<Portfolio[]>;
} => {
  const query = useQuery({
    queryKey: ['portfolios'],
    queryFn: fetchPortfolios,
    ...options,
  });

  let portfolios: Portfolio[] = [];
  if (Array.isArray(query.data) && query.data.length > 0) {
    portfolios = query.data.sort((a, b) => a.name.localeCompare(b.name));
  }

  return { portfolios: portfolios || [], query };
};

/**
 * Query manager for creating a portfolio.
 *
 * @returns Portfolio
 */
const useCreatePortfolio = () => {
  return useMutation({
    mutationFn: createPortfolio,
  });
};

const useUpdatePortfolio = () => {
  return useMutation({
    mutationFn: updatePortfolio,
  });
};

const useDeletePortfolio = () => {
  return useMutation({
    mutationFn: deletePortfolio,
  });
};

/**
 * Query manager for fetching a list of statuses for the given portfolio
 *
 * @param portfolioId portfolio id to fetch statuses for
 * @param options UseQueryOptions
 * @returns a nested object of statuses by projects and subprojects along with the query manager
 */
function usePortfolioStatusesByProjectsAndBoards(
  portfolioId: string | null,
  options?: UseQueryOptions<{ statuses_by_project_and_subproject: PortfolioStatusesByProjectsAndBoards }>,
): {
  statusesByProjectsAndBoards: PortfolioStatusesByProjectsAndBoards | undefined;
  query: QueryObserverResult<{ statuses_by_project_and_subproject: PortfolioStatusesByProjectsAndBoards }>;
} {
  const query = useQuery({
    queryKey: ['portfolioStatuses', portfolioId],
    queryFn: () => (portfolioId ? getPortfolioStatuses(portfolioId) : Promise.reject('Portfolio ID is required')),
    ...options,
  });

  return { statusesByProjectsAndBoards: query.data?.statuses_by_project_and_subproject, query };
}

/**
 * Query manager for fetching a list of initiative completions for the given portfolio
 *
 * @param portfolioId portfolio id to fetch initiative completions for
 * @param startDate start date to filter the initiative completions
 * @param endDate end date to filter the initiative completions
 * @param options UseQueryOptions
 * @returns a object of initiative completions along with the query manager
 */
const useInitiativeCompletions = (
  portfolioId: string,
  startDate: string,
  endDate: string,
  options?: UseQueryOptions<ProcessAnalysisInitiativeChartData>,
): {
  initiativeCompletions: ProcessAnalysisInitiativeChartData | undefined;
  query: QueryObserverResult<ProcessAnalysisInitiativeChartData>;
} => {
  const query = useQuery({
    queryKey: ['initiativeCompletions', portfolioId, startDate, endDate],
    queryFn: () => getInitiativeCompletion(portfolioId, startDate, endDate),
    ...options,
  });

  return { initiativeCompletions: query.data, query };
};

/**
 * Query manager for fetching a list of initiative focus for the given portfolio
 *
 * @param portfolioId portfolio id to fetch initiative focus for
 * @param startDate start date to filter the initiative focus
 * @param endDate end date to filter the initiative focus
 * @param options UseQueryOptions
 * @returns a object of initiative focus along with the query manager
 */
const useInitiativeFocus = (
  portfolioId: string,
  startDate: string,
  endDate: string,
  options?: UseQueryOptions<ProcessAnalysisInitiativeChartData>,
): {
  initiativeFocus: ProcessAnalysisInitiativeChartData | undefined;
  query: QueryObserverResult<ProcessAnalysisInitiativeChartData>;
} => {
  const query = useQuery({
    queryKey: ['initiativeFocus', portfolioId, startDate, endDate],
    queryFn: () => getInitiativeFocus(portfolioId, startDate, endDate),
    ...options,
  });

  return { initiativeFocus: query.data, query };
};

export {
  useCreatePortfolio,
  useDeletePortfolio,
  useInitiativeCompletions,
  useInitiativeFocus,
  usePortfolioStatusesByProjectsAndBoards,
  usePortfolios,
  useUpdatePortfolio,
};
