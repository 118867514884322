/**
 * Returns the appropriate icon name for a given widget type and subtype
 * @param type - The main widget type ('chart', 'table', 'tile', 'metric')
 * @param subType - Optional subtype for chart widgets ('line', 'area', 'bar', 'stacked-bar', 'pie')
 * @returns The icon name string to be used with the Icon component
 */

const getWidgetIcon = (type: string, subType?: string): string => {
  switch (type) {
    case 'chart':
      switch (subType) {
        case 'line':
          return 'show_chart';
        case 'area':
          return 'area_chart';
        case 'bar':
          return 'bar_chart';
        case 'stacked-bar':
          return 'stacked_bar_chart';
        case 'pie':
          return 'pie_chart';
        default:
          return 'analytics';
      }
    case 'table':
      return 'table_chart';
    case 'tile':
      return 'numbers';
    case 'metric':
      return 'speed';
    default:
      return 'dashboard';
  }
};

export { getWidgetIcon };
