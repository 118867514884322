import { styled } from '@linaria/react';

const EditGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2px 1fr 2px 2fr;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const GridItem = styled.div`
  height: 100%;
  overflow: hidden;
  padding: 1em;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const WorkflowsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin: 16px 0px 0px 0px;
`;

export { EditGridContainer, GridItem, HeaderContainer, WorkflowsContainer };
