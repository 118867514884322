import { AppShell } from '@mantine/core';
import { ReactNode, useContext } from 'react';
import { FloraAside } from '../flora/flora';
import { useFlora } from '../flora/flora.context';
import { HeaderBar } from '../header/header';
import { SideBar } from '../side-bar/side-bar';
import { SidebarContext } from '../side-bar/side-bar.context';

type MenuDrivenContainerProps = {
  children: ReactNode;
  header?: string | ReactNode;
};

export const MenuDrivenContainer = ({ children, header }: MenuDrivenContainerProps) => {
  const { sidebarOpen } = useContext(SidebarContext);
  const { isFloraVisible, toggleFlora } = useFlora();
  const navbarWidth = sidebarOpen ? 280 : 70;
  const floraWidth = 325;

  return (
    <AppShell
      layout="alt"
      header={{ height: 76 }}
      navbar={{
        width: navbarWidth,
        breakpoint: 'never',
        collapsed: { mobile: false, desktop: false },
      }}
      aside={{
        width: isFloraVisible ? floraWidth + 10 : 0,
        breakpoint: 'never',
      }}
      padding={16}
      withBorder={false}
      styles={{
        main: {
          transition: 'padding-right 0.3s ease',
        },
        aside: {
          transition: 'transform 0.3s ease',
          marginTop: '25px',
          height: 'calc(100vh - 50px)',
          marginRight: '10px',
          background: 'transparent',
          border: 'none',
        },
      }}
    >
      <HeaderBar header={header} />
      <SideBar />
      {isFloraVisible && (
        <AppShell.Aside w={floraWidth} style={{ zIndex: 500 }}>
          <FloraAside onClose={toggleFlora} />
        </AppShell.Aside>
      )}
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
};
