import { Integration } from '../../api/integrations-client/integrations-client.type';
import { useIntegrationsStore } from './integrations-store';
import { IntegrationConfig } from './integrations-store.types';

/**
 * Sets the integrations in the integrations store.
 *
 * @param {Integration[]} integrations - The integrations to set.
 * @return {void}
 */
const setIntegrations = (integrations: Integration[]): void => {
  useIntegrationsStore.setState({ integrations }, false, '[Integrations Store] setIntegrations');
};

/**
 * Sets the current integration in the integrations store.
 *
 * @param {Integration | null} integration - The integration to set, or null to clear
 * @return {void}
 */
const setIntegration = (integration: IntegrationConfig | null): void => {
  useIntegrationsStore.setState({ integration }, false, '[Integrations Store] setIntegration');
};

const updateIntegration = (partialIntegration: Partial<IntegrationConfig>): void => {
  const currentIntegration = useIntegrationsStore.getState().integration;

  if (currentIntegration) {
    useIntegrationsStore.setState(
      { integration: { ...currentIntegration, ...partialIntegration } },
      false,
      '[Integrations Store] updateIntegration',
    );
  }
};

export { setIntegration, setIntegrations, updateIntegration };
