import { dropTargetForElements, monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { styled } from '@linaria/react';
import { Group, Skeleton, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import { PortfolioStatusesByProjectsAndBoards } from '../../../../../api/portfolio-client/portfolio-client.type';
import { Mapping, Workflow } from '../../../../../api/workflows-client/workflows-client.type';
import { DropShadowCard } from '../../../../../components/drop-shadow-card/drop-shadow-card';
import {
  baseWhite,
  primaryBase,
  redBase,
  secondaryBase,
  skyDark,
  skyLighter,
  yellowBase,
} from '../../../../../styles/design-tokens';
import { Icon } from '../../../../../ui-library/icon/icon';
import { Text } from '../../../../../ui-library/typography/typography';
import {
  filterPortfolioStatusesByProjectsAndBoardsBySubprojectIds,
  getMappedAndUnmappedStatusesForWorkflow,
} from '../workflows.helpers';
import { WorkflowAction } from './reducer';
import { Status } from './statuses';

const BUCKETS = ['Backlog', 'In Progress', 'In Review', 'In Test', 'Deployable', 'Done'];

export function WorkflowConfiguration({
  state,
  dispatch,
  statusesByProjectsAndBoards,
}: {
  state: Workflow;
  dispatch: (action: WorkflowAction) => void;
  statusesByProjectsAndBoards: PortfolioStatusesByProjectsAndBoards | undefined;
}) {
  const statusesForWorkflowSubprojects = filterPortfolioStatusesByProjectsAndBoardsBySubprojectIds(
    statusesByProjectsAndBoards,
    state.subprojects,
  );
  const { mappedStatuses, unmappedStatuses } = getMappedAndUnmappedStatusesForWorkflow(
    state,
    statusesForWorkflowSubprojects,
  );

  useEffect(() => {
    return monitorForElements({
      onDrop: ({ source, location }) => {
        // If the drop target is not found, do not allow the drop
        if (!location.current.dropTargets[0]) {
          return;
        }

        // If the targeted step is not found in state, do not allow the drop
        const targetedStep = state.steps.find((step) => step.name === location.current.dropTargets[0].data.bucket);
        if (!targetedStep) {
          return;
        }

        // If the source status is already in the target bucket step, do not allow the drop to prevent duplicates
        if (targetedStep.mappings.some((mapping) => mapping.external_name === source.data.status)) {
          return;
        }

        const newMappings = [...targetedStep.mappings, { external_name: source.data.status as string } as Mapping];

        dispatch({
          type: 'SET_WORKFLOW',
          payload: {
            ...state,
            steps: [
              ...state.steps.filter((step) => step.name !== targetedStep.name),
              {
                ...targetedStep,
                mappings: newMappings,
              },
            ],
          },
        });
      },
    });
  }, [state, dispatch]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Stack>
        <Group justify="space-between" style={{ paddingBottom: '16px' }}>
          <Text size="large" weight="bold">
            Workflow Map
          </Text>
          {unmappedStatuses.length > 0 ? (
            <Text weight="bold" color={redBase}>{`Unmapped Statuses: (${unmappedStatuses.length})`}</Text>
          ) : mappedStatuses.length > 0 ? (
            <Group>
              <Text weight="bold" color={primaryBase}>
                Calibrated
              </Text>
              <Icon name="check_circle" size={24} color={primaryBase} />
            </Group>
          ) : null}
        </Group>
        <Group justify="space-between">
          <Text size="small" color={skyDark}>
            {mappedStatuses.length} Statuses Mapped
          </Text>
          {state.metadata_updated_at && (
            <Text size="small" color={skyDark}>
              Last Updated: {dayjs(state.metadata_updated_at).format('MMM D, YYYY [at] h:mm A')}
            </Text>
          )}
        </Group>
      </Stack>
      <div
        style={{
          flex: 1,
          display: 'flex',
          minHeight: 0,
          overflow: 'auto',
          padding: '16px 8px',
          backgroundColor: skyLighter,
        }}
      >
        <WorkflowContainer>
          {BUCKETS.map((bucket, index) => (
            <Bucket
              key={bucket}
              name={bucket}
              ordinalPosition={index === 0 ? 'first' : index === BUCKETS.length - 1 ? 'last' : 'internal'}
              state={state}
              dispatch={dispatch}
            >
              {(state.steps || [])
                .find((step) => step.name === bucket)
                ?.mappings.map((status) => (
                  <Status key={status.id} status={status.external_name} dispatch={dispatch} />
                ))}
            </Bucket>
          ))}
        </WorkflowContainer>
        <WorkflowContainer>
          <Bucket name="Waiting" ordinalPosition="waiting" state={state} dispatch={dispatch}>
            {(state.steps || [])
              .find((step) => step.name === 'Waiting')
              ?.mappings.map((status) => (
                <Status
                  key={status.id}
                  status={status.external_name}
                  dispatch={dispatch}
                  backgroundColor={yellowBase}
                />
              ))}
          </Bucket>
        </WorkflowContainer>
      </div>
    </div>
  );
}

function Bucket({
  name,
  ordinalPosition,
  children,
  state,
  dispatch,
}: {
  name: string;
  ordinalPosition: 'first' | 'internal' | 'last' | 'waiting';
  children: React.ReactNode;
  state: Workflow;
  dispatch: (action: WorkflowAction) => void;
}) {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    invariant(el);

    return dropTargetForElements({
      element: el,
      getData: () => ({ bucket: name }),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
    });
  }, [state, dispatch, name, ref]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <BucketIcon ordinalPosition={ordinalPosition} />
      <div style={{ height: '2px', width: '16px', backgroundColor: skyDark }} />
      {!['last', 'waiting'].includes(ordinalPosition) ? (
        <div
          style={{
            position: 'relative',
            top: '88px',
            right: '28px',
            height: '150px',
            width: '2px',
            borderLeft: `2px dashed ${secondaryBase}`,
          }}
        >
          <Icon
            name="keyboard_arrow_down"
            variant="filled"
            size={24}
            color={secondaryBase}
            style={{ position: 'relative', top: '140px', right: '13px' }}
          />
        </div>
      ) : null}
      <DropShadowCard
        ref={ref}
        style={{
          backgroundColor: baseWhite,
          border: isDraggedOver ? `2px solid ${primaryBase}` : 'none',
        }}
      >
        <BucketContainer>
          <Text weight="bold" style={{ alignSelf: 'start' }}>
            {name}
          </Text>
          <BucketChildren isDraggedOver={isDraggedOver}>{children}</BucketChildren>
        </BucketContainer>
      </DropShadowCard>
    </div>
  );
}

function BucketIcon({ ordinalPosition }: { ordinalPosition: 'first' | 'internal' | 'last' | 'waiting' }) {
  if (ordinalPosition === 'first') {
    return <Icon name="play_circle_outline" variant="filled" size={24} color={primaryBase} />;
  }
  if (ordinalPosition === 'last') {
    return <Icon name="check_circle_outline" variant="filled" size={24} color={secondaryBase} />;
  }
  if (ordinalPosition === 'waiting') {
    return <Icon name="pause_circle_outline" variant="filled" size={24} color={yellowBase} />;
  }
  return <Icon name="adjust" variant="filled" size={24} color={secondaryBase} />;
}

function BucketChildren({ children, isDraggedOver }: { children: React.ReactNode; isDraggedOver: boolean }) {
  if (isDraggedOver) {
    if (children && Array.isArray(children) && children.length) {
      return (
        <>
          {children}
          <Skeleton height={32} width={200} />
        </>
      );
    }
    return <Skeleton height={32} width={200} />;
  }
  if (children && Array.isArray(children) && children.length) {
    return children;
  }
  return <EmptyState>Empty</EmptyState>;
}

const BucketContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 100px;
  min-width: 150px;
  padding: 8px;
`;

const EmptyState = styled.div`
  padding: 4px 8px;
  border: 1px dashed ${skyDark};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
`;

const WorkflowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  background-color: ${skyLighter};
  padding: 8px;
  height: fit-content;
`;
