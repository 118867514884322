import { styled } from '@linaria/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIntegrationEntries } from '../../../../api/integrations-client/integrations-client';
import { useIntegrationEntries } from '../../../../api/integrations-client/integrations-client.hooks';
import { IntegrationEntry, IntegrationType } from '../../../../api/integrations-client/integrations-client.type';
import { usePortfolios } from '../../../../api/portfolio-client/portfolio-client.hooks';
import { useProjects } from '../../../../api/projects-client/projects-client.hooks';
import { BrandedLoadingOverlay } from '../../../../components/loader/branded-loader';
import { useOrganizationId } from '../../../../helpers/auth-helpers/auth.hooks';
import { useRepositories } from '../../data-management-client.hooks';
import { ProjectInfoPanel } from '../project-info-panel';
import { SubprojectInfoPanel } from '../subproject-info-panel';
import { DataSourcesList } from './data-sources-list';
import { DataSourcesPanel } from './data-sources-panel';

export function DataSources() {
  const navigate = useNavigate();

  const organizationId = useOrganizationId();
  const { portfolios } = usePortfolios({
    queryKey: ['portfolios'] as const,
  });
  const allPortfolios = portfolios.filter((portfolio) => portfolio.organization.id === organizationId);
  const { projects } = useProjects({
    queryKey: ['projects', organizationId] as const,
    organizationId,
  });

  const { data: integrationEntries = [], query } = useIntegrationEntries<IntegrationEntry[]>({
    queryKey: ['integration-entries', organizationId] as const,
    queryFn: async () => {
      if (!organizationId) {
        throw new Error('Organization ID is required');
      }
      return getIntegrationEntries(organizationId);
    },
    select: (data) => data.filter((integration) => integration.projects.length > 0),
    enabled: !!organizationId,
    staleTime: 1000 * 60 * 5,
  });

  const { data: repositories = [] } = useRepositories({ organization_id: organizationId });

  const [selectedDataSource, setSelectedDataSource] = useState<IntegrationType | null>(null);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [selectedSubprojectId, setSelectedSubprojectId] = useState<string | null>(null);

  const handleDisplayIntegrations = () => {
    setSelectedDataSource(null);
    setSelectedProjectId(null);
    setSelectedSubprojectId(null);
    navigate('/application/integrations');
  };

  const handleSelectIntegrationType = (dataSource: IntegrationType | null) => {
    setSelectedDataSource(dataSource);
    setSelectedProjectId(null);
    setSelectedSubprojectId(null);
  };

  const selectedProject = projects?.find((project) => project.id === selectedProjectId);
  const selectedSubproject = integrationEntries
    .flatMap((entry) => entry.subprojects)
    .find((subproject) => subproject.id === selectedSubprojectId);
  const selectedSubprojectIntegration = selectedSubproject
    ? integrationEntries.find((entry) => entry.subprojects.some((sub) => sub.id === selectedSubproject.id))
    : null;

  return (
    <Container>
      <BrandedLoadingOverlay visible={query.isFetching} transitionDuration={30} variant="colored" />
      <DataSourcesList
        availableDataSources={integrationEntries.map((entry) => entry.integration_type)}
        selectedDataSource={selectedDataSource}
        handleSelectIntegrationType={handleSelectIntegrationType}
        handleDisplayIntegrations={handleDisplayIntegrations}
      />
      <DataSourcesPanel
        selectedDataSource={selectedDataSource}
        integrationEntries={integrationEntries}
        selectedProjectId={selectedProjectId}
        selectedSubprojectId={selectedSubprojectId}
        setSelectedProjectId={setSelectedProjectId}
        setSelectedSubprojectId={setSelectedSubprojectId}
      />
      {selectedSubprojectId && selectedSubproject && (
        <SubprojectInfoPanel
          subproject={selectedSubproject}
          setSelectedSubprojectId={setSelectedSubprojectId}
          integrationSource={selectedSubprojectIntegration?.integration_type ?? null}
        />
      )}
      {selectedProjectId && selectedProject && !selectedSubproject && (
        <ProjectInfoPanel
          project={selectedProject}
          portfolios={allPortfolios}
          currentPortfolio={null}
          setSelectedProject={setSelectedProjectId}
          repositories={repositories}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin: 16px 0px 0px 0px;
`;
