import { styled } from '@linaria/react';
import { LoadingOverlay, Title } from '@mantine/core';
import { useContext, useRef } from 'react';
import { Measure } from '../../../api/work-periods-client/work-periods-client.type';
import { ComboboxSelect } from '../../../components/combobox-select/combobox-select';
import { DownloadPNGButton } from '../../../components/download-png-button';
import { FloraButton } from '../../../containers/flora/flora-button';
import { canvasHtmlDownload } from '../../../helpers/image-downloader/image-downloader';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  filterCustomMeasures,
  filterUnwantedMeasures,
  useAvailableMeasureOptions,
  useDateRange,
  useEntities,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { DropShadowContainer } from '../initiative-sections/initiative-overview';
import { TH } from '../key-measures/key-measures-over-time';
import { Pills } from '../pills/pills';
import { ProcessAnalysisContext } from '../process-analysis.context';
import { getMeasureColor, getPills, toDisplayText } from '../process-analysis.helpers';
import { Section } from '../process-analysis.type';
import { ProcessAnalysisMeasuresComparisonChart } from './measure-comparison-chart';
import {
  calculateAverage,
  calculateTrend,
  forecastNextValue,
  getMeasuresWithColors,
} from './measure-comparison.helpers';
import {
  useMeasureComparisonCustomMeasureData,
  useMeasureComparisonData,
  useMeasureComparisonDataGroupCalls,
} from './measure-comparison.hooks';
import { MeasureComparisonType } from './measure-comparison.type';
import { MeasureRow } from './measure-row';

export function MeasureComparison() {
  const { portfolios = [], teams = [] } = useGlobalStore();
  const { useAI } = useGlobalStore();

  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures)
    .filter(filterUnwantedMeasures)
    .filter((measure) => !filterCustomMeasures(measure));
  const customMeasures = useProcessAnalysisStore((state) => state.availableMeasures)
    .filter(filterCustomMeasures)
    .filter(filterUnwantedMeasures);
  const allMeasureMetadata = availableMeasures.concat(customMeasures);
  const entities = useEntities();
  const { startDate, endDate } = useDateRange();
  const availableMeasureOptions = useAvailableMeasureOptions();

  const {
    [Section.MeasureComparison]: {
      measures,
      selectedMeasures,
      selectedTrends,
      setMeasures,
      setSelectedMeasures,
      setSelectedTrends,
      removeSelection,
    },
  } = useContext(ProcessAnalysisContext);

  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);
  const { data: defaultMeasureData } = useMeasureComparisonDataGroupCalls(MeasureComparisonType.Default);
  const { data: transformerMeasureData } = useMeasureComparisonData(MeasureComparisonType.Transformer);
  const { data: customMeasureData } = useMeasureComparisonCustomMeasureData(customMeasures);
  const pills = getPills(entities, activeTab, portfolios, teams, startDate, endDate);

  const activeMeasureOptions = availableMeasureOptions.filter((option) => !measures.includes(option.value as Measure));

  const handleDownload = () => canvasHtmlDownload(pills.join('_'), downloadRef, exceptionRef);
  const combinedData = {
    ...(defaultMeasureData || {}),
    ...(transformerMeasureData || {}),
    ...(customMeasureData || {}),
  };

  return (
    <DropShadowContainer ref={downloadRef}>
      <LoadingOverlay
        visible={!defaultMeasureData && !transformerMeasureData && !customMeasureData}
        overlayProps={{ blur: 2 }}
        style={{ zIndex: 200 }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '12px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '5px' }}>
          <Pills pillText={pills} />
          <div ref={exceptionRef} style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            {useAI && <FloraButton externalData={[defaultMeasureData, transformerMeasureData, customMeasureData]} />}
            {<DownloadPNGButton handleDownload={handleDownload} exceptionRef={exceptionRef} />}
          </div>
        </div>
        <Title>Measure Comparison</Title>
      </div>
      <ContentContainer>
        <LegendContainer>
          <LegendScrollContainer>
            <LegendTable>
              <thead>
                <tr>
                  <StickyTH>Measures</StickyTH>
                  <StickyTH>Line</StickyTH>
                  <StickyTH>Trend</StickyTH>
                  <StickyTH></StickyTH>
                </tr>
                <tr style={{ height: '16px' }}></tr>
              </thead>
              <tbody>
                {measures.map((measure) => {
                  const color = getMeasureColor(measure);
                  const measureMetadata = allMeasureMetadata.find((m) => m.measure_name === measure);
                  const measureData = combinedData[measure] || {};
                  const trendLine = calculateTrend(measureData, measureMetadata?.is_zero_valid ?? true);
                  const forecast = forecastNextValue(trendLine, measureData);

                  return (
                    <MeasureRow
                      key={measure}
                      measure={measure}
                      title={measureMetadata?.measure_title || toDisplayText(measure)}
                      description={measureMetadata?.measure_description}
                      color={color}
                      isZeroValid={measureMetadata?.is_zero_valid ?? true}
                      average={calculateAverage(measureData, measureMetadata?.is_zero_valid ?? true)}
                      trend={trendLine?.slope ?? null}
                      forecast={forecast}
                      selectedMeasures={selectedMeasures}
                      selectedTrends={selectedTrends}
                      setSelectedMeasures={(event) => {
                        const { checked } = event.currentTarget;
                        if (checked) {
                          setSelectedMeasures([...selectedMeasures, measure]);
                        } else {
                          setSelectedMeasures(selectedMeasures.filter((val) => val !== measure));
                        }
                      }}
                      setSelectedTrends={(event) => {
                        const { checked } = event.currentTarget;
                        if (checked) {
                          setSelectedTrends([...selectedTrends, measure]);
                        } else {
                          setSelectedTrends(selectedTrends.filter((val) => val !== measure));
                        }
                      }}
                      removeSelection={removeSelection}
                    />
                  );
                })}
              </tbody>
            </LegendTable>
          </LegendScrollContainer>
          {activeMeasureOptions.length > 0 && (
            <DropdownContainer>
              <ComboboxSelect
                options={activeMeasureOptions}
                onChange={(value) => {
                  setMeasures([...measures, value as Measure]);
                }}
                placeholder="Select measure"
              />
            </DropdownContainer>
          )}
        </LegendContainer>
        <ChartContainer>
          <ProcessAnalysisMeasuresComparisonChart
            measureData={combinedData}
            measuresWithColor={getMeasuresWithColors(selectedMeasures)}
            trendsWithColor={getMeasuresWithColors(selectedTrends)}
          />
        </ChartContainer>
      </ContentContainer>
    </DropShadowContainer>
  );
}

const LegendContainer = styled.div`
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LegendScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding-right: 16px;
`;

const DropdownContainer = styled.div`
  background-color: white;
  padding: 8px 0;
  border-top: 1px solid ${newCOLORS.lightGray};
`;

const StickyTH = styled(TH)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;

  &:nth-child(1) {
    width: 160px;
  }
  &:nth-child(2) {
    width: 40px;
  }
  &:nth-child(3) {
    width: 40px;
  }
  &:nth-child(4) {
    width: 40px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 400px;
  transition: width 300ms ease;
`;

const LegendTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 300px;
  table-layout: fixed;
`;

const ChartContainer = styled.div`
  flex: 1;
  min-width: 0;
`;
