import { Divider, Flex } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { useReducer } from 'react';
import { completeGithubHandshake } from '../../../api/integrations-client/integrations-client';
import { Button } from '../../../components/button/button';
import { useOrganizationId } from '../../../helpers/auth-helpers/auth.hooks';
import { useRedirect } from '../integrations.hooks';
import { StepProps } from '../integrations.type';
import styles from '../views/views.module.css';
import { githubReducer } from './github-reducer';
import { GithubState } from './github.type';
import { Organizations } from './organizations';
import { ProjectMappings } from './project-mapping';
import { Repositories } from './repositories';
import { Repository } from './repositories.type';
import { Success } from './success';
import { Summary } from './summary';

const initialState: GithubState = {
  initialized: false,
  repository: {} as Repository,
  organization: '',
  projects: [],
  options: {
    organizationWithRepos: {},
    repositories: [],
    selectedProject: '',
  },
};

/** The container for the Github integration.
 *  This is the parent component for the Github integration.
 *
 *  This component is responsible for rendering the correct view based on the activeStep prop,
 *  as well as navigating between views.
 *  This component is also responsible for managing the state of the entire Github integration workflow.
 *
 * @param props: { activeStep: number, setActiveStep: Dispatch<SetStateAction<number>>}
 * @returns JSX.Element
 */
export function Github({ activeStep = 0, setActiveStep }: StepProps) {
  const [state, dispatch] = useReducer(githubReducer, initialState);
  const { redirect } = useRedirect();
  const orgId = useOrganizationId();

  const views = [
    <Organizations state={state} dispatch={dispatch} key="organizations" />,
    <Repositories state={state} dispatch={dispatch} key="repositories" />,
    <ProjectMappings state={state} dispatch={dispatch} key="project-mappings" />,
    <Summary state={state} setActiveStep={handleChangeView} key="summary" />,
    <Success key="success" />,
  ];

  /** Changes the view to the view specified by the view parameter.
   * Scrolls the window to the top of the page when the view changes.
   *
   * @param view: number | ((current: number) => number) - the index of the view to change to.
   * Can be a number or a function that returns a number
   */
  function handleChangeView(view: number | ((current: number) => number)) {
    setTimeout(() => window.scrollTo(0, 0));
    setActiveStep(view);
  }

  const completeGithubMutation = useMutation({
    mutationKey: ['completeGithub', state] as const,
    mutationFn: () => {
      const { repository, organization, options } = state;

      if (!repository || !options?.selectedProject || !orgId || !organization) {
        throw new Error('Missing request parameters');
      }

      return completeGithubHandshake(repository, orgId, options.selectedProject, organization);
    },
  });

  function handleSubmit() {
    // TODO(sentry): Could not automatically migrate - see https://github.com/getsentry/sentry-javascript/blob/develop/MIGRATION.md#deprecate-hub
    completeGithubMutation.mutate();
    nextStep();
  }

  /** Determines whether the next button should be disabled or not.
   * The next button may need to be disabled based on criteria specific to each step.
   *
   * @param activeStep: number- the current step in the workflow
   * @returns boolean- true if the next button should be disabled, false otherwise
   */
  function isNextButtonDisabled(activeStep: number) {
    switch (activeStep) {
      case 0:
        return !state.repository;
      default:
        return false;
    }
  }

  const nextStep = () => handleChangeView((current: number) => (current < views.length ? current + 1 : current));
  const previousStep = () => handleChangeView((current: number) => (current > 0 ? current - 1 : current));

  return (
    <Flex direction="column">
      {views[activeStep]}
      {!redirect && (
        <>
          <Divider my="sm" />
          <div id={styles.backNextContainer}>
            {activeStep < 4 ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                {activeStep !== 0 ? (
                  <Button variant="subtle" disabled={activeStep === 0} onClick={previousStep} size="lg">
                    Back
                  </Button>
                ) : null}
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    disabled={isNextButtonDisabled(activeStep)}
                    onClick={activeStep < 3 ? nextStep : handleSubmit}
                    size="lg"
                  >
                    {activeStep < 3 ? 'Next' : "Let's go!"}
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </Flex>
  );
}
