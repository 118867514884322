import iconADO from './iconADO.svg';
import iconArrowDown from './iconArrowDown.svg';
import iconBeta from './iconBeta.svg';
import iconBloomfilter from './iconBloomfilter.svg';
import iconCalendar from './iconCalendar.svg';
import iconCaretLeft from './iconCaretLeft.svg';
import iconCaretRight from './iconCaretRight.svg';
import iconCheckmark from './iconCheckmark.svg';
import iconCheckmarkDarkGreen from './iconCheckmarkDarkGreen.svg';
import iconCheckmarkGrey from './iconCheckmarkGrey.svg';
import iconClipboard from './iconClipboard.svg';
import iconCompass from './iconCompass.svg';
import iconCompassGrey from './iconCompassGrey.svg';
import iconCompletion from './iconCompletion.svg';
import iconContributingEpics from './iconContributingEpics.svg';
import iconContributingTeams from './iconContributingTeams.svg';
import iconCurrentSprint from './iconCurrentSprint.svg';
import iconDelete from './iconDelete.svg';
import iconDoubleArrowLeft from './iconDoubleArrowLeft.svg';
import iconDoubleArrowRight from './iconDoubleArrowRight.svg';
import iconDoubleChevronDown from './iconDoubleChevronDown.svg';
import iconDoubleChevronRight from './iconDoubleChevronRight.svg';
import iconDoubleChevronUp from './iconDoubleChevronUp.svg';
import iconDownload from './iconDownload.svg';
import iconEdit from './iconEdit.svg';
import iconExpand from './iconExpand.svg';
import iconFinancials from './iconFinancials.svg';
import iconFire from './iconFire.svg';
import iconFlora from './iconFlora.svg';
import iconGithub from './iconGithub.svg';
import iconGrayAlert from './iconGrayAlert.svg';
import iconGreenAlert from './iconGreenAlert.svg';
import iconHistory from './iconHistory.svg';
import iconIntegrations from './iconIntegrations.svg';
import iconJira from './iconJira.svg';
import iconLinkTargetIndigo from './iconLinkTargetIndigo.svg';
import iconPanHorizontal from './iconPanHorizontal.svg';
import iconPortfolio from './iconPortfolio.svg';
import iconProcessAnalysis from './iconProcessAnalysis.svg';
import iconProcessMap from './iconProcessMap.svg';
import iconProject from './iconProject.svg';
import iconRedAlert from './iconRedAlert.svg';
import iconRedWarning from './iconRedWarning.svg';
import iconShare from './iconShare.svg';
import iconSolidLayers from './iconSolidLayers.svg';
import iconSortDefault from './iconSortDefault.svg';
import iconStrategy from './iconStrategy.svg';
import iconStrategyGradient from './iconStrategyGradient.svg';
import iconTarget from './iconTarget.svg';
import iconTeam from './iconTeam.svg';
import iconTimeElapsedPink from './iconTimeElapsedPink.svg';
import iconWarningDarkRed from './iconWarningDarkRed.svg';
import iconWarningGrey from './iconWarningGrey.svg';
import iconWindowGraph from './iconWindowGraph.svg';
import iconYellowAlert from './iconYellowAlert.svg';

export const icons = {
  iconADO,
  iconArrowDown,
  iconCalendar,
  iconCaretLeft,
  iconCaretRight,
  iconCheckmark,
  iconCheckmarkDarkGreen,
  iconCheckmarkGrey,
  iconClipboard,
  iconCompass,
  iconCompassGrey,
  iconCompletion,
  iconContributingEpics,
  iconContributingTeams,
  iconCurrentSprint,
  iconDelete,
  iconDoubleChevronDown,
  iconDoubleChevronRight,
  iconDoubleChevronUp,
  iconDownload,
  iconEdit,
  iconExpand,
  iconFinancials,
  iconFire,
  iconGithub,
  iconGrayAlert,
  iconGreenAlert,
  iconHistory,
  iconIntegrations,
  iconJira,
  iconLinkTargetIndigo,
  iconPanHorizontal,
  iconPortfolio,
  iconStrategy,
  iconProcessMap,
  iconProject,
  iconRedAlert,
  iconRedWarning,
  iconShare,
  iconSolidLayers,
  iconSortDefault,
  iconTarget,
  iconTeam,
  iconTimeElapsedPink,
  iconWarningDarkRed,
  iconWarningGrey,
  iconWindowGraph,
  iconYellowAlert,
  iconProcessAnalysis,
  iconDoubleArrowLeft,
  iconDoubleArrowRight,
  iconBeta,
  iconStrategyGradient,
  iconBloomfilter,
  iconFlora,
};
