import { styled } from '@linaria/react';
import { Link } from 'react-router-dom';
import { SprintAuditLogEntry } from '../../api/sprints-client/sprints-client.type';
import { TaskAuditLogEntry } from '../../api/tasks-client/task-client.type';
import { formatDate } from '../../helpers/timezone/timezone';
import { DateTemplate } from '../../helpers/timezone/timezone.type';
import { newCOLORS } from '../../styles/colors';

export type Props = {
  entry: SprintAuditLogEntry | TaskAuditLogEntry;
  clickable?: boolean;
};

export function AuditLogEntry(props: Props) {
  const entryLink = `/application/process-analysis/audit-log/${props.entry.id}`;

  return (
    <AuditLogTableRow>
      <AuditLogTableCell>
        {formatDate(
          props.entry.date,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          DateTemplate.DateTimeWithTimezone,
        )}
      </AuditLogTableCell>
      <AuditLogTableCell>
        {props.clickable ? <Link to={entryLink}>{props.entry.identifier}</Link> : <div> {props.entry.identifier}</div>}
      </AuditLogTableCell>
      <AuditLogTableCell>{props.entry.message}</AuditLogTableCell>
    </AuditLogTableRow>
  );
}

const AuditLogTableRow = styled.tr`
  padding: 5px;
  font-size: 20px;
  border-bottom: 1px solid ${newCOLORS.gray};
`;

const AuditLogTableCell = styled.td`
  padding: 5px;
`;
