import { forwardRef, ReactNode } from 'react';

export const DropShadowCard = forwardRef<
  HTMLDivElement,
  { children: ReactNode; style?: { [key: string]: string | number } }
>(({ children, style }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        backgroundColor: '#FFF',
        padding: '1em',
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: 16,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        ...style,
      }}
    >
      {children}
    </div>
  );
});
