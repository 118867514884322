import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Button, Checkbox, Divider, MultiSelect, MultiSelectProps, Select } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useEffect, useRef } from 'react';
import { deduplicateComboboxGroups } from '../../../helpers/general-helpers';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  applyWorkPeriodsFilter,
  setIsDirtyWorkPeriods,
} from '../../../store/process-analysis-store/process-analysis-store.actions';
import { newCOLORS } from '../../../styles/colors';
import { WorkPeriodType } from '../process-analysis.type';
import { useWorkPeriodForm } from './scope-explorer.hooks';
import { commonSelectProps, getBoardOptions, getWorkPeriodTypeOptions } from './work-period-form.helpers';
import {
  useBoardManagement,
  useWorkPeriodOptions,
  useWorkPeriodSelection,
  useWorkPeriodsManagement,
} from './work-period-form.hooks';

const showSprintComparisonFlag = import.meta.env.VITE_FEATURE_FLAG_SPRINT_COMPARISON === 'true';

export function WorkPeriodForm() {
  const teams = useGlobalStore((state) => state.teams);
  const organization = useGlobalStore((state) => state.organization);
  const portfolio = useGlobalStore((state) => state.portfolio);
  const { workPeriodForm, handleSubmitWorkPeriodForm } = useWorkPeriodForm();
  const isFirstRender = useRef(true);

  // Track organization/portfolio changes
  const orgIdRef = useRef(organization?.id);
  const portfolioIdRef = useRef(portfolio?.id);

  // Ensure showSprintComparison is false when feature flag is false
  useEffect(() => {
    if (!showSprintComparisonFlag && workPeriodForm.values.showSprintComparison) {
      workPeriodForm.setFieldValue('showSprintComparison', false);
      setIsDirtyWorkPeriods(true);
      applyWorkPeriodsFilter({
        workPeriodBoardId: workPeriodForm.values.workPeriodBoardId || '',
        workPeriodType: workPeriodForm.values.workPeriodType,
        workPeriod: null,
        selectedWorkPeriods: [],
        showSprintComparison: false,
      });
    }
  }, [workPeriodForm]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      orgIdRef.current = organization?.id;
      portfolioIdRef.current = portfolio?.id;
      return;
    }

    const orgChanged = organization?.id !== orgIdRef.current;
    const portfolioChanged = portfolio?.id !== portfolioIdRef.current;

    if (orgChanged || portfolioChanged) {
      orgIdRef.current = organization?.id;
      portfolioIdRef.current = portfolio?.id;

      workPeriodForm.setFieldValue('workPeriodIds', []);
      workPeriodForm.setFieldValue('workPeriodId', null);
      setIsDirtyWorkPeriods(true);
      applyWorkPeriodsFilter({
        workPeriodBoardId: workPeriodForm.values.workPeriodBoardId || '',
        workPeriodType: workPeriodForm.values.workPeriodType,
        workPeriod: null,
        selectedWorkPeriods: [],
        showSprintComparison: workPeriodForm.values.showSprintComparison,
      });
    }
  }, [organization, portfolio, workPeriodForm]);

  const workPeriods = useProcessAnalysisStore((state) => state.workPeriods);
  const workPeriodOptions = useWorkPeriodOptions();

  const boardId = workPeriodForm.getValues().workPeriodBoardId;
  const workPeriodType = workPeriodForm.getValues().workPeriodType;
  const workPeriodIds = workPeriodForm.getValues().workPeriodIds;
  const showSprintComparison = workPeriodForm.getValues().showSprintComparison;

  useBoardManagement();
  useWorkPeriodsManagement(boardId);
  useWorkPeriodSelection(workPeriodForm);

  return (
    <form onSubmit={workPeriodForm.onSubmit(() => handleSubmitWorkPeriodForm(workPeriodForm))}>
      <Panel>
        <Select
          required
          {...workPeriodForm.getInputProps('workPeriodBoardId')}
          onChange={(value) => {
            workPeriodForm.setFieldValue('workPeriodBoardId', value);
            workPeriodForm.setFieldValue('workPeriodIds', []);
            setIsDirtyWorkPeriods(true);
            applyWorkPeriodsFilter({
              workPeriodBoardId: value || '',
              workPeriodType: workPeriodForm.values.workPeriodType,
              workPeriod: null,
              selectedWorkPeriods: [],
              showSprintComparison: workPeriodForm.values.showSprintComparison,
            });
          }}
          data={deduplicateComboboxGroups(getBoardOptions(teams))}
          allowDeselect={false}
          miw={200}
          {...commonSelectProps}
        />
        <Select
          required
          {...workPeriodForm.getInputProps('workPeriodType')}
          onChange={(value) => {
            workPeriodForm.setFieldValue('workPeriodType', value as WorkPeriodType);
            setIsDirtyWorkPeriods(true);
          }}
          data={getWorkPeriodTypeOptions()}
          allowDeselect={false}
          miw={200}
          {...commonSelectProps}
        />
        <Divider orientation="vertical" />
        {workPeriodType === WorkPeriodType.Defined && workPeriods.length > 0 && (
          <>
            {showSprintComparison && showSprintComparisonFlag ? (
              <StyledMultiSelect
                {...workPeriodForm.getInputProps('workPeriodIds')}
                onChange={(value) => {
                  workPeriodForm.setFieldValue('workPeriodIds', value);
                  setIsDirtyWorkPeriods(true);
                }}
                data={workPeriodOptions}
                placeholder={workPeriodIds.length > 0 ? undefined : 'Select sprints to compare'}
                w={500}
                searchable
                maxDropdownHeight={400}
                {...(commonSelectProps as Partial<MultiSelectProps>)}
              />
            ) : (
              <Select
                {...workPeriodForm.getInputProps('workPeriodId')}
                onChange={(value) => {
                  workPeriodForm.setFieldValue('workPeriodId', value);
                  setIsDirtyWorkPeriods(true);
                }}
                data={workPeriodOptions}
                allowDeselect={false}
                miw={200}
                {...commonSelectProps}
              />
            )}
            {showSprintComparisonFlag && (
              <CheckboxContainer>
                <Checkbox
                  {...workPeriodForm.getInputProps('showSprintComparison', { type: 'checkbox' })}
                  onChange={(event) => {
                    workPeriodForm.setFieldValue('showSprintComparison', event.currentTarget.checked);
                    setIsDirtyWorkPeriods(true);
                  }}
                  label="Sprint Comparison"
                />
              </CheckboxContainer>
            )}
          </>
        )}
        {workPeriodType === WorkPeriodType.Custom && (
          <>
            <DatePickerInput
              required
              leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
              leftSectionPointerEvents="none"
              {...workPeriodForm.getInputProps('startDate')}
              maxDate={workPeriodForm.values.endDate}
              pointer
              miw="fit-content"
            />
            <DatePickerInput
              required
              leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
              leftSectionPointerEvents="none"
              {...workPeriodForm.getInputProps('endDate')}
              minDate={workPeriodForm.values.startDate}
              pointer
              miw="fit-content"
            />
          </>
        )}
        <Button
          disabled={!workPeriodForm.isDirty() || !workPeriodForm.isValid()}
          color={newCOLORS.darkIndigo}
          type="submit"
          miw={120}
        >
          Apply Filter
        </Button>
      </Panel>
    </form>
  );
}

const Panel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  .mantine-Checkbox-body {
    align-items: center;
  }
`;

const StyledMultiSelect = styled(MultiSelect)`
  .mantine-MultiSelect-inputField {
    min-width: 10px;
  }
`;
