import { Icon } from '@iconify/react';
import { Stepper } from '@mantine/core';
import { User } from '@sentry/browser';
import { useContext } from 'react';
import bfMark from '../../../assets/images/BF-Mark-Primary-Black.svg';
import { UserContext } from '../../../contexts';
import { newCOLORS } from '../../../styles/colors';
import { HealthCheckSuccess, HealthCheckSuccessHeader } from '../views/healthcheck-view';
import styles from '../views/views.module.css';

/** The last/success view during the github itegration workflow.
 *
 * @returns JSX.Element
 */
export function Success() {
  const { user } = useContext(UserContext);

  if (user?.subscription_status == 'unpaid') {
    return <HealthCheckSuccess />;
  } else {
    return (
      <div id={styles.viewContainer}>
        <p>Your integration has been started! You will receive an email once the integration is complete.</p>
        <p>You can close this tab now</p>
        <div id={styles.successStepperContainer}>
          <Stepper
            active={3}
            orientation="vertical"
            size="lg"
            styles={{ verticalSeparator: { display: 'none' } }}
            color="violet.5"
          >
            <Stepper.Step label="We've got it from here!" />
            <Stepper.Step
              label="Bloomfilter will send you an email when your data is ready"
              description="Integrations can take between 1–8 hours depending on your instance. Click on the link within the email to be directed to your Bloomfilter dashboard."
            />
            <Stepper.Step label="Take a coffee break!" />
          </Stepper>
        </div>
      </div>
    );
  }
}

export function SuccessHeader(props: { user: User }) {
  const { user } = props;
  if (user?.subscription_status == 'unpaid') {
    return <HealthCheckSuccessHeader />;
  } else {
    return (
      <header id={styles.successHeader}>
        <div id={styles.successTitleContainer}>
          <h2 id={styles.successHeaderTitle}>
            {props.user?.subscription_status === 'unpaid'
              ? 'Healthcheck is in progress...'
              : 'Integration setup is in progress...'}
          </h2>
        </div>
        <div id={styles.successIconContainer}>
          <img alt="Bloomfilter Beta Logo" src={bfMark} width="64px" height="64px" />
          <div className={styles.successDividerContainer}>
            <div className={styles.successDivider} />
          </div>
          <Icon icon="ph:globe" width={64} height={64} color={newCOLORS.aqua} />
          <div className={styles.successDividerContainer}>
            <div className={styles.successDivider} />
          </div>
          <Icon icon="bx:server" width={64} height={64} color={newCOLORS.blue} />
        </div>
      </header>
    );
  }
}
