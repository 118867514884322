import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getInitiative, getInitiativeTasks } from '../../../api/initiative-client/initiative-client';
import { ExtendedInitiative, InitiativeTasksFilters } from '../../../api/initiative-client/initiative-client.type';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../../components/page-header-message/page-header-message';
import { Title } from '../../../components/title/title';
import { useDocumentTitle } from '../../../helpers/general-helpers';
import { MenuDrivenContainer } from '../../menu-driven-container/menu-driven-container';
import { TasksTable } from '../../tasks-table/tasks-table';
import { formatTasks, makeTitle } from './initiative-tasks.helpers';

export const InitiativeTasks = () => {
  useDocumentTitle('Task Details - Bloomfilter');

  const { portfolioId } = useParams<{ portfolioId: string }>();
  const { initiativeId } = useParams<{ initiativeId: string }>();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('filter') as InitiativeTasksFilters | null;
  const date = searchParams.get('date');
  const epicId = searchParams.get('epicId');
  const projectId = searchParams.get('projectId');
  const [year, month] = date ? date.split('-') : [null, null];

  const [initiative, setInitiative] = useState<ExtendedInitiative>();

  const initiativeQuery = useQuery({
    queryKey: ['initiative', portfolioId, initiativeId] as const,
    queryFn: async () => {
      if (!portfolioId || !initiativeId) {
        throw new Error('Portfolio ID and Initiative ID are required');
      }
      return getInitiative(portfolioId, initiativeId);
    },
    enabled: Boolean(portfolioId) && Boolean(initiativeId),
  });

  useEffect(() => {
    if (initiativeQuery.data) {
      setInitiative(initiativeQuery.data);
    }
  }, [initiativeQuery.data]);

  useEffect(() => {
    if (initiativeQuery.error) {
      console.error('There was an issue fetching the initiative:', initiativeQuery.error);
    }
  }, [initiativeQuery.error]);

  const initiativeTasksQuery = useQuery({
    queryKey: ['initiativeTasks', portfolioId, initiativeId, filter, date, epicId, projectId] as const,
    queryFn: async () => {
      if (!portfolioId || !initiativeId || !filter) {
        throw new Error('Required parameters missing for fetching tasks');
      }
      return getInitiativeTasks({
        portfolioId,
        initiativeId,
        filter,
        date: date || undefined,
        epicId: epicId || undefined,
        projectId: projectId || undefined,
      });
    },
    enabled: Boolean(portfolioId && initiativeId && filter),
  });

  const showLocalError =
    !initiativeTasksQuery.isFetching &&
    initiativeTasksQuery.data &&
    initiativeTasksQuery.data.every(({ tasks }) => tasks.length === 0);

  const header = <Title title={makeTitle(filter, projectId, initiative, epicId, year, month)} />;

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay visible={initiativeTasksQuery.isFetching} transitionDuration={30} variant="colored" />
      <TasksContainer>
        {showLocalError && <PageHeaderMessage message="There are no related tasks for this selection." color="red" />}
        <TasksTable tasks={formatTasks(initiativeTasksQuery.data)} />
      </TasksContainer>
    </MenuDrivenContainer>
  );
};

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
