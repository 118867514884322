import { styled } from '@linaria/react';
import { Flex, Select } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteInitiative,
  getInitiative,
  getInitiatives,
  updateInitiative,
} from '../../../api/initiative-client/initiative-client';
import {
  ExtendedInitiative,
  InitiativeSource,
  PreSaveInitiative,
} from '../../../api/initiative-client/initiative-client.type';
import { icons } from '../../../assets/icons/icons';
import { Button } from '../../../components/button/button';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../../components/page-header-message/page-header-message';
import { Title } from '../../../components/title/title';
import { useDocumentTitle } from '../../../helpers/general-helpers';
import { useStrategyStore } from '../../../store/strategy-store/strategy-store';
import { setMessage } from '../../../store/strategy-store/strategy-store.actions';
import { MessageStatus } from '../../../store/strategy-store/strategy-store.type';
import { newCOLORS } from '../../../styles/colors';
import { Text } from '../../../ui-library/typography/typography';
import { FloraButton } from '../../flora/flora-button';
import { MenuDrivenContainer } from '../../menu-driven-container/menu-driven-container';
import { iconNavigate } from '../assets';
import { InitiativeDeleteModal } from '../initiative-delete/initiative-delete-modal';
import { InitiativeModal } from '../initiative-modal/initiative-modal';
import { getSourceIcon, getSourceLabel } from '../initiatives-list/initiatives-list.helpers';
import { getSelectOptions } from './initiative-performance.helpers';
import { SectionEpics } from './section-epics/section-epics';
import { SectionStatus } from './section-status/section-status';
import { SectionTeams } from './section-teams/section-teams';
export function InitiativePerformance() {
  useDocumentTitle('Initiative Performance - Bloomfilter');
  const [initiative, setInitiative] = useState<ExtendedInitiative | undefined>(undefined);
  const { portfolioId, initiativeId } = useParams();
  const navigate = useNavigate();

  const [openedEdit, { open: openEditModal, close: closeEditModal }] = useDisclosure(false);
  const [openedDelete, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);

  const message = useStrategyStore((state) => state.message);

  // check if the epic is completed and associated epics have active subtasks
  const activeMessage =
    'This initiative has passed its end date. Tasks are continuing to be added, completed, or removed in epics mapped to this initiative.';
  const defaultMessage = initiative?.contains_active_epics_after_close ? activeMessage : '';
  useEffect(() => {
    if (defaultMessage) {
      setMessage({ status: MessageStatus.Error, text: defaultMessage });
    }
  }, [defaultMessage]);
  const ref = useClickOutside(() => setMessage(null), ['mouseup', 'touchend']);

  const selectRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();

  const initiativesQuery = useQuery({
    queryKey: ['initiatives', portfolioId] as const,
    queryFn: async () => {
      if (!portfolioId) {
        throw new Error('Portfolio ID is required');
      }
      return getInitiatives(portfolioId);
    },
    enabled: Boolean(portfolioId),
  });

  const initiativeQuery = useQuery({
    queryKey: ['initiative', initiativeId, portfolioId] as const,
    queryFn: async () => {
      if (!portfolioId || !initiativeId) {
        throw new Error('Portfolio ID and Initiative ID are required');
      }
      return getInitiative(portfolioId, initiativeId);
    },
    enabled: !!initiativeId && !!portfolioId,
  });

  useEffect(() => {
    if (initiativeQuery.data) {
      setInitiative(initiativeQuery.data);
    }
  }, [initiativeQuery.data]);

  const editInitiativeMutation = useMutation({
    mutationKey: ['editInitiative', initiativeId] as const,
    mutationFn: async (initiative: PreSaveInitiative) => {
      if (!portfolioId) {
        throw new Error('Cannot update initiative: Portfolio ID is required');
      }
      if (!initiativeId) {
        throw new Error('Cannot update initiative: Initiative ID is required');
      }
      return updateInitiative(portfolioId, { id: initiativeId, ...initiative });
    },
    onSuccess: () => {
      closeEditModal();
      initiativesQuery.refetch();
      initiativeQuery.refetch();
    },
  });

  const deleteInitiativeMutation = useMutation({
    mutationKey: ['deleteInitiative', initiativeId] as const,
    mutationFn: async () => {
      if (!portfolioId || !initiativeId) {
        throw new Error('Cannot delete initiative: Portfolio ID and Initiative ID are required');
      }
      return deleteInitiative(portfolioId, initiativeId);
    },
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: ['initiative', initiativeId, portfolioId] });
      closeDeleteModal();
      navigate('/application/strategy', {
        state: { message: `${initiative?.name} has been successfully removed.` },
      });
    },
  });

  const loading = initiativeQuery.isFetching || editInitiativeMutation.isPending;

  const header = initiative && (
    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
      <Title title={initiative.name} />
      <FloraButton externalData={[initiative]} size={30} />
    </div>
  );

  const notification =
    message?.text === activeMessage ? (
      <PageHeaderMessage
        message="This initiative has passed its end date. "
        navigationMessage="Tasks are continuing"
        messageAfterLink=" to be added, completed, or removed in epics mapped to this initiative."
        color="red"
        navigationPath="tasks/?filter=active"
      />
    ) : (
      <PageHeaderMessage
        message={message?.text}
        color={message?.status === MessageStatus.Success ? 'green' : 'yellow'}
      />
    );

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay visible={loading} transitionDuration={30} variant="colored" />
      {initiative && (
        <>
          <div ref={ref}>{message ? notification : null}</div>
          <PageTitleContainer>
            <Actions initiative={initiative} onEdit={openEditModal} onDelete={openDeleteModal} />
            <SelectContainer>
              <SelectLabel>Initiative</SelectLabel>
              <div ref={selectRef}>
                <StyledSelect
                  label=""
                  size="xs"
                  styles={{
                    wrapper: { width: 'auto' },
                  }}
                  value={initiative.id}
                  data={getSelectOptions(initiativesQuery.data, initiative)}
                  onChange={(initiativeId) => {
                    navigate(`/application/strategy/${portfolioId}/${initiativeId}`);
                  }}
                />
              </div>
            </SelectContainer>
          </PageTitleContainer>
          <Flex direction="row" align="center" gap={4} style={{ marginBottom: 8 }}>
            <img src={getSourceIcon(initiative.source)} width={20} height={20} alt={initiative.source} />
            <Text>{getSourceLabel(initiative.source)}</Text>
          </Flex>
          <Description>{initiative.description}</Description>
          <InitiativePerformanceContent initiative={initiative} portfolioId={portfolioId} />
        </>
      )}
      {portfolioId && (
        <InitiativeModal
          opened={openedEdit}
          title="Edit Initiative"
          portfolioId={portfolioId}
          initiative={initiative}
          handleClose={closeEditModal}
          handleSubmit={editInitiativeMutation.mutateAsync}
        />
      )}
      {initiative && (
        <InitiativeDeleteModal
          opened={openedDelete}
          initiativeName={initiative.name}
          handleClose={closeDeleteModal}
          handleSubmit={deleteInitiativeMutation.mutate}
        />
      )}
    </MenuDrivenContainer>
  );
}

export const InitiativePerformanceContent = ({
  initiative,
  portfolioId,
}: {
  initiative: ExtendedInitiative;
  portfolioId: string | undefined;
}) => (
  <ContentContainer>
    <SectionStatus initiative={initiative} />
    <SectionEpics initiative={initiative} />
    <SectionTeams initiative={initiative} portfolioId={portfolioId} />
  </ContentContainer>
);

const Actions = ({
  initiative,
  onEdit,
  onDelete,
}: {
  initiative: ExtendedInitiative;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const isExternalInitiative = initiative.source !== InitiativeSource.Bloomfilter;

  if (isExternalInitiative && initiative.source_url) {
    const sourceLabels = {
      [InitiativeSource.Jira]: 'Jira',
      [InitiativeSource.JDC]: 'Jira',
      [InitiativeSource.ADO]: 'ADO',
      [InitiativeSource.Github]: 'GitHub',
    };

    return (
      <Button
        variant="outline"
        onClick={() => window.open(initiative.source_url as string, '_blank', 'noopener, noreferrer')}
        style={{ minWidth: 'fit-content' }}
      >
        <Flex direction="row" align="center" gap={4}>
          <Text>Review Initiative in {sourceLabels[initiative.source as keyof typeof sourceLabels]}</Text>
          <img alt="icon" height={20} width={20} src={iconNavigate} />
        </Flex>
      </Button>
    );
  }

  return (
    <div style={{ display: 'flex', gap: '15px' }}>
      <StyledButton leftSection={<img src={icons.iconEdit} />} onClick={onEdit}>
        Edit
      </StyledButton>
      <StyledButton
        leftSection={<img src={icons.iconDelete} className="delete-icon" />}
        onClick={onDelete}
        variant="outline"
      >
        Delete
      </StyledButton>
    </div>
  );
};

const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 8px;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectLabel = styled.div`
  color: #666;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px;
`;

const StyledSelect = styled(Select)`
  .mantine-Input-input {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    font-size: 15px;
    min-width: 120px;
  }
`;

const StyledButton = styled(Button)`
  display: inline-flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 36px;
  width: 120px;
`;

const ContentContainer = styled.div`
  display: flex;
  padding: 8px 16px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`;

const Description = styled.div`
  color: ${newCOLORS.darkerGray};
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
`;
