import dayjs from 'dayjs';
import {
  BurnsData,
  BurnsDataOverTime,
  FlowByPaceDataOverTime,
  FlowByPhaseDataOverTime,
} from '../../../api/work-periods-client/work-periods-client.type';
import {
  AssessmentChart,
  WorkPeriodBurnsAndFlows,
  WorkPeriodBurnsAndFlowsByPace,
  WorkPeriodFlowDataPoint,
} from './assessment-view.type';

/**
 * Retrieves the download label for a given AssessmentChart.
 *
 * @param {AssessmentChart} chart - The type of AssessmentChart to retrieve the label for.
 * @return {string} The label corresponding to the provided AssessmentChart.
 */
const getDownloadLabel = (chart: AssessmentChart): string => {
  const labels = {
    [AssessmentChart.Phase]: 'Flow of work, by phase',
    [AssessmentChart.Pace]: 'Flow of work, to pace needed',
    [AssessmentChart.Delivery]: 'Flow of work, delivery confidence',
  };

  return labels[chart];
};

/**
 * Transforms the `FlowByPhaseDataOverTime` object into a `WorkPeriodBurnsAndFlows` object
 * compatible with the burn-flow-chart component.
 *
 * @param {FlowByPhaseDataOverTime | undefined} data - The data to be transformed.
 * @return {WorkPeriodBurnsAndFlows} The transformed data.
 */
const formatFlowByPhaseData = (
  data?: FlowByPhaseDataOverTime,
  paceData?: FlowByPaceDataOverTime,
): WorkPeriodBurnsAndFlows => {
  if (!data) {
    return { flows_tasks: { data_points: [] }, flows_points: { data_points: [] } };
  }

  const result = Object.keys(data)
    .toSorted((a, b) => dayjs(a).diff(dayjs(b)))
    .reduce(
      (acc, key) => {
        const source = data[key];
        const paceSource = paceData?.[key];
        const date = dayjs.utc(key).toISOString();

        const tasksDataPoint = {
          date,
          backlog: source.tasks_backlog || 0,
          blocked: source.tasks_blocked || 0,
          wip: source.tasks_wip || 0,
          review: source.tasks_review || 0,
          test: source.tasks_test || 0,
          deployable: source.tasks_deployable || 0,
          done: source.tasks_done || 0,
          weekend_or_holiday: paceSource?.weekend_or_holiday || false,
        };

        const pointsDataPoint = {
          date,
          backlog: source.points_backlog || 0,
          blocked: source.points_blocked || 0,
          wip: source.points_wip || 0,
          review: source.points_review || 0,
          test: source.points_test || 0,
          deployable: source.points_deployable || 0,
          done: source.points_done || 0,
          weekend_or_holiday: paceSource?.weekend_or_holiday || false,
        };

        acc.flows_tasks.data_points.push(tasksDataPoint);
        acc.flows_points.data_points.push(pointsDataPoint);

        return acc;
      },
      {
        flows_tasks: { data_points: [] },
        flows_points: { data_points: [] },
      } as WorkPeriodBurnsAndFlows,
    );

  return result;
};

/**
 * Calculates the percentage of a given status in the total amount of tasks at the given day.
 *
 * @param {string} status - The status to calculate the percentage of.
 * @param {WorkPeriodFlowDataPoint[]} data - The data points to calculate the percentage from.
 * @param {number} activeDay - The day number to calculate the percentage for.
 * @return {number} The percentage of the given status at the given day.
 */
const calculateMeasurePercentage = (status: string, data: WorkPeriodFlowDataPoint[], activeDay: number): number => {
  const dataPoint = data[activeDay - 1];

  if (!dataPoint) {
    return 0;
  }

  const sum =
    dataPoint.backlog +
    dataPoint.blocked +
    dataPoint.wip +
    dataPoint.review +
    dataPoint.test +
    dataPoint.deployable +
    dataPoint.done;

  const statusValue = dataPoint[status as keyof typeof dataPoint] as number;

  return (statusValue / sum) * 100;
};

/**
 * Transforms the `FlowByPaceDataOverTime` object into a `WorkPeriodBurnsAndFlowsByPace` object
 * compatible with the flow by pace chart components.
 *
 * @param {FlowByPaceDataOverTime | undefined} data - The data to be transformed.
 * @return {WorkPeriodBurnsAndFlowsByPace} The transformed data.
 */
const formatFlowByPaceData = (data?: FlowByPaceDataOverTime): WorkPeriodBurnsAndFlowsByPace => {
  if (!data) {
    return {
      flow_of_work_tasks_by_pace: { data_points: [] },
      flow_of_work_points_by_pace: { data_points: [] },
    };
  }

  const result = Object.keys(data)
    .toSorted((a, b) => dayjs(a).diff(dayjs(b)))
    .reduce(
      (acc, key) => {
        const source = data[key];

        const tasksDataPoint = {
          date: dayjs(key).format('YYYY-MM-DD'),
          weekend_or_holiday: source.weekend_or_holiday || false,
          tasks_complete: source.tasks_complete || 0,
          tasks_doing: source.tasks_doing || 0,
          tasks_ideal: source.tasks_ideal || 0,
          tasks_original_plan: source.tasks_original_plan || 0,
          tasks_remaining: source.tasks_remaining || 0,
          tasks_total: source.tasks_total || 0,
          tasks_status: source.tasks_status,
        };

        const pointsDataPoint = {
          date: dayjs(key).format('YYYY-MM-DD'),
          weekend_or_holiday: source.weekend_or_holiday || false,
          points_complete: source.points_complete || 0,
          points_doing: source.points_doing || 0,
          points_ideal: source.points_ideal || 0,
          points_original_plan: source.points_original_plan || 0,
          points_remaining: source.points_remaining || 0,
          points_total: source.points_total || 0,
          points_status: source.points_status,
        };

        acc.flow_of_work_tasks_by_pace.data_points.push(tasksDataPoint);
        acc.flow_of_work_points_by_pace.data_points.push(pointsDataPoint);

        return acc;
      },
      {
        flow_of_work_tasks_by_pace: { data_points: [] },
        flow_of_work_points_by_pace: { data_points: [] },
      } as WorkPeriodBurnsAndFlowsByPace,
    );

  return result;
};

/**
 * Transforms the `BurnsDataOverTime` object into a sorted array of
 * `BurnsData` objects.
 *
 * @param {BurnsDataOverTime | undefined} data - The data to be transformed.
 * @return {BurnsData[]} The transformed data.
 */
const formatBurnsData = (data?: BurnsDataOverTime): BurnsData[] => {
  if (!data) {
    return [];
  }

  const result = Object.keys(data)
    .toSorted((a, b) => dayjs(a).diff(dayjs(b)))
    .map((key) => data[key]);

  return result;
};

/**
 * Splices the data points of a given object by a specified day.
 *
 * @template T - The type of the object containing data points.
 * @param {T} data - The object containing data points to be spliced.
 * @param {string | null} day - The day by which the data points should be spliced.
 * @return {T} - The object with spliced data points.
 */
const spliceByDay = <T extends { data_points: object[] }>(data: T, day: string | null): T => {
  return {
    ...data,
    data_points: data.data_points.toSpliced(Number(day)),
  };
};

export {
  calculateMeasurePercentage,
  formatBurnsData,
  formatFlowByPaceData,
  formatFlowByPhaseData,
  getDownloadLabel,
  spliceByDay,
};
