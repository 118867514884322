import { Fragment } from 'react/jsx-runtime';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { useAssessmentViewData } from './assessment-view.hooks';
import { SectionDelivery } from './section-delivery';
import { SectionKeyMeasures } from './section-key-measures';
import { SectionPace } from './section-pace';
import { SectionPhase } from './section-phase';

export const AssessmentView = () => {
  const { scoreData, keyMeasuresData, flowData, isLoading } = useAssessmentViewData();

  const score = scoreData?.health_scores?.score_by_points || 0;

  return (
    <Fragment>
      <BrandedLoadingOverlay visible={isLoading} transitionDuration={30} variant="colored" />
      <SectionKeyMeasures score={score} data={keyMeasuresData} />
      {flowData && (
        <>
          <SectionPhase data={{ flow_by_phase: flowData.flow_by_phase, flow_by_pace: flowData.flow_by_pace }} />
          <SectionPace data={flowData.flow_by_pace} />
          <SectionDelivery data={{ burns: flowData.burns, historical_burns: flowData.historical_burns }} />
        </>
      )}
    </Fragment>
  );
};
