import { styled } from '@linaria/react';
import { Flex } from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMeasureDetail } from '../../api/work-periods-client/work-periods-client.hooks';
import { Measure } from '../../api/work-periods-client/work-periods-client.type';
import { formatTasks } from '../../components/financials-tasks/financials-tasks.helpers';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { MeasureInfoBox } from '../../components/measure-info-box';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { Title } from '../../components/title/title';
import { useGlobalStore } from '../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import {
  filterCustomMeasures,
  filterUnwantedMeasures,
  useDateRange,
  useTimeAllocations,
} from '../../store/process-analysis-store/process-analysis-store.hooks';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { TasksTable } from '../tasks-table/tasks-table';
import { TaskRecord } from '../tasks-table/tasks-table.type';
import { formatTitle, mapSubprojectIdsToNames } from './process-analysis-tasks.helpers';
import { useTasksData, useTimeAllocationsData } from './process-analysis.hooks';
import { Tab, WorkPeriodType } from './process-analysis.type';
import { useWorkPeriodForm } from './scope-explorer/scope-explorer.hooks';

export const ProcessAnalysisTasks = () => {
  const [searchParams] = useSearchParams();
  const measure = searchParams.get('measure');
  const date = searchParams.get('date') || null;
  const factor = searchParams.get('factor') || null;
  const workPeriodId = searchParams.get('work_period_id') || null;
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures);
  const customMeasures = availableMeasures
    .filter(filterCustomMeasures)
    .filter(filterUnwantedMeasures)
    .map((measure) => measure.measure_name);

  const teams = useGlobalStore((state) => state.teams);
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);

  const { workPeriodType, workPeriod } = useProcessAnalysisStore((state) => ({
    workPeriodType: state.workPeriodType,
    workPeriod: state.workPeriod,
  }));
  const { startDate, endDate } = useDateRange();

  const idNameMap = mapSubprojectIdsToNames(teams);
  const { workPeriodForm } = useWorkPeriodForm();
  const currentBoardId = workPeriodForm.getValues().workPeriodBoardId;
  const currentBoardName = idNameMap[currentBoardId as string];

  useTimeAllocationsData();
  const timeAllocations = useTimeAllocations();
  const foundAllocation = timeAllocations.find((allocation) => allocation.id === date);
  const formattedStartDate = dayjs(startDate).format('MM/DD/YYYY');
  const formattedEndDate = dayjs(endDate).format('MM/DD/YYYY');

  const taskFilter = date && factor ? `${date},${factor}` : date || factor;
  const isCustom =
    customMeasures.includes(measure as Measure) || !availableMeasures.find((m) => m.measure_name === measure);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data: tasksQueryData = [], isFetching } = useTasksData(
    measure as Measure,
    taskFilter,
    isCustom,
    workPeriodId,
  );
  const { data: measureDetailsData, query: measureDetailsQuery } = useMeasureDetail({
    measureName: measure as string,
    options: {
      enabled: !!measure,
      staleTime: Infinity,
    },
  });

  useEffect(() => {
    if (!isFetching && !tasksQueryData.length) {
      setErrorMessage('There are no related tasks for this selection.');
    }
  }, [tasksQueryData, isFetching]);

  useEffect(() => {
    if (measureDetailsQuery.isError) {
      setErrorMessage('Failed to load measure details');
    }
  }, [measureDetailsQuery]);

  const titleMeasure = measure === Measure.FlowByPhase ? factor || '' : measure || '';
  const header = <Title title={formatTitle(titleMeasure, workPeriodType, workPeriod, currentBoardName, activeTab)} />;

  const dateRange =
    date == null ? (
      <>
        {workPeriodType === WorkPeriodType.Defined
          ? `${dayjs(workPeriod?.start_date).format('MM/DD/YYYY')} - ${dayjs(workPeriod?.end_date).format(
              'MM/DD/YYYY',
            )}`
          : `${formattedStartDate} - ${formattedEndDate}`}
      </>
    ) : activeTab === Tab.WorkPeriods ? (
      `${dayjs(date).format('MM/DD/YYYY')}`
    ) : (
      `${dayjs(foundAllocation?.start).format('MM/DD/YYYY')} - ${dayjs(foundAllocation?.end)
        .subtract(1, 'day')
        .format('MM/DD/YYYY')}`
    );

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay visible={isFetching} transitionDuration={30} variant="colored" />
      <Flex direction="column" gap={16} h="100%">
        {errorMessage && <PageHeaderMessage message={errorMessage} color="red" />}
        {measureDetailsData && <MeasureInfoBox measure={measureDetailsData} />}
        <TasksContainer>
          <TasksTable tasks={formatTasks(tasksQueryData as TaskRecord[])} dateRange={dateRange} />
        </TasksContainer>
      </Flex>
    </MenuDrivenContainer>
  );
};

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;
