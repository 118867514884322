import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryClipContainer, VictoryLabel, VictoryStack } from 'victory';
import { SprintMetricsCumulativeFlow } from '../../api/sprints-client/sprints-client.type';
import { WorkPeriodBurnsAndFlows } from '../../containers/process-analysis/assessment-view/assessment-view.type';
import { handleSVGasImageDownload } from '../../helpers/image-downloader/image-downloader';
import { newCOLORS } from '../../styles/colors';
import { TitleHeadingTag } from '../../styles/shared-styled-components';
import { BurnFlowChartLegend } from './burn-flow-chart-legend';
import {
  calculateMaximumY,
  calculateWeekendAreas,
  createCumulativeFlowAggregatedData,
  getDataPoints,
  getDisplayDateXAxisValues,
  mapFlowData,
} from './burn-flow-chart.helpers';
import { useViewportHeight } from './burn-flow-chart.hooks';
import { CumulativeFlowAggregatedDataType, ViewByType } from './burn-flow-chart.type';

export type CumulativeFlowChartProps = {
  cumulative_flow_points: SprintMetricsCumulativeFlow;
  cumulative_flow_tasks: SprintMetricsCumulativeFlow;
  burnsAndFlows: WorkPeriodBurnsAndFlows;
  legend?: boolean;
  header?: boolean;
  dataType?: ViewByType;
  downloadFileName?: string;
};

export const CumulativeFlowChart = ({
  cumulative_flow_points,
  cumulative_flow_tasks,
  burnsAndFlows,
  legend = true,
  header = true,
  dataType,
  downloadFileName,
}: CumulativeFlowChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const viewportHeight = useViewportHeight();
  const [chartData, setChartData] = useState<CumulativeFlowAggregatedDataType[]>([]);
  const [maximumY, setMaximumY] = useState<number>(0);

  useEffect(() => {
    const cumulativeData = mapFlowData(dataType === ViewByType.tasks ? cumulative_flow_tasks : cumulative_flow_points);
    const cumulativeFlowAggregatedData = createCumulativeFlowAggregatedData(cumulativeData);

    setChartData(cumulativeFlowAggregatedData);

    const maxY = calculateMaximumY(cumulativeFlowAggregatedData);
    setMaximumY(maxY);
  }, [dataType, cumulative_flow_points, cumulative_flow_tasks]);

  const xAxisTickValues =
    dataType === ViewByType.tasks
      ? getDisplayDateXAxisValues(burnsAndFlows?.flows_tasks?.data_points)
      : getDisplayDateXAxisValues(burnsAndFlows?.flows_points?.data_points);

  const weekendAreas = calculateWeekendAreas(getDataPoints(burnsAndFlows), Math.ceil(maximumY / 10) * 10);

  return (
    <Fragment>
      {header && downloadFileName && (
        <CumulativeFlowHeadingContainer>
          <ChartHeading>
            <TitleHeadingTag>Burn Flow</TitleHeadingTag>
          </ChartHeading>
          <BurnFlowHeadingText>
            <Icon
              style={{ marginLeft: 'auto', paddingRight: '10px', cursor: 'pointer' }}
              width="32"
              height="32"
              onClick={() => handleSVGasImageDownload(chartRef, 0, downloadFileName, `Burn Flow ${dataType}`)}
              icon="material-symbols:image-outline"
            />
          </BurnFlowHeadingText>
        </CumulativeFlowHeadingContainer>
      )}

      {cumulative_flow_points.data_points.length > 0 && cumulative_flow_tasks.data_points.length > 0 && (
        <ChartWithLegend ref={chartRef}>
          <Chart legend={legend}>
            <VictoryChart
              domainPadding={0}
              height={viewportHeight}
              width={viewportHeight * 2.42}
              padding={{ top: 10, bottom: 80, left: 75, right: 75 }}
            >
              {
                <VictoryAxis
                  tickCount={12}
                  orientation="bottom"
                  style={{
                    grid: { stroke: 'transparent' },
                    axis: { stroke: `${newCOLORS.gray}`, margin: 30 },
                    tickLabels: { fontSize: 21 },
                    axisLabel: { fontSize: 26 },
                  }}
                  tickValues={xAxisTickValues}
                  axisLabelComponent={<VictoryLabel dy={25} />}
                />
              }
              {
                <VictoryAxis
                  dependentAxis
                  crossAxis
                  orientation="left"
                  label={dataType === ViewByType.points ? 'Story Points' : 'Number of Tasks'}
                  tickFormat={(tick: number) => `${tick}`}
                  style={{
                    grid: { stroke: `${newCOLORS.gray}` },
                    axis: { stroke: `${newCOLORS.gray}` },
                    tickLabels: { fontSize: 21 },
                    axisLabel: { fontSize: 26 },
                  }}
                  axisLabelComponent={<VictoryLabel dy={-25} />}
                />
              }
              {weekendAreas.map((area, index) => (
                <VictoryArea
                  key={index}
                  style={{
                    data: {
                      fill: newCOLORS.lighterGray,
                      fillOpacity: 0.6,
                    },
                  }}
                  data={area}
                />
              ))}
              <VictoryStack>
                {chartData.map((object) => (
                  <VictoryArea
                    domain={{ y: [0, maximumY + 5] }} // pad the maximum Y value to give a little breathing room at the top of the chart
                    key={object.title}
                    interpolation="monotoneX"
                    data={object.data}
                    style={{
                      data: {
                        fill: `${object.color}`,
                        fillOpacity: 0.7,
                        stroke: `${object.color}`,
                        strokeWidth: 2,
                      },
                    }}
                    groupComponent={<VictoryClipContainer clipPadding={{ top: 5, bottom: 5 }} />}
                  />
                ))}
              </VictoryStack>
            </VictoryChart>
          </Chart>

          {legend && (
            <Legend>
              <BurnFlowChartLegend />
            </Legend>
          )}
        </ChartWithLegend>
      )}
    </Fragment>
  );
};

const CumulativeFlowHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const ChartHeading = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const BurnFlowHeadingText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

const ChartWithLegend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Chart = styled.div<{ legend: boolean }>`
  width: ${(props: { legend: boolean }) => (props.legend ? '85%' : '100%')};
`;

const Legend = styled.div`
  width: 15%;
  height: 40%;
`;
