import { styled } from '@linaria/react';
import { useEffect, useState } from 'react';
import { ViewByType } from '../../../components/burn-flow-chart/burn-flow-chart.type';
import { BurnUpChart } from '../../../components/burn-up-chart/burn-up-chart';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  useDefaultDay,
  useHistoricalBurns,
  useWorkPeriodLength,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { ContentTag, HeadingTag } from '../../../styles/shared-styled-components';
import { WorkPeriodType } from '../process-analysis.type';
import { formatBurnsData } from './assessment-view.helpers';
import { AssessmentChart, DeliveryConfidenceData, Metric } from './assessment-view.type';
import { ChartContainer } from './chart-container/chart-container';
import { getHistoricalBurnsBand } from './section-delivery.helpers';

type Props = {
  data: DeliveryConfidenceData;
};

export const SectionDelivery = ({ data }: Props) => {
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriodLength = useWorkPeriodLength();
  const showHistoricalBurns = useHistoricalBurns();
  const defaultDay = useDefaultDay();

  const [metric, setMetric] = useState<Metric>(Metric.Tasks);
  const [activeDay, setActiveDay] = useState<string | null>(defaultDay);

  useEffect(() => {
    setActiveDay(defaultDay);
  }, [defaultDay]);

  const title = (
    <>
      <LargeHeadingTag>Flow of work, delivery confidence</LargeHeadingTag>
      <ContentTag>
        How work in this work period is or isn't keeping up with needed pace, based on historical performance.
      </ContentTag>
    </>
  );

  return (
    <ChartContainer
      chartId={AssessmentChart.Delivery}
      title={title}
      day={activeDay}
      setDay={setActiveDay}
      showDaySelect={workPeriodType === WorkPeriodType.Defined}
      metric={metric}
      setMetric={setMetric}
      data={data}
    >
      <DayDetailsBox>
        <LargeHeadingTag>
          {`Day ${activeDay}`} of {workPeriodLength}
        </LargeHeadingTag>
      </DayDetailsBox>
      <BurnUpChart
        burnsUp={formatBurnsData(data.burns)}
        showHistoricalBurns={showHistoricalBurns}
        historicalBurnsBand={getHistoricalBurnsBand(data.historical_burns, metric, Number(activeDay))}
        dayOfSprint={Number(activeDay)}
        viewByType={metric as unknown as ViewByType}
      />
    </ChartContainer>
  );
};

const LargeHeadingTag = styled(HeadingTag)`
  font-size: 20px;
`;

const DayDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
