import { TextInput, TextInputProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { skyBase, skyDark } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';

interface Props extends TextInputProps {
  value: string;
  isOpen: boolean;
  canEdit: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onSave: (value: string) => void;
  onCancel: () => void;
  error?: string | boolean;
}

export function EditableTextField(props: Props) {
  const [isOpen, toggle] = useDisclosure(props.isOpen);
  const [tempValue, setTempValue] = useState(props.value);
  return (
    <>
      {isOpen && props.canEdit ? (
        <TextInput
          value={isOpen ? tempValue : props.value}
          onChange={(e) => {
            setTempValue(e.currentTarget.value);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
          onKeyDown={props.onKeyDown}
          error={props.error}
          autoFocus
          size="md"
          radius="xl"
          style={{ width: '300px', height: '24px', color: skyBase }}
          disabled={props.disabled}
          rightSection={
            props.error ? null : (
              <Icon
                name="check_circle"
                variant="outlined"
                size={20}
                color={skyDark}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  props.onSave(tempValue);
                  toggle.close();
                }}
              />
            )
          }
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Text weight="bold" size="large">
            {props.value}
          </Text>
          {props.canEdit ? (
            <Icon name="edit" size={20} color={skyDark} style={{ cursor: 'pointer' }} onClick={toggle.open} />
          ) : null}
        </div>
      )}
    </>
  );
}
