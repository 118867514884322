import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';
import { Tabs } from '@mantine/core';
import { useRef, useState } from 'react';
import { DownloadPNGButton } from '../../components/download-png-button';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { canvasHtmlDownload } from '../../helpers/image-downloader/image-downloader';
import { newCOLORS } from '../../styles/colors';
import { SubSectionTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { icons } from '../tasks-table/assets';
import { glossaryContent } from './help.content';
import styles from './help.module.css';
import { GlossaryContent } from './help.type';

const handleDivClick = (url: string) => {
  window.open(url, '_blank', 'noopener, noreferrer');
};

const ChangelogPreview = ({ url }: { url: string }) => {
  return (
    <div className={styles.previewContainer} onClick={() => handleDivClick(url)}>
      <div className={styles.previewTextContainer}>
        <PreviewText>
          <img
            alt="icon"
            src={icons.iconShare}
            style={{
              marginTop: '24px',
              marginBottom: '24px',
              marginLeft: '24px',
              marginRight: '14px',
              width: '28px',
              height: '28px',
            }}
          />{' '}
          Keep up to date with the latest Bloomfilter product updates.
        </PreviewText>
      </div>
    </div>
  );
};
const BlogPreview = ({ url }: { url: string }) => {
  return (
    <div className={styles.previewContainer} onClick={() => handleDivClick(url)}>
      <div className={styles.previewTextContainer}>
        <PreviewText>
          <img
            alt="icon"
            src={icons.iconShare}
            style={{
              marginTop: '24px',
              marginBottom: '24px',
              marginLeft: '24px',
              marginRight: '14px',
              width: '28px',
              height: '28px',
            }}
          />{' '}
          Get more insights and perspectives from our blog.
        </PreviewText>
      </div>
    </div>
  );
};
const GlossaryItem = (props: GlossaryContent) => {
  return (
    <div className={styles.glossaryItem}>
      <div className={styles.glossaryItemHeader}>
        <SubSectionTag className={styles.firstItem}>{props.name}</SubSectionTag>
        <div>{props.pageName}</div>
      </div>
      <div>{props.description}</div>
    </div>
  );
};

export const Help = () => {
  useDocumentTitle('Help - Bloomfilter');

  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState('Changelog');

  return (
    <MenuDrivenContainer header="Help">
      <HeadingContainer>
        <div ref={exceptionRef}>
          {activeTab === 'glossary' && (
            <DownloadPNGButton
              handleDownload={() => canvasHtmlDownload('Glossary', downloadRef, exceptionRef)}
              exceptionRef={exceptionRef}
            />
          )}
        </div>
      </HeadingContainer>
      <div className={styles.helpContentContainer}>
        <div className={styles.helpItemContainer}>
          <Tabs
            value={activeTab}
            variant="outline"
            className={styles.tabParent}
            onChange={(value) => {
              if (value !== null) {
                setActiveTab(value);
              }
            }}
          >
            <Tabs.List>
              <Tabs.Tab value="Changelog" className={styles.tab}>
                Changelog
              </Tabs.Tab>
              <Tabs.Tab value="glossary" className={styles.tab}>
                Glossary
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="Changelog" className={styles.tabPanel}>
              <ChangelogPreview url="https://www.thebloomfilter.com/changelog" />
              <BlogPreview url="https://www.thebloomfilter.com/blog" />
            </Tabs.Panel>
            <Tabs.Panel value="glossary" className={styles.tabPanel}>
              <div className={styles.glossaryContainer} ref={downloadRef}>
                {glossaryContent.map((glossaryTerm) => (
                  <GlossaryItem {...glossaryTerm} key={`${glossaryTerm.pageName}_${glossaryTerm.name}`} />
                ))}
              </div>
            </Tabs.Panel>
          </Tabs>
        </div>
        <div className={styles.contactContainer}>
          <div className={styles.contactHeader}>
            <FontAwesomeIcon style={{ color: newCOLORS.darkGray }} size="2x" icon={faMessage} />
            <h3>Get in Touch</h3>
          </div>
          <p className={styles.contactBlurb}>
            If you’re having any issues getting up and running, understanding how to use Bloomfilter, or experiencing
            problems with the platform please reach out to our Customer Success team.
          </p>
          <a href="mailto: support@bloomfilter.app">support@bloomfilter.app</a>
        </div>
      </div>
    </MenuDrivenContainer>
  );
};

const HeadingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PreviewText = styled.p`
  display: flex;
  align-items: center;
  color: ${newCOLORS.darkPurple};
  font-size: 24px;
`;
