import { styled } from '@linaria/react';
import { Title } from '@mantine/core';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { MeasureMetadata, MeasureUnits, WorkPeriod } from '../../../api/work-periods-client/work-periods-client.type';
import { DownloadPNGButton } from '../../../components/download-png-button';
import { downloadCSV } from '../../../helpers/csv-download';
import { newCOLORS } from '../../../styles/colors';
import { MeasureLabel } from '../measure-label';
import { getUnitsLabel } from '../process-analysis.helpers';
import { Table } from '../table/table';
import { getLastNotNullValue } from './sprint-comparison-view.helpers';

interface SprintComparisonTableProps {
  customMeasures: MeasureMetadata[];
  measureData: Record<string, any>;
  workPeriods: WorkPeriod[];
}

export const SprintComparisonTable = ({ customMeasures, measureData, workPeriods }: SprintComparisonTableProps) => {
  const downloadRef = useRef<HTMLDivElement>(null);

  const formatDate = (date: string) => dayjs(date).format('MMM D');

  const columnHeaders = workPeriods.map((workPeriod) => (
    <HeaderContainer key={workPeriod.id}>
      <SprintName>{workPeriod.name}</SprintName>
      <DateRange>
        {formatDate(workPeriod.start_date)} - {formatDate(workPeriod.end_date)}
      </DateRange>
    </HeaderContainer>
  ));

  const getLastValue = (timeSeriesData: Record<string, number> | undefined | null, measureUnits: MeasureUnits) => {
    const lastValue = getLastNotNullValue(timeSeriesData);
    return lastValue !== null ? (measureUnits === 'Percentage' ? lastValue.toFixed(1) : lastValue.toFixed(0)) : '-';
  };

  const rows = customMeasures.map((measure) => [
    <GridCell key="title">
      <MeasureLabel label={measure.measure_title} tooltip={measure.measure_description} />
    </GridCell>,
    <GridCell key="average">
      {(() => {
        const values = workPeriods
          .map((workPeriod) => {
            const value = measureData[workPeriod.id]?.[measure.measure_name];
            if (!value) return null;
            const lastValue = getLastValue(value, measure.measure_units);
            return lastValue === '-' ? null : parseFloat(lastValue);
          })
          .filter((v): v is number => v !== null);

        if (values.length === 0) return '-';
        const avg = values.reduce((a, b) => a + b, 0) / values.length;
        return measure.measure_units === 'Percentage' ? avg.toFixed(1) : avg.toFixed(0);
      })()}
      <UnitLabel>{getUnitsLabel(measure.measure_units)}</UnitLabel>
    </GridCell>,
    ...workPeriods.map((workPeriod) => (
      <GridCell key={workPeriod.id}>
        {getLastValue(measureData[workPeriod.id]?.[measure.measure_name], measure.measure_units)}
        <UnitLabel>{getUnitsLabel(measure.measure_units)}</UnitLabel>
      </GridCell>
    )),
  ]);

  const handleDownload = () => {
    try {
      const csvData = [
        ['Measure', ...workPeriods.map((wp) => wp.name)],
        ...customMeasures.map((measure) => [
          measure.measure_title,
          ...workPeriods.map((workPeriod) =>
            getLastValue(measureData[workPeriod.id]?.[measure.measure_name], measure.measure_units),
          ),
        ]),
      ];

      downloadCSV(csvData, 'sprint-comparison.csv');
    } catch (error) {
      console.error('Error generating CSV data:', error);
    }
  };

  return (
    <DropShadowContainer>
      <TitleContainer>
        <Title order={2}>Sprint Comparison</Title>
        <DownloadPNGButton handleDownload={handleDownload} exceptionRef={downloadRef} />
      </TitleContainer>
      <Table
        label="Measure"
        columnHeaders={[<SprintName key="average">Average</SprintName>, ...columnHeaders]}
        rows={rows}
      />
    </DropShadowContainer>
  );
};

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 8px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SprintName = styled.div`
  font-weight: 600;
  color: ${newCOLORS.darkGray};
`;

const DateRange = styled.div`
  font-size: 12px;
  color: ${newCOLORS.gray};
  font-weight: 500;
`;

const GridCell = styled.div`
  display: flex;
  align-items: end;
  gap: 4px;
  color: ${newCOLORS.darkGray};
  background-color: ${newCOLORS.white};
  text-align: left;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
`;

const DropShadowContainer = styled.div`
  position: relative;
  height: fit-content;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
`;

const UnitLabel = styled.div`
  color: ${newCOLORS.gray};
  background-color: ${newCOLORS.white};
  text-align: left;
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
`;
