import { styled } from '@linaria/react';
import { inkLight } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

export const MeasureInfoBoxButton = ({ isOpen, onClick }: Props) => {
  return (
    <Container
      onClick={onClick}
      data-testid="measure-info-box-toggle"
      role="button"
      aria-label={`${isOpen ? 'Collapse' : 'Expand'} measure info box`}
      aria-expanded={isOpen}
    >
      <Icon name={isOpen ? 'unfold_less' : 'unfold_more'} color={inkLight} size={14} style={{ cursor: 'pointer' }} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 30px;
  border-radius: 32px;
  cursor: pointer;
  border: 1px solid var(--sky-base);

  &:hover {
    border: 1px solid var(--secondary-base);
  }
`;
