import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { createRoot } from 'react-dom/client';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@material-design-icons/font/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'reactflow/dist/style.css';
import './assets/fonts/fonts.css';
import './styles/design-tokens.css';
import './styles/index.css';

import { App } from './containers/app/app';
import { trackEvent } from './helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from './helpers/analytics-event/analytics-event.type';

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  tracesSampleRate: 1.0,

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,

  environment: import.meta.env.VITE_APP_ENV_NAME,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

const mixpanelKey = import.meta.env.VITE_APP_MIXPANEL_KEY || false;
if (mixpanelKey) {
  mixpanel.init(mixpanelKey, {
    ignore_dnt: true,
    loaded: () => {
      trackEvent(AnalyticsEventType.OpenApp, { userContext: {} });
    },
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
