import { Group } from '@mantine/core';
import { QueryObserverResult } from '@tanstack/react-query';
import { PortfolioStatusesByProjectsAndBoards } from '../../../../../../api/portfolio-client/portfolio-client.type';
import { Workflow, WorkflowPayload } from '../../../../../../api/workflows-client/workflows-client.type';
import { Button } from '../../../../../../ui-library/button/button';
import { Icon } from '../../../../../../ui-library/icon/icon';
import { Text } from '../../../../../../ui-library/typography/typography';
import { EditableTextField } from '../../../../editable-text-field';
import { WorkflowAction } from '../../shared-components/reducer';
import { HeaderContainer } from '../../shared-components/styles';

export function CreateWorkflowHeader({
  state,
  dispatch,
  setPageState,
  isDirty,
  handleSelectWorkflow,
  statusesByProjectsAndBoards,
  workflows,
  createWorkflow,
  workflowsQuery,
}: {
  state: Workflow;
  dispatch: React.Dispatch<WorkflowAction>;
  setPageState: (pageState: 'update' | 'create' | 'idle') => void;
  isDirty: boolean;
  handleSelectWorkflow: (
    workflow: Workflow,
    statusesByProjectsAndBoards: PortfolioStatusesByProjectsAndBoards | undefined,
    dispatch: React.Dispatch<WorkflowAction>,
  ) => void;
  statusesByProjectsAndBoards: PortfolioStatusesByProjectsAndBoards | undefined;
  workflows: Workflow[];
  createWorkflow: (payload: WorkflowPayload) => void;
  workflowsQuery: QueryObserverResult<Workflow[], Error>;
}) {
  return (
    <HeaderContainer>
      <EditableTextField
        value={state.name}
        canEdit
        isOpen
        onSave={(value: string) => {
          dispatch({
            type: 'UPDATE_FIELD',
            field: 'name',
            value,
          });
        }}
        onCancel={() => {}}
      />
      <Group>
        <Button
          variant="outline"
          size="xs"
          radius="xl"
          style={{ padding: '8px 16px', gap: '8px' }}
          onClick={() => {
            if (isDirty) {
              if (
                window.confirm(
                  "This page is asking you to confirm that you want to leave - information you've entered may not be saved.",
                )
              ) {
                const selectedWorkflow = workflows?.find((workflow) => workflow.id === state.id);
                dispatch({
                  type: 'SET_WORKFLOW',
                  payload: selectedWorkflow ?? state,
                });
                setPageState('idle');
                if (selectedWorkflow) {
                  handleSelectWorkflow(selectedWorkflow, statusesByProjectsAndBoards, dispatch);
                }
              }
            } else {
              const selectedWorkflow = workflows?.find((workflow) => workflow.id === state.id);
              dispatch({
                type: 'SET_WORKFLOW',
                payload: selectedWorkflow ?? state,
              });
              setPageState('idle');
              if (selectedWorkflow) {
                handleSelectWorkflow(selectedWorkflow, statusesByProjectsAndBoards, dispatch);
              }
            }
          }}
        >
          <Text>Cancel</Text>
        </Button>
        <Button
          variant="primary"
          size="xs"
          radius="xl"
          leftSection={<Icon name="check_circle_outline" variant="filled" size={16} color="white" />}
          style={{ padding: '8px 16px', gap: '8px' }}
          onClick={() => {
            createWorkflow(state);
            workflowsQuery.refetch();
            setPageState('idle');
          }}
        >
          <Text>Save</Text>
        </Button>
      </Group>
    </HeaderContainer>
  );
}
