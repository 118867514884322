import { Radio } from '@mantine/core';
import * as Sentry from '@sentry/browser';
import { Dispatch, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../../components/page-header-message/page-header-message';
import { UserContext } from '../../../contexts';
import { trackEvent } from '../../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../../helpers/analytics-event/analytics-event.type';
import { useBasicAuth, useHandleErrors, useRedirect } from '../integrations.hooks';
import styles from '../views/views.module.css';
import { useOauth } from './github.hooks';
import { GithubReducerAction, GithubState } from './github.type';

type OrganizationsProps = {
  state: GithubState;
  dispatch: Dispatch<GithubReducerAction>;
};

/** The view for selecting organization during the github integration workflow.
 *
 * @param props: { state: GithubState, dispatch: Dispatch<GithubReducerAction> }
 * @returns JSX.Element
 */
export function Organizations({ state, dispatch }: OrganizationsProps) {
  const { user } = useContext(UserContext);
  const { redirect } = useRedirect();
  const [organizations, setOrganizations] = useState<string[]>([]);

  const { options } = state;

  const organizationKeys = useMemo(() => {
    return Object.keys(options?.organizationWithRepos ?? {});
  }, [options?.organizationWithRepos]);

  useEffect(() => {
    setOrganizations(organizationKeys);
  }, [organizationKeys]);

  useHandleErrors();

  const onSuccess = useCallback(
    (data: Record<string, unknown>) => {
      dispatch({ type: 'options', payload: { organizationWithRepos: data } });
      dispatch({ type: 'init' });
      trackEvent(AnalyticsEventType.GithubIntegrationTapped, { userContext: user });
    },
    [dispatch, user],
  );

  const { completeOauthQuery } = useOauth({ onSuccess });
  useBasicAuth({ service: ExternalService.Github, onSuccess });

  /** Handles the change of the org selection.
   *
   * @param org: string - the selected org
   */
  function handleChange(org: string) {
    try {
      dispatch({ type: 'organization', payload: org });
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  return (
    <div id={styles.viewContainer}>
      <BrandedLoadingOverlay visible={organizations && completeOauthQuery.isLoading} variant="colored" />
      {!completeOauthQuery.isLoading && organizations.length === 0 ? (
        <PageHeaderMessage
          message="We couldn't find any organizations for your account."
          color="yellow"
          navigationMessage="Make sure you've installed our Github app for your organization."
          navigationPath="https://github.com/marketplace/bloomfilter-platform"
        />
      ) : (
        <>
          {!redirect && (
            <>
              <h4>Select a organization whose repository you would like to integrate with:</h4>
              <div id={styles.grid}>
                {(organizations || []).map((org: string) => (
                  <div
                    key={org}
                    className={
                      org === state.organization
                        ? `${styles.checkboxContainer} ${styles.checkboxContainerChecked}`
                        : styles.checkboxContainer
                    }
                    onClick={() => handleChange(org)}
                  >
                    <Radio
                      color="red.5"
                      styles={{ label: { fontWeight: 600, marginLeft: '30px' } }}
                      value={org}
                      checked={org === state.organization}
                    />
                    <span className={styles.checkboxLabel}>{org}</span>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
