import dayjs from 'dayjs';
import {
  SprintMetricsCumulativeFlow,
  SprintMetricsCumulativeFlowDataPoint,
} from '../../api/sprints-client/sprints-client.type';
import {
  WorkPeriodBurnsAndFlows,
  WorkPeriodFlowDataPointWithWeekend,
} from '../../containers/process-analysis/assessment-view/assessment-view.type';
import { newCOLORS } from '../../styles/colors';
import {
  CumulativeFlowAggregatedDataType,
  CumulativeFlowDataPoints,
  LineScatterDataPoint,
} from './burn-flow-chart.type';

/**
 * Gets the appropriate data points based on the mode and type of burns and flows data
 *
 * @param sprintBurnsAndFlows - The burns and flows data for either a sprint or work period
 * @param mode - The mode indicating whether this is for a sprint or work period view
 * @returns An array of data points specific to the mode and data type, or empty array if no match
 */
const getDataPoints = (sprintBurnsAndFlows: WorkPeriodBurnsAndFlows): WorkPeriodFlowDataPointWithWeekend[] => {
  return sprintBurnsAndFlows.flows_points.data_points || [];
};

/**
 * Calculates areas representing weekends and holidays for display on a chart
 *
 * @param {WorkPeriodFlowDataPointWithWeekend[]} dataPoints - Array of data points containing date and weekend/holiday status
 * @param {number} height - The height value to use for the area segments
 * @returns {Array<Array<{x: string, y: number}>>} Array of area segments, where each segment is an array of coordinate points
 *                                                 representing a continuous weekend/holiday period
 */
const calculateWeekendAreas = (
  dataPoints: WorkPeriodFlowDataPointWithWeekend[],
  height: number,
): { x: string; y: number }[][] => {
  const weekendHolidayVictoryAreaData = [];

  let oneSegmentData: { x: string; y: number }[] = [];
  let beforeDayStatus = false;

  dataPoints.forEach((point: WorkPeriodFlowDataPointWithWeekend) => {
    if (point.weekend_or_holiday === true) {
      oneSegmentData.push({ x: dayjs(point.date).format('MM/DD'), y: height });
    }
    if (point.weekend_or_holiday === false && beforeDayStatus === true) {
      oneSegmentData.push({ x: dayjs(point.date).format('MM/DD'), y: height });
      weekendHolidayVictoryAreaData.push(oneSegmentData);
      oneSegmentData = [];
    }
    beforeDayStatus = point.weekend_or_holiday;
  });

  if (oneSegmentData.length) {
    weekendHolidayVictoryAreaData.push(oneSegmentData);
  }

  return weekendHolidayVictoryAreaData;
};

/**
 * Converts sprint metrics data points into line/scatter chart data points
 *
 * @param {SprintMetricsCumulativeFlowDataPoint[]} points - Array of cumulative flow data points
 * @param {keyof SprintMetricsCumulativeFlowDataPoint} key - The metric key to extract from each data point
 * @returns {LineScatterDataPoint[]} Array of data points formatted for line/scatter charts, with null values for future dates
 */
const getLineScatterDataPoints = (
  points: SprintMetricsCumulativeFlowDataPoint[],
  key: keyof SprintMetricsCumulativeFlowDataPoint,
): LineScatterDataPoint[] => {
  return points.map((point) => {
    const fullDate = dayjs(point.date).startOf('day').toDate();
    const displayDate = dayjs(point.date).format('MM/DD');
    const today = new Date();

    if (fullDate <= today) {
      return { x: displayDate, y: point[key] as number };
    }

    return { x: displayDate, y: null };
  });
};

/**
 * Maps cumulative flow data into separate arrays for each workflow state
 *
 * @param {SprintMetricsCumulativeFlow} [data] - The cumulative flow data containing data points for each workflow state
 * @returns {CumulativeFlowDataPoints} Object containing arrays of data points for each workflow state
 */
const mapFlowData = (data?: SprintMetricsCumulativeFlow): CumulativeFlowDataPoints => {
  if (!data) {
    return {
      deployed: [],
      ready_for_deploy: [],
      in_test: [],
      in_review: [],
      in_progress: [],
      blocked: [],
      current_sprint: [],
    };
  }

  const points = data.data_points;

  return {
    deployed: getLineScatterDataPoints(points, 'done'),
    ready_for_deploy: getLineScatterDataPoints(points, 'deployable'),
    in_test: getLineScatterDataPoints(points, 'test'),
    in_review: getLineScatterDataPoints(points, 'review'),
    in_progress: getLineScatterDataPoints(points, 'wip'),
    blocked: getLineScatterDataPoints(points, 'blocked'),
    current_sprint: getLineScatterDataPoints(points, 'backlog'),
  };
};

/**
 * Creates aggregated data for cumulative flow chart
 *
 * @param {CumulativeFlowDataPoints} cumulativeData - The cumulative flow data points
 * @returns {CumulativeFlowAggregatedDataType[]} Array of aggregated data for each workflow state
 */
const createCumulativeFlowAggregatedData = (
  cumulativeData: CumulativeFlowDataPoints,
): CumulativeFlowAggregatedDataType[] => {
  return [
    {
      title: 'Work Done',
      data: cumulativeData.deployed,
      color: `${newCOLORS.green}`,
    },
    {
      title: 'Staging Ready',
      data: cumulativeData.ready_for_deploy,
      color: `${newCOLORS.aqua}`,
    },
    {
      title: 'In Test',
      data: cumulativeData.in_test,
      color: `${newCOLORS.pink}`,
    },
    {
      title: 'In Review',
      data: cumulativeData.in_review,
      color: `${newCOLORS.lightYellow}`,
    },
    {
      title: 'Work in Progress',
      data: cumulativeData.in_progress,
      color: `${newCOLORS.darkYellow}`,
    },
    {
      title: 'Blocked',
      data: cumulativeData.blocked,
      color: `${newCOLORS.coral}`,
    },
    {
      title: 'Committed Work',
      data: cumulativeData.current_sprint,
      color: `${newCOLORS.indigo}`,
    },
  ];
};

/**
 * Calculates the maximum Y value for a cumulative flow chart
 *
 * @param {CumulativeFlowAggregatedDataType[]} cumulativeFlowAggregatedData - The aggregated data for the cumulative flow chart
 * @returns {number} The maximum Y value
 */
const calculateMaximumY = (cumulativeFlowAggregatedData: CumulativeFlowAggregatedDataType[]): number => {
  const potentialMaximums = cumulativeFlowAggregatedData
    .flatMap(({ data }) => data)
    .reduce((dateBucket: { [key: string]: number }, dataPoint: LineScatterDataPoint) => {
      const { x, y } = dataPoint;
      if (y) {
        dateBucket[x] = (dateBucket[x] || 0) + y;
      }
      return dateBucket;
    }, {});

  return Object.values(potentialMaximums).length > 0 ? Math.max(...Object.values(potentialMaximums)) : 0;
};

/**
 * Retrieves display dates for the X-axis of a cumulative flow chart
 *
 * @param {SprintMetricsCumulativeFlowDataPoint[]} dataPoints - Array of cumulative flow data points
 * @returns {string[]} Array of display dates for the X-axis
 */
const getDisplayDateXAxisValues = (dataPoints: SprintMetricsCumulativeFlowDataPoint[]): string[] => {
  const dateValues = dataPoints.map((point: SprintMetricsCumulativeFlowDataPoint) => {
    const displayDate = dayjs(point.date).format('MM/DD');

    return displayDate;
  });

  return dateValues;
};

export {
  calculateMaximumY,
  calculateWeekendAreas,
  createCumulativeFlowAggregatedData,
  getDataPoints,
  getDisplayDateXAxisValues,
  mapFlowData,
};
