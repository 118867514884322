import { MultiSelectProps } from '@mantine/core';

const commonMultiSelectProps: Partial<MultiSelectProps> = {
  style: { minWidth: 200 },
  rightSectionPointerEvents: 'none',
  styles: {
    input: {
      paddingRight: 30,
    },
  },
};

export { commonMultiSelectProps };
