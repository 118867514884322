import { ActionIcon, Box, Card, Flex, Group, Stack, Tooltip } from '@mantine/core';
import React, { useState } from 'react';
import { Component } from '../../../api/pages-client/pages-client.type';
import { newCOLORS } from '../../../styles/colors';
import { skyDark } from '../../../styles/design-tokens';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';
import { DeleteConfirmationModal } from '../../data-management/components/delete-confirmation-modal';
import { getWidgetIcon } from '../dashboards.helpers';
import { ChartType, DATE_RANGES } from '../dashboards.types';
import { useWidgetData } from '../hooks/dashboards.hooks';
import { AddWidgetModal } from './add-widget-modal';
import { ChartWidget } from './chart-widget';
import { TableWidget } from './table-widget';
import { TileWidget } from './tile-widget';
interface DashboardWidgetProps {
  component: Component;
  onEdit: (updatedWidget: Component) => void;
  onDelete: () => void;
}

export const DashboardWidget: React.FC<DashboardWidgetProps> = ({ component, onEdit, onDelete }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Use the widget data hook for all widget types
  const { chartData, tableData, tileData, isLoading, error } = useWidgetData(
    component.component_data?.[0] ?? {
      id: 'default',
      measure: '',
      range_type: 'last_30_days',
      object_type: 'project',
      object_ids: [],
    },
  );

  if (isLoading) {
    return (
      <Card shadow="sm" radius="md" withBorder style={{ height: '100%', maxWidth: '98%' }}>
        <Flex justify="center" align="center" style={{ height: '100%' }}>
          <Text>Loading...</Text>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card shadow="sm" radius="md" withBorder style={{ height: '95%', maxWidth: '98%' }}>
        <Flex justify="center" align="center" style={{ height: '100%' }}>
          <Text color={newCOLORS.red}>Error loading data</Text>
        </Flex>
      </Card>
    );
  }

  const renderWidgetContent = () => {
    switch (component.type) {
      case 'chart':
        return <ChartWidget type={(component.sub_type as ChartType) || 'line'} data={chartData} />;
      case 'table':
        return <TableWidget data={tableData} />;
      case 'tile':
        return <TileWidget data={tileData} />;
      case 'metric':
        return (
          <Box>
            <Text size="regular">{tileData?.average?.toFixed(2) ?? 'N/A'}</Text>
            <Text size="small">Total: {tileData?.total?.toFixed(2) ?? 'N/A'}</Text>
          </Box>
        );
      default:
        return null;
    }
  };

  const handleSaveEdit = (updatedWidget: Component) => {
    onEdit(updatedWidget);
    setIsEditModalOpen(false);
  };

  const handleDelete = () => {
    onDelete();
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <Card shadow="md" radius="md" withBorder style={{ height: '95%', maxWidth: '99%' }}>
        <Card.Section inheritPadding py="sm">
          <Flex justify="space-between" align="center">
            {component.show_title ? (
              <Stack>
                <Flex align="center" gap={8}>
                  <Flex align="center" gap={6}>
                    <Icon name={getWidgetIcon(component.type, component.sub_type)} size={18} color={skyDark} />
                    <Text>{component.name}</Text>
                    <Tooltip label={component.description || 'No Description Provided'} position="right">
                      <ActionIcon variant="subtle" size="sm" radius="xl">
                        <Icon name="help_outline" size={16} />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                  <Group gap={12}>
                    <Tooltip label="Total of all values">
                      <Flex align="center" gap={4}>
                        <Icon name="functions" size={14} />
                        <Text size="tiny" color="dimmed">
                          Total: {tileData.total.toLocaleString()}
                        </Text>
                      </Flex>
                    </Tooltip>
                    <Tooltip label="Average value">
                      <Flex align="center" gap={4}>
                        <Icon name="timeline" size={14} />
                        <Text size="tiny" color="dimmed">
                          Avg: {tileData.average.toLocaleString()}
                        </Text>
                      </Flex>
                    </Tooltip>
                  </Group>
                </Flex>
                <Group>
                  <Text size="small" color="dimmed">
                    {DATE_RANGES.find((range) => range.value === component.component_data?.[0]?.range_type)?.label}
                  </Text>
                </Group>
              </Stack>
            ) : (
              <Box />
            )}
            <Group gap={4} style={{ alignSelf: 'flex-start' }}>
              <Tooltip label="Edit Widget">
                <ActionIcon
                  variant="subtle"
                  onClick={() => setIsEditModalOpen(true)}
                  size="sm"
                  radius="xl"
                  color={skyDark}
                >
                  <Icon name="edit" size={16} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Delete Widget">
                <ActionIcon
                  variant="subtle"
                  onClick={() => setIsDeleteModalOpen(true)}
                  size="sm"
                  radius="xl"
                  color="red"
                >
                  <Icon name="delete" size={16} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Flex>
        </Card.Section>
        <Card.Section inheritPadding py="md">
          {renderWidgetContent()}
        </Card.Section>
      </Card>

      <AddWidgetModal
        opened={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSave={handleSaveEdit}
        initialData={component}
        isEditing
      />

      <DeleteConfirmationModal
        itemName={component.name}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
        title="Delete Widget"
        isDeleting={false}
      />
    </>
  );
};
