import { styled } from '@linaria/react';
import { Box, Flex } from '@mantine/core';
import { skyDark } from '../../styles/design-tokens';
import { Text } from '../../ui-library/typography/typography';

type Props = {
  icon: React.ReactNode;
  title: string;
  description: string;
  expanded: boolean;
  style?: React.CSSProperties;
};

export const MeasureInfoBoxCard = ({ icon, title, description, expanded, style }: Props) => {
  const containerStyle = {
    ...style,
    padding: expanded ? 24 : 8,
  };

  return (
    <Container direction="column" justify="center" align="center" gap={8} style={containerStyle}>
      <Flex gap={8} align="center">
        {icon}
        {title}
      </Flex>
      {expanded && (
        <Box maw={520}>
          <Text size="small" color={skyDark}>
            {description}
          </Text>
        </Box>
      )}
    </Container>
  );
};

const Container = styled(Flex)`
  box-sizing: border-box;
  border: 1px solid var(--sky-light);
  background: var(--sky-tight);
  border-radius: var(--border-radius-small);
`;
