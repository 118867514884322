import { IntegrationType } from '../../../../api/integrations-client/integrations-client.type';

/**
 * Returns the display name for a given data source type
 * @param {IntegrationType | null} type - The integration type (JIRA, ADO, JDC, GITHUB) or null for all sources
 * @returns {string} The human-readable name of the data source
 */

function getDataSourceName(type: IntegrationType | null): string {
  switch (type) {
    case null:
      return 'All Data Sources';
    case IntegrationType.JIRA:
      return 'Atlassian Jira Software Cloud';
    case IntegrationType.ADO:
      return 'Microsoft Azure DevOps';
    case IntegrationType.JDC:
      return 'Jira Data Center';
    case IntegrationType.GITHUB:
      return 'Github';
    default:
      return 'Unknown Data Source';
  }
}

/**
 * Checks if the data source is up to date based on the last refresh time
 * @param {string | null} lastRefresh - The last refresh time
 * @returns {boolean} - True if the data source is up to date, false otherwise
 */
function isUpToDate(lastRefresh: string | null): boolean {
  if (!lastRefresh) return false;
  const lastRefreshDate = new Date(lastRefresh);
  const currentDate = new Date();
  const twentyFourHoursInMs = 24 * 60 * 60 * 1000;
  return currentDate.getTime() - lastRefreshDate.getTime() <= twentyFourHoursInMs;
}

/**
 * Checks if an project or board is considered active based on its last refresh time
 * @param {string | null} lastRefresh - The last refresh time
 * @returns {boolean} - True if the object was refreshed within the last 30 days, false otherwise
 */
function isProjectOrBoardActive(lastRefresh: string | null): boolean {
  if (!lastRefresh) return false;
  const lastRefreshDate = new Date(lastRefresh);
  const currentDate = new Date();
  const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
  return currentDate.getTime() - lastRefreshDate.getTime() <= thirtyDaysInMs;
}

export { getDataSourceName, isProjectOrBoardActive, isUpToDate };
