import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { Component, Page } from './pages-client.type';

const axiosInstance = AxiosInstance();
const pageBaseURL = import.meta.env.VITE_APP_API_URL + '/api/pages/';
const componentBaseURL = import.meta.env.VITE_APP_API_URL + '/api/components/';

/**
 * Fetches pages based on provided filters
 * @param organizationId - The ID of the organization to filter pages by
 * @param portfolioId - The ID of the portfolio to filter pages by
 * @param type - The type of pages to filter by
 * @param moduleType - The module type to filter pages by
 * @returns Promise containing the fetched pages data
 * @throws Will reject with error object if the API request fails
 */
async function fetchPages(organizationId: string, portfolioId: string, type: string, moduleType: string) {
  let apiURL = pageBaseURL + 'get_pages/?';

  if (organizationId) {
    apiURL += `organization=${organizationId}&`;
  }
  if (portfolioId) {
    apiURL += `portfolio=${portfolioId}&`;
  }
  if (type) {
    apiURL += `type=${type}&`;
  }
  if (moduleType) {
    apiURL += `module_type=${moduleType}`;
  }

  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
}

/**
 * Creates a new page
 * @param payload - The page data to create
 * @returns Promise containing the created page data
 * @throws Will reject with error object if the API request fails
 */
async function createPage(payload: Page) {
  const apiURL = pageBaseURL + 'create_page/';
  return await axiosInstance
    .post(apiURL, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
}

/**
 * Updates an existing page
 * @param id - The ID of the page to update
 * @param payload - The updated page data
 * @returns Promise containing the updated page data
 * @throws Will reject with error object if the API request fails
 */
async function updatePage(id: string, payload: Page) {
  const apiURL = pageBaseURL + id + '/edit_page/';
  return await axiosInstance
    .put(apiURL, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
}

/**
 * Deletes a page
 * @param id - The ID of the page to delete
 * @returns Promise containing the response data from the deletion
 * @throws Will reject with error object if the API request fails
 */
async function deletePage(id: string) {
  const apiURL = pageBaseURL + id + '/delete_page/';

  return await axiosInstance
    .delete(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
}

/**
 * Creates a new component
 * @param payload - The component data to create
 * @returns Promise containing the created component data
 * @throws Will reject with error object if the API request fails
 */
async function createComponent(payload: Component) {
  const apiURL = componentBaseURL + 'create_component/';
  return await axiosInstance
    .post(apiURL, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
}

/**
 * Updates an existing component
 * @param component_id - The ID of the component to update
 * @param payload - The updated component data
 * @returns Promise containing the updated component data
 * @throws Will reject with error object if the API request fails
 */
async function updateComponent(component_id: string, payload: Component) {
  const apiURL = componentBaseURL + component_id + '/edit/';
  return await axiosInstance
    .put(apiURL, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
}

/**
 * Deletes a component
 * @param component_id - The ID of the component to delete
 * @returns Promise containing the response data from the deletion
 * @throws Will reject with error object if the API request fails
 */
async function deleteComponent(component_id: string) {
  const apiURL = componentBaseURL + component_id + '/';
  return await axiosInstance
    .delete(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
}

export { createComponent, createPage, deleteComponent, deletePage, fetchPages, updateComponent, updatePage };
