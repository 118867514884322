import { Table as MantineTable, Tooltip } from '@mantine/core';
import { styled } from 'linaria/react';
import { ReactElement } from 'react';
import { newCOLORS } from '../../../styles/colors';

export function Table({
  label,
  columnHeaders,
  columnTooltips,
  rows,
}: {
  label: string;
  columnHeaders: ReactElement[] | string[];
  columnTooltips?: string[];
  rows: ReactElement[][];
}) {
  return (
    <TableWrapper>
      <StyledMantineTable striped={false} highlightOnHover={false} withTableBorder withColumnBorders>
        <MantineTable.Thead>
          <MantineTable.Tr>
            <StyledTh>{label}</StyledTh>
            {columnHeaders.map((header, index) => (
              <StyledTh key={`header-${index}`}>
                {columnTooltips?.[index] ? (
                  <Tooltip
                    multiline
                    w={200}
                    position="top"
                    label={columnTooltips[index]}
                    styles={{ tooltip: { textAlign: 'left', textTransform: 'none' } }}
                  >
                    <HeaderText>{header}</HeaderText>
                  </Tooltip>
                ) : (
                  header
                )}
              </StyledTh>
            ))}
          </MantineTable.Tr>
        </MantineTable.Thead>
        <MantineTable.Tbody>
          {rows.map((row, rowIndex) => (
            <MantineTable.Tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <StyledTd key={`${rowIndex}-${cellIndex}`}>{cell}</StyledTd>
              ))}
            </MantineTable.Tr>
          ))}
        </MantineTable.Tbody>
      </StyledMantineTable>
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  background-color: ${newCOLORS.white};
`;

const StyledMantineTable = styled(MantineTable)`
  font-family: Figtree, sans-serif;
  table-layout: fixed;

  & thead tr th,
  & tbody tr td {
    border-color: ${newCOLORS.lightGray};
    background-color: ${newCOLORS.white};
    padding: 4px 0px 4px 0px;
    font-size: 14px;
    font-weight: 600;
    color: ${newCOLORS.darkGray};
    text-align: left;
  }

  & thead tr th {
    padding: 12px 0px 12px 8px !important;
    height: auto !important;
  }
`;

const StyledTh = styled(MantineTable.Th)``;

const StyledTd = styled(MantineTable.Td)``;

const HeaderText = styled.span`
  cursor: pointer;
`;
