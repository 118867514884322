enum TaskType {
  Bug = 'bug',
  Story = 'story',
  Task = 'task',
  Subtask = 'sub-task',
  Spike = 'spike',
}

type Epic = {
  id: string;
  name: string;
  url: string;
  title: string;
};

type Initiative = {
  id: string;
  name: string;
};

type TaskTransition = {
  status: string;
  changed_by: string;
  changed_at: string;
};

type TaskRecord = {
  id: string;
  name: string;
  title: string;
  url: string;
  created_date: string;
  points: number | null;
  type: TaskType | null;
  epic: Epic | null;
  initiatives: Initiative[];
  transitions?: TaskTransition[];
};

type SortedColumn = 'title' | 'name' | 'epics' | 'initiatives' | 'points' | 'type' | 'created' | 'transitions';

enum Grouping {
  Initiative = 'initiatives',
  Epic = 'epics',
  Points = 'points',
  Type = 'type',
}

type Group = 'initiatives' | 'epics' | 'points' | 'type' | 'unmapped';

type CollapseState = {
  [key: string]: boolean;
};

interface TasksTableProps {
  tasks: TaskRecord[];
  dateRange?: React.ReactNode | string;
}

enum ToggleCollapseValue {
  COLLAPSE_ALL = 'collapseAll',
  EXPAND_ALL = 'expandAll',
}

export { Grouping, TaskType, ToggleCollapseValue };
export type { CollapseState, Group, SortedColumn, TaskRecord, TasksTableProps };
