import { styled } from '@linaria/react';
import { Flex, Modal, Stack, TextInput, Textarea } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { secondaryBase } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';

interface EditDashboardModalProps {
  opened: boolean;
  onClose: () => void;
  onSave: (data: { name: string; description: string }) => void;
  initialData: {
    name: string;
    description: string;
  };
  isCreating?: boolean;
}

export const EditDashboardModal: React.FC<EditDashboardModalProps> = ({
  opened,
  onClose,
  onSave,
  initialData,
  isCreating = false,
}) => {
  const [name, setName] = useState(initialData.name);
  const [description, setDescription] = useState(initialData.description);
  useEffect(() => {
    setName(initialData.name);
    setDescription(initialData.description);
  }, [initialData]);

  const handleSave = () => {
    onSave({ name, description });
    onClose();
  };

  const handleClose = () => {
    setName('');
    setDescription('');
    onClose();
  };

  return (
    <DashboardModalStyled
      opened={opened}
      onClose={handleClose}
      title={
        <Flex align="center" gap="sm">
          <Text weight="bold" size="large">
            {isCreating ? 'Create New Dashboard' : 'Edit Dashboard'}
          </Text>
        </Flex>
      }
      size="md"
      centered
    >
      <Stack gap="md">
        <Text size="regular">
          {isCreating ? 'Create a new dashboard to start tracking your data.' : 'Update your dashboard settings below.'}
        </Text>
        <TextInput
          label="Dashboard Name"
          value={name}
          onChange={(e) => {
            setName(e.currentTarget.value);
          }}
          required
          placeholder="QA Project Dashboard"
          className="dashboard-input"
        />
        <Textarea
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          minRows={2}
          placeholder="This dashboard tracks the progress of the QA project."
          className="dashboard-input"
        />
      </Stack>
      <Flex justify="flex-end" gap="sm" mt="xl">
        <Button radius="xl" variant="outline" onClick={handleClose}>
          Cancel
        </Button>
        <Button radius="xl" variant="secondary" onClick={handleSave}>
          {isCreating ? 'Create Dashboard' : 'Save Changes'}{' '}
          <Icon
            style={{ marginLeft: '8px' }}
            name={isCreating ? 'arrow_forward' : 'check_circle'}
            size={16}
            color="white"
          />
        </Button>
      </Flex>
    </DashboardModalStyled>
  );
};

export const DashboardModalStyled = styled(Modal)`
  .mantine-Modal-content {
    border-radius: 12px;
    background: white;
    border: 1px solid #e9ecef;
  }

  .mantine-Modal-header {
    padding: 12px 24px 0 24px;
  }

  .mantine-Modal-body {
    padding: 24px;
  }

  .mantine-Modal-close {
    color: #868e96;
    &:hover {
      background-color: #f1f3f5;
    }
  }

  .mantine-TextInput-input,
  .mantine-Textarea-input {
    border-radius: 16px;
    transition: border-color 0.2s ease;

    &:focus {
      border-color: ${secondaryBase};
    }
  }
`;
