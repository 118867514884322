type LineScatterDataPoint = {
  x: any;
  y: number | null;
  sprintId?: string;
};

enum ViewByType {
  tasks = 'Tasks',
  points = 'Points',
}

type CumulativeFlowAggregatedDataType = {
  title: string;
  data: LineScatterDataPoint[];
  color: string;
};

type CumulativeFlowDataPoints = {
  deployed: LineScatterDataPoint[];
  ready_for_deploy: LineScatterDataPoint[];
  in_test: LineScatterDataPoint[];
  in_review: LineScatterDataPoint[];
  in_progress: LineScatterDataPoint[];
  blocked: LineScatterDataPoint[];
  current_sprint: LineScatterDataPoint[];
};

export { ViewByType };
export type { CumulativeFlowAggregatedDataType, CumulativeFlowDataPoints, LineScatterDataPoint };
