import { styled } from '@linaria/react';
import { SprintAuditLogEntry } from '../../api/sprints-client/sprints-client.type';
import { TaskAuditLogEntry } from '../../api/tasks-client/task-client.type';
import { AuditLogEntry } from './audit-log-entry';

export type Props = {
  entries: SprintAuditLogEntry[] | TaskAuditLogEntry[];
};

export function AuditLog(props: Props) {
  return (
    <AuditLogTable>
      <AuditLogHeaderRow>
        <tr>
          <AuditLogHeader>Date</AuditLogHeader>
          <AuditLogHeader>Card</AuditLogHeader>
          <AuditLogHeader>Action</AuditLogHeader>
        </tr>
      </AuditLogHeaderRow>
      <tbody>
        {props.entries.map((entry) => {
          return <AuditLogEntry key={entry.id} entry={entry} clickable />;
        })}
      </tbody>
    </AuditLogTable>
  );
}

const AuditLogHeader = styled.th`
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const AuditLogTable = styled.table`
  width: 100%;
  margin-top: 20px;
  padding: 30px;
`;

const AuditLogHeaderRow = styled.thead`
  padding-bottom: 30px;
`;
