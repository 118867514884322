import { styled } from '@linaria/react';
import { Flex } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMeasureDetail } from '../../api/work-periods-client/work-periods-client.hooks';
import { MenuDrivenContainer } from '../../containers/menu-driven-container/menu-driven-container';
import { TasksTable } from '../../containers/tasks-table/tasks-table';
import { TaskRecord } from '../../containers/tasks-table/tasks-table.type';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { BrandedLoadingOverlay } from '../loader/branded-loader';
import { MeasureInfoBox } from '../measure-info-box/measure-info-box';
import { PageHeaderMessage } from '../page-header-message/page-header-message';
import { Title } from '../title/title';
import { formatTasks, makeTitle, useFinancialsTasksScope } from './financials-tasks.helpers';
import { useAllocationOfCostsTasks, useCostByInitiativeTasks, useCostVsOutputTasks } from './financials-tasks.hooks';

export const FinancialsTasks = () => {
  useDocumentTitle('Task Details - Bloomfilter');
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const [searchParams] = useSearchParams();
  const measure = searchParams.get('measure');

  const useTasksQuery =
    measure === 'cost_allocation'
      ? useAllocationOfCostsTasks
      : measure === 'cost_by_initiative'
        ? useCostByInitiativeTasks
        : useCostVsOutputTasks;

  const scope = useFinancialsTasksScope(portfolioId);
  const { projectId, subprojectId, date, factor, factorOption, startDate, endDate } = scope;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data: tasksQueryData = [], query: tasksQuery } = useTasksQuery({
    portfolioId,
    projectId,
    subprojectId,
    date,
    factor,
    factorOption,
    startDate,
    endDate,
  });

  const { data: measureDetailsData, query: measureDetailsQuery } = useMeasureDetail({
    measureName: measure as string,
    options: {
      enabled: !!measure,
      staleTime: Infinity,
    },
  });

  useEffect(() => {
    if (!tasksQuery.isPending && !tasksQueryData.length) {
      setErrorMessage('There are no related tasks for this selection.');
    }
  }, [tasksQueryData, tasksQuery.isPending]);

  useEffect(() => {
    if (measureDetailsQuery.isError) {
      setErrorMessage('Failed to load measure details');
    }
  }, [measureDetailsQuery]);

  const header = <Title title={makeTitle(scope)} />;

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay visible={tasksQuery.isPending} transitionDuration={30} variant="colored" />
      <Flex direction="column" gap={16} h="100%">
        {errorMessage && <PageHeaderMessage message={errorMessage} color="red" />}
        {measureDetailsData && <MeasureInfoBox measure={measureDetailsData} />}
        <TasksContainer>
          <TasksTable tasks={formatTasks(tasksQueryData as TaskRecord[])} />
        </TasksContainer>
      </Flex>
    </MenuDrivenContainer>
  );
};

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;
