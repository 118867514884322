import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { AuditLog } from '../../components/audit-log/audit-log';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { OuterPaddingDiv, TitleHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { useTaskAuditLogData } from './assessment-view/assessment-view.hooks';

export const ProcessAnalysisAuditLogTasks = () => {
  useDocumentTitle('Task Audit Log - Bloomfilter');

  const { taskId = '' } = useParams<{ taskId: string }>();

  const { data, isFetching } = useTaskAuditLogData(taskId);
  return (
    <Fragment>
      <BrandedLoadingOverlay visible={isFetching} transitionDuration={300} variant="colored" />
      <MenuDrivenContainer>
        {data && (
          <OuterPaddingDiv>
            <TitleHeadingTag>{data.length > 0 ? data[0]?.identifier : ''} Audit Log</TitleHeadingTag>
            <AuditLog entries={data || []} />
          </OuterPaddingDiv>
        )}
      </MenuDrivenContainer>
    </Fragment>
  );
};
