import { MultiSelect, MultiSelectProps } from '@mantine/core';
import { deduplicateItems } from '../../helpers/general-helpers';
import { useGlobalStore } from '../../store/global-store/global-store';
import { commonMultiSelectProps } from './multiselect.helpers';

export function TeamMultiselect(props: Omit<MultiSelectProps, 'data'>) {
  const { value: teamIds, onChange: handleTeamSelected, ...rest } = props;
  const teams = useGlobalStore((state) => deduplicateItems(state.teams));

  return (
    <MultiSelect
      {...rest}
      {...commonMultiSelectProps}
      value={teams.length > 0 ? teamIds : []}
      data={teams.map((t) => ({ value: t.id, label: t.name }))}
      onChange={(teamIds: string[]) => handleTeamSelected?.(teamIds)}
    />
  );
}
