import { ADOProject, ADOTeam } from '../../containers/integrations/ado/ado.type';
import { Repository } from '../../containers/integrations/github/repositories.type';
import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { ExternalBoard, ExternalProject } from '../projects-client/projects-client.type';
import {
  BoardJDC,
  ExternalService,
  GetBoardStatusesPayload,
  GetBoardStatusesResponse,
  Integration,
  IntegrationEntry,
  IntegrationJDC,
  IntegrationRefreshData,
  Methodology,
  SetStatusMappingPayload,
  StartIntegrationProcessPayload,
  completeGithubHandshakeResponse,
} from './integrations-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL;

/**
 * Fetches all available integrations from the API
 *
 * @returns {Promise<Integration[]>} A promise that resolves to an array of Integration objects
 * @throws {Error} If the API request fails, rejects with the error response data
 */
const getIntegrations = async (): Promise<Integration[]> => {
  const url = `${baseURL}/integrations/`;
  return await axiosInstance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
};

/**
 * Creates a system access for an integration
 *
 * @param {string} id - The integration ID
 * @param {string} orgId - The organization ID
 * @param {string} token - The authentication token
 * @returns {Promise<{ id: string, message: string }>} A promise that resolves to the created system access data
 * @throws {Error} If the API request fails, rejects with the error response data
 */
const createSystemAccess = async (
  id: string,
  orgId: string,
  token: string,
): Promise<{ id: string; message: string }> => {
  const url = `${baseURL}/integrations/${id}/system-accesses`;
  const postBody = {
    organization_id: orgId,
    auth_token: token,
  };

  return await axiosInstance
    .post(url, postBody)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
};

/**
 * Starts the integration process for a given payload
 *
 * @param {StartIntegrationProcessPayload} payload - The payload for starting the integration process
 * @returns {Promise<Record<string, unknown>>} A promise that resolves to the response data
 * @throws {Error} If the API request fails, rejects with the error response data
 */
const startIntegrationProcess = async (payload: StartIntegrationProcessPayload): Promise<Record<string, unknown>> => {
  const url = `${baseURL}/integrations/processes`;

  return await axiosInstance
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
};

/**
 * Method to fetch the handshake url for getting the oauth token for a given service
 * @param service string service name
 * @returns the handshake url
 */
const getIntegrationUrl = async (service: ExternalService): Promise<{ url: string }> => {
  const url = baseURL + `/integrations/get-auth-link?service=${service}`;
  return await axiosInstance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response));
};

/**
 * Gets board statuses based on the provided payload.
 *
 * @param {GetBoardStatusesPayload} payload - the payload for retrieving board statuses
 * @returns {Promise<GetBoardStatusesResponse>} a Promise that resolves to the retrieved board statuses response
 */
async function getBoardStatuses(payload: GetBoardStatusesPayload): Promise<GetBoardStatusesResponse> {
  const url = baseURL + '/integrations/get-board-statuses';
  const formattedPayload = { ...payload, state: localStorage.getItem('jiraInstance') || null };
  return await axiosInstance
    .get(url, { params: formattedPayload })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
    .finally(() => localStorage.removeItem('jiraInstance'));
}

/**
 * Generates a status mapping for a given set of external (jira/ado) statuses
 * @param externalStatuses: External statuses to map to categorize
 * @returns an object with the status mapping
 */
async function generateStatusMapping(externalStatuses: string[]): Promise<{ [key: string]: string[] }> {
  const url = baseURL + '/integrations/generate-status-mapping';
  const postBody = {
    statuses: externalStatuses,
  };
  return await axiosInstance
    .post(url, postBody)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

const completeOauth = async (
  service: ExternalService,
  userId: string,
  orgId: string,
  code: string,
  state: string,
  options: { [key: string]: string } = {},
): Promise<Record<string, unknown>> => {
  const url = baseURL + '/integrations/create-auth-token';
  const postBody = {
    userId,
    orgId,
    code,
    service,
    state,
    ...options,
  };
  return await axiosInstance
    .post(url, postBody)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response));
};

/**
 * Method to post the gathered project, subproject data to the backend
 * @param project
 * @param board
 * @param serviceName
 * @param devTeamSize
 * @param selectedMethodology
 * @param orgId
 * @param requireLabels
 * @param pointBugs
 * @param mappings
 * @param workingHoursStart
 * @param workingHoursEnd
 * @param configuration_state
 * @returns Promise of the response
 */
const setProjectBoard = async (
  project: ExternalProject | ADOProject,
  board: ExternalBoard | ADOTeam,
  serviceName: ExternalService,
  devTeamSize: number,
  selectedMethodology: Methodology,
  orgId: string,
  requireLabels: boolean,
  pointBugs: boolean,
  workingHoursStart: string,
  workingHoursEnd: string,
  configuration_state: string,
  mappings?: object,
) => {
  const url = baseURL + '/integrations/save-project-board';
  const postBody = {
    service_name: serviceName,
    project: project,
    board: board,
    dev_team_size: devTeamSize,
    methodology: selectedMethodology,
    org_id: orgId,
    require_labels: requireLabels,
    point_bugs: pointBugs,
    working_hours_start: workingHoursStart,
    working_hours_end: workingHoursEnd,
    mappings: mappings,
    configuration_state: configuration_state,
  };
  return await axiosInstance
    .post(url, postBody)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
};

const setStatusMapping = async (
  serviceName: ExternalService,
  project: ExternalProject | ADOProject | IntegrationJDC,
  board: ExternalBoard | ADOTeam | BoardJDC,
  orgId: string,
  backlogSections: string[],
  inProgressSection: string[],
  blockedSection: string[],
  inReviewSection: string[],
  inQASection: string[],
  readyForDeploymentSection: string[],
  doneSections: string[],
  projectUrl: string,
  configurationState?: string,
) => {
  const url = baseURL + '/integrations/save-status-mapping';
  const postBody: SetStatusMappingPayload = {
    service_name: serviceName,
    project: project,
    board: board,
    org_id: orgId,
    backlog_section: backlogSections,
    blocked_section: blockedSection,
    in_progress_section: inProgressSection,
    in_review_section: inReviewSection,
    in_test_section: inQASection,
    ready_for_deployment_section: readyForDeploymentSection,
    done_section: doneSections,
    project_url: projectUrl,
    configuration_state: configurationState,
  };
  if (serviceName === ExternalService.JDC) {
    postBody['service_name'] = 'jira_data_center';
  }
  if (!projectUrl) {
    delete postBody['project_url'];
  }
  return await axiosInstance
    .post(url, postBody)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
};

/**
 * Request endpoint to complete the github handshake
 * @param repository repository object
 * @param orgId orgId of the user
 * @param projectId selected project for the repository to map with
 * @returns Promise of the response
 */
const completeGithubHandshake = async (
  repository: Repository,
  orgId: string,
  projectId: string,
  externalOrgName: string,
): Promise<completeGithubHandshakeResponse> => {
  const url = baseURL + '/integrations/complete-github-handshake';
  const postBody = {
    repository: repository,
    org_id: orgId,
    project_id: projectId,
    external_org_name: externalOrgName,
  };
  return await axiosInstance
    .post(url, postBody)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
};

const completeHandshake = async (
  integrationName: string,
  options: { [key: string]: any },
): Promise<{ [key: string]: any }> => {
  const url = `${baseURL}/integrations/complete-handshake/`;
  const postBody = Object.assign({}, options, { service: integrationName });
  return await axiosInstance
    .post(url, postBody)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
};

/**
 * Retrieves the integration entries from the backend API.
 *
 * @param {string} organizationId - The ID of the organization to retrieve integration entries for
 * @returns {Promise<IntegrationEntry[]>} A promise that resolves to an array of IntegrationEntry objects.
 */
const getIntegrationEntries = async (organizationId: string): Promise<IntegrationEntry[]> => {
  const url = `${baseURL}/api/system-accesses/?organization_id=${organizationId}`;
  return await axiosInstance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
};

/**
 * Refreshes the integration by making a request to the specified integration ID's data refresh endpoint.
 *
 * @param {string} integrationId - The ID of the integration to be refreshed
 * @returns {Promise<IntegrationRefreshData>} A promise that resolves to an object containing refreshed data and status
 */
const refreshIntegration = async (integrationId: string): Promise<IntegrationRefreshData> => {
  const url = `${baseURL}/api/system-accesses/${integrationId}/request-data-refresh/`;
  return await axiosInstance
    .get(url)
    .then((response) => ({ ...response.data, status: response.status }))
    .catch((error) => Promise.reject(error.response.data));
};

/**
 * Retrieves Jira Data Center integrations.
 *
 * @param {string} organizationId - The ID of the organization to fetch integrations for.
 * @returns {Promise<IntegrationJDC[]>} A promise that resolves with an array of IntegrationJDC objects.
 */
const getIntegrationsJDC = async (organizationId: string): Promise<IntegrationJDC[]> => {
  const url = `${baseURL}/integrations/jdc/get-integrations?organization_id=${organizationId}`;
  return await axiosInstance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
};

/**
 * Retrieves the board statuses from JDC for a specific board.
 *
 * @param {string} boardId - The ID of the board.
 * @returns {Promise<{ statuses: string[] }>} The board statuses.
 */
const getBoardStatusesJDC = async (boardId: string): Promise<{ statuses: string[] }> => {
  const url = `${baseURL}/integrations/jdc/get-board-statuses?board_id=${boardId}`;
  return await axiosInstance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
};

/**
 * Configures JDC integration for a specific board.
 *
 * @param {string} boardId - The ID of the board.
 * @param {number} devTeamSize - The size of the development team.
 * @param {boolean} pointBugs - Whether to assign points to bug cards.
 * @param {boolean} requireLabels - Whether labels are mandatory for cards.
 * @param {string} workingHoursStart - The start time for working hours.
 * @param {string} workingHoursEnd - The end time for working hours.
 * @return {Promise<number>} A promise that resolves with the response status or rejects with an error.
 */
const configureJDC = async (
  boardId: string,
  devTeamSize: number,
  pointBugs: boolean,
  requireLabels: boolean,
  workingHoursStart: string,
  workingHoursEnd: string,
  configurationState: string,
): Promise<number> => {
  const url = `${baseURL}/integrations/jdc/configure`;
  const postBody = {
    board_id: boardId,
    dev_team_size: devTeamSize,
    points_for_bug_cards: pointBugs,
    labels_for_cards_mandatory: requireLabels,
    working_hours_start: workingHoursStart,
    working_hours_end: workingHoursEnd,
    configuration_state: configurationState,
  };
  return await axiosInstance
    .post(url, postBody)
    .then((response) => response.status)
    .catch((error) => Promise.reject({ error: error.response.data }));
};

export {
  completeGithubHandshake,
  completeHandshake,
  completeOauth,
  configureJDC,
  createSystemAccess,
  generateStatusMapping,
  getBoardStatuses,
  getBoardStatusesJDC,
  getIntegrationEntries,
  getIntegrationUrl,
  getIntegrations,
  getIntegrationsJDC,
  refreshIntegration,
  setProjectBoard,
  setStatusMapping,
  startIntegrationProcess,
};
