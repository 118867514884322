import { primaryLight, redLighter, skyBase, yellowBase } from '../../styles/design-tokens';

const initiativesByStatusesData = [
  { category: 'Empty', value: 4, fill: skyBase },
  { category: 'Off Track', value: 3, fill: redLighter },
  { category: 'At Risk', value: 5, fill: yellowBase },
  { category: 'On Track', value: 9, fill: primaryLight },
];

const progressBarData = {
  current: 72,
  changes: [
    { period: '7 days', value: 2.51 },
    { period: '14 days', value: 5.14 },
    { period: '30 days', value: 7.15 },
  ],
};

const workProgressData = Array.from({ length: 15 }, (_, i) => ({
  day: i + 1,
  planned: Math.floor(30 + Math.random() * 40),
  completed: Math.floor(25 + Math.random() * 30),
}));

const strategicProgressData = Array.from({ length: 10 }, (_, i) => ({
  day: i + 1,
  planned: Math.floor(20 + i * 5 + Math.random() * 5),
  completed: Math.floor(15 + i * 4 + Math.random() * 5),
}));

const throughputData = {
  primaryMetric: {
    value: 0.52,
    unit: 'Tasks/h',
  },
  dataPoints: Array.from({ length: 30 }, () => ({
    value: 0.2 + Math.random() * 0.6,
  })),
  referenceValue: 0.5,
};

export { initiativesByStatusesData, progressBarData, strategicProgressData, throughputData, workProgressData };
